import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";
import { CloseCircleFilled } from "@ant-design/icons";
import Subscription from "components/layout-components/Subscription";
import { useState } from "react";
import mixpanel from "mixpanel-browser";
import { message } from "antd";
// import { FREE_SCHOLARSHIP_COUNT } from "constants/common.constant";
import { useAssitanceChatBotContext } from "context/ChatbotContext";

export const SquareView = ({ data, history }) => {
  const mobileView = window.innerWidth < 1200;
  const tabView = window.innerWidth < 961;

  const { freeScholarshipCount } = useAssitanceChatBotContext();

  const gaEventTracker = useAnalyticsEventTracker("Lenny Assistance");
  // const storedValue = "true";
  const storedValue = localStorage.getItem("isActiveSubscription");

  const isActiveSubscription = storedValue === "true";
  console.log("🚀 ~ SquareView ~ isActiveSubscription:", isActiveSubscription);

  const [isOpen, setIsOpen] = useState(false);

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const togglePopup = () => {
    if (isActiveSubscription) {
      history.push("/auth/dashboard/plan");
    } else {
      // setIsOpen(!isOpen);
      if (tabView) {
        setIsOpen(!isOpen);
      } else {
        history.push("/auth/dashboard/subscription-plan");
      }
    }
  };
  const seeAnswer = () => {
    let label = {
      screenName: "Lenny Assistance",
      questionId: data.id,
      questionTitle: data.question,
    };
    gaEventTracker("See Answer clicked", JSON.stringify(label));
    mixpanel.track("See Answer clicked");
    history.push({
      pathname: "/auth/dashboard/assistanceChat",
      state: data,
    });
    // console.log("see answer function");
  };

  return (
    <div
      style={{
        width: mobileView ? "100%" : "30%",
        marginRight: "20px",
        marginBottom: "20px",
        borderWidth: 1,
        borderStyle: "double",
        padding: "20px",
        borderColor: "rgba(29, 37, 45, 0.13)",
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          height: "50px",
        }}
      >
        {data?.question}
      </div>
      <div
        style={{
          marginTop: "15px",
        }}
      >
        <a
          onClick={() => {
            if (isActiveSubscription === true) {
              seeAnswer();
            } else {
              if (userInfo?.scholarshipCount < freeScholarshipCount) {
                // viewDetail();
                seeAnswer();
              } else {
                message.error(
                  `You have exceeded the limit of free scholarship views. Please subscribe to continue accessing more scholarships.`
                );
                togglePopup();
              }
              // togglePopup();
            }
          }}
        >
          <span
            style={{
              color: "#1778F2",
            }}
          >
            {" "}
            See answer{" "}
          </span>
          <img
            src="/applicantImg/blueRight.png"
            style={{ width: "12px", marginLeft: "2px" }}
          />
        </a>
      </div>
      {isOpen &&
        (tabView ? (
          <div
            className="popup-overlay"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "24px",
                padding: "0px 20px",
              }}
            >
              {`${userInfo?.firstName}`} get a membership for free 7 days to
              activate automatic application to your 234 scholarship matches.
              Lets us do the hard work for you !
            </p>

            <SubscriptionMobileDesign
              toggleMobilePopup={togglePopup}
              redirect={true}
            />
          </div>
        ) : (
          <div className="popup-overlay">
            <div className="popup-content">
              <div
                onClick={togglePopup}
                style={{
                  color: "rgb(113, 122, 245)",
                  fontSize: "24px",
                  cursor: "pointer",
                  float: "right",
                  lineHeight: "normal",
                }}
              >
                {" "}
                <CloseCircleFilled />
              </div>
              <Subscription
                modalStyle={{
                  border: "none",
                  boxShadow: "none",
                  margin: "0px",
                }}
                redirect={true}
              />
            </div>
          </div>
        ))}
    </div>
  );
};
