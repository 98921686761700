const userDetailQuestion = [
  {
    question: "Please select your role",
    options: ["Student", "Parent"],
    key: "role",
    type: "two_buttons",
    qusType: "profile",
  },
  {
    question: "Please enter your Date of Birth",
    type: "date",
    qusType: "profile",
    key: "dob",
  },
  {
    question: "Select your country",
    options: "select",
    type: "select",
    qusType: "profile",
    key: "country",
  },
  {
    question: "Select your citizenship and Ethnicity",
    options: ["citizenship", "Ethnicity"],
    type: "inputBox",
    qusType: "profile",
    key: "citizenshipAndEthnicity",
  },
  {
    question: "Please select your gender",
    options: ["Male", "Female", "Others"],
    qusType: "profile",
    type: "three_buttons",
    key: "gender",
  },
  {
    question: "Please enter your zip code",
    type: "inputBox",
    qusType: "profile",
    key: "zipCode",
  },
  {
    question: "Select your current school level",
    type: "select",
    qusType: "edicationalDetail",
    key: "currentSchoolLevel",
  },
  {
    question: "Select your High School name",
    type: "select",
    qusType: "edicationalDetail",
    key: "highSchoolName",
  },
  {
    question: "Enter your high school graduation date",
    type: "datePicker",
    qusType: "edicationalDetail",
    key: "highSchoolGraduationDate",
  },
  {
    question: "Enrolled in college",
    options: ["Yes", "No"],
    qusType: "edicationalDetail",
    type: "two_buttons",
    key: "enrolledInCollege",
  },
  {
    question: "Enter your College enrolled date ",
    type: "datePicker",
    qusType: "edicationalDetail",
    key: "collegeEnrolledDate",
  },
  {
    question: "Enter your College name",
    type: "inputBox",
    qusType: "edicationalDetail",
    key: "collegeName",
  },
  {
    question: "Enter your expected College graduation date and Field of study",
    type: "inputBoxAndDatePicker",
    qusType: "edicationalDetail",
    key: "collegeGraduationDateAndFieldOfStudy",
  },
  {
    question: "Please select your Degree type",
    options: "select",
    qusType: "edicationalDetail",
    key: "degreeType",
  },
  {
    question: "Please select your GPA",
    options: "select",
    qusType: "edicationalDetail",
    key: "gpa",
  },
  {
    question: "Please select your career goal",
    options: "select",
    qusType: "edicationalDetail",
    key: "careerGoal",
  },
  {
    question: "Please select your MIlitary affiliation",
    options: "select",
    qusType: "edicationalDetail",
    key: "militaryAffiliation",
  },
  {
    question: "Interested in online study",
    options: ["Yes", "No", "Maybe"],
    qusType: "edicationalDetail",
    type: "three_buttons",
    key: "interestedInOnlineStudy",
  },
];

export default userDetailQuestion;
