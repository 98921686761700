import { Row, Col, Input, Spin, Typography, Layout } from "antd";
import { useHistory } from "react-router-dom";
import useAxios from "axios-hooks";
import moment from "moment";
import React, { useState, Fragment, useMemo } from "react";
import { MsgSummary } from "./MsgSummary.js";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import { debounce } from "lodash";
import { searchForMessages } from "./utils.js";

const Inbox = ({ props }) => {
  const [{ data, loading }] = useAxios(`/inbox`);
  const mobileView = window.innerWidth < 1200;
  const [selectedData, setSelectedData] = useState();
  const [viewIdx, setViewIdx] = useState();

  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState("");

  const history = useHistory();

  const searchData = (val) => {
    setFilteredData(searchForMessages(val, data));
  };

  const debouncedSearch = useMemo(() => debounce(searchData, 500), []);

  const onInputChange = (e) => {
    if (e.target.value) {
      setSearchText(e.target.value);
      debouncedSearch(e.target.value);
    } else {
      setSearchText("");
    }
  };

  const msgClick = (el, idx) => {
    if (mobileView) {
      history.push("/auth/message");
      props.setSideBars(true);
    } else {
      setSelectedData(el);
    }
    setViewIdx(idx);
  };

  const msgView = (el, idx) => {
    const {
      _id,
      fromName,
      subject,
      createdAt,
      sentDate,
      bodyAsHtml,
      bodyAsText,
    } = el;
    return (
      <div
        style={{
          borderTopWidth: "1px",
          borderTopStyle: "double",
          paddingTop: "10px",
          borderColor: "rgba(29, 37, 45, 0.13)",
          backgroundColor: selectedData?._id?.S === el?._id?.S && "#ffffff",
          cursor: "pointer",
        }}
        onClick={() => {
          msgClick(el, idx);
        }}
      >
        <Row
          style={{
            paddingLeft: "15px",
            paddingRight: "15px",
          }}
        >
          <Col span={24}>
            <div>
              <span
                style={{ fontSize: "14px", fontSize: "400", color: "#1D252D" }}
              >
                {fromName?.S}
              </span>
              <span
                style={{
                  float: "right",
                  fontSize: "12px",
                  fontWeight: "400",
                  color: "rgba(29, 37, 45, 0.58)",
                }}
              >
                {moment(sentDate?.S).format("DD MMMM, YYYY")}
              </span>
            </div>
            <div style={{ fontSize: "14px", fontWeight: "600" }}>
              {el?.subject?.S}
            </div>
            <Typography.Paragraph
              ellipsis={{
                rows: 2,
              }}
            >
              {bodyAsText?.S}
            </Typography.Paragraph>
          </Col>
        </Row>
      </div>
    );
  };

  const onChangeViewIdx = (idx) => {
    if (idx < 0) {
      setSelectedData(searchText ? filteredData?.[0] : data?.[0]);
      setViewIdx(0);
    } else if (idx >= data?.length) {
      setSelectedData(data?.[data?.length - 1]);
      setViewIdx(data?.length - 1);
    } else if (searchText && idx <= filteredData?.length) {
      setSelectedData(filteredData?.[filteredData?.length - 1]);
      setViewIdx(filteredData?.length - 1);
    } else {
      setSelectedData(searchText ? filteredData?.[idx] : data?.[idx]);
      setViewIdx(idx);
    }
  };

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "520px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  const { Content } = Layout;

  return (
    <div>
      <Layout>
        <Content>
          <Row>
            <Col
              style={{
                paddingTop: "15px",
              }}
              xs={24}
              lg={8}
            >
              <span
                style={{
                  fontSize: "18px",
                  fontWeight: "700",
                  paddingLeft: "10px",
                }}
              >
                Inbox ({data?.length || 0})
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "400",
                  float: "right",
                  paddingRight: "10px",
                  color: "rgba(29, 37, 45, 0.53)",
                }}
              >
                {"Recent  "}
                <DownOutlined size={10} />
              </span>
              <div
                style={{
                  marginBottom: "15px",
                  marginTop: "10px",
                  paddingRight: "10px",
                  paddingLeft: "10px",
                }}
              >
                <Input
                  placeholder="Search"
                  style={{
                    borderRadius: "25px",
                  }}
                  prefix={<SearchOutlined className="mr-0" />}
                  onChange={onInputChange}
                />
              </div>
              {!data?.length && (
                <Typography.Title level={4} style={{ paddingLeft: "10px" }}>
                  No new messages
                </Typography.Title>
              )}
              {searchText ? (
                <>
                  {filteredData?.map((el, idx) => (
                    <Fragment key={el?._id?.S}>{msgView(el, idx)}</Fragment>
                  ))}
                </>
              ) : (
                <>
                  {data?.map((el, idx) => (
                    <Fragment key={el?._id?.S}>{msgView(el, idx)}</Fragment>
                  ))}
                </>
              )}
            </Col>
            <Col span={16}>
              <div>
                {!mobileView && (
                  <MsgSummary
                    data={selectedData}
                    onChangeViewIdx={onChangeViewIdx}
                    viewIdx={viewIdx}
                  />
                )}
              </div>
            </Col>
          </Row>
        </Content>
      </Layout>
    </div>
  );
};

export default Inbox;
