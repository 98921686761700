import { initializePaddle } from "@paddle/paddle-js";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

export default function PaddleIntegration({
  paddlePriceId,
  togglePopup,
  userDetail,
  productId,
  price,
  paddle,
}) {
  // console.log("🚀 ~ userDetail:", userDetail);
  const history = useHistory();
  // const [paddle, setPaddle] = useState();
  const [loading, setLoading] = useState(true);

  const successUrl = window.location.origin + "/auth/dashboard/success";
  // const linkId = localStorage.getItem("linkId");
  //const sourceId = localStorage.getItem("sourceId");
  // console.log("🚀 ~ sourceId:", sourceId);

  // const openCheckout = () => {
  //   const customer = {};

  //   if (userDetail?.paddleUserId) {
  //     customer.id = userDetail.paddleUserId;
  //   } else {
  //     customer.email = userDetail.email;
  //   }

  //   if (userDetail?.paddleAddressId) {
  //     customer.address = {
  //       id: userDetail.paddleAddressId,
  //     };
  //   } else {
  //     customer.address = {
  //       countryCode: userDetail.countryCode,
  //       postalCode: userDetail.zipCode,
  //     };
  //   }

  //   // console.log(Object.keys(customer).length, customer);
  //   // console.log(customer.email?.length === 0)
  //   if (customer.email?.length === 0) {
  //     return paddle?.Checkout.open({
  //       settings: {
  //         successUrl: successUrl,
  //       },
  //       items: [
  //         {
  //           priceId: paddlePriceId,
  //           quantity: 1,
  //         },
  //       ],
  //       // customer: customer,
  //       customData: {
  //         integration_id: `${new Date().getTime()}__${userDetail.userId}`,
  //         plan_id: productId,

  //       },
  //     });
  //   } else {
  //     paddle?.Checkout.open({
  //       settings: {
  //         successUrl: successUrl,
  //       },
  //       items: [
  //         {
  //           priceId: paddlePriceId,
  //           quantity: 1,
  //         },
  //       ],
  //       customer: customer,
  //       customData: {
  //         integration_id: `${new Date().getTime()}__${userDetail.userId}`,
  //         plan_id: productId,
  //       },
  //     });
  //   }
  // };

  const openCheckout = () => {
    const customer = {};

    if (userDetail?.paddleUserId) {
      customer.id = userDetail.paddleUserId;
    } else {
      customer.email = userDetail.email;
    }

    if (userDetail?.paddleAddressId) {
      customer.address = {
        id: userDetail.paddleAddressId,
      };
    } else {
      customer.address = {
        countryCode: userDetail.countryCode,
        postalCode: userDetail.zipCode,
      };
    }

    // get linkid and souce id
    const linkId = localStorage.getItem("linkId");
    const sourceId = localStorage.getItem("sourceId");
    const clientIp = localStorage.getItem("clientIp");

    const customData = {
      integration_id: `${new Date().getTime()}__${userDetail.userId}`,
      plan_id: productId,
    };

    if (linkId && sourceId) {
      customData.linkId = linkId;
      customData.sourceId = sourceId;
      customData.userAgent = navigator.userAgent;
      customData.countryCode = userDetail?.countryCode;
      customData.planPrice = price?.substring(1);
      customData.ipAddress = clientIp;
    }

    if (customer.email?.length === 0) {
      return paddle?.Checkout.open({
        settings: {
          successUrl: successUrl,
        },
        items: [
          {
            priceId: paddlePriceId,
            quantity: 1,
          },
        ],
        customData: customData,
      });
    } else {
      paddle?.Checkout.open({
        settings: {
          successUrl: successUrl,
        },
        items: [
          {
            priceId: paddlePriceId,
            quantity: 1,
          },
        ],
        customer: customer,
        customData: customData,
      });
    }
  };

  // function closeCheckout() {
  //   paddle?.Checkout.close();
  // }

  // useEffect(() => {
  //   const initializeAndOpenCheckout = async () => {
  //     try {
  //       const paddleInstance = await initializePaddle({
  //         environment: "sandbox",
  //         token: "test_5542685d1fea3460323c1a07221",
  //         // token: "live_2acf6093a3b4baf59f5ac9e1bbb",
  //         eventCallback: (data) => {
  //           // console.log(data);
  //           if (data.name === "checkout.completed") {
  //             // closeCheckout();
  //             localStorage.setItem(
  //               "addressId",
  //               data?.data?.customer?.address?.id
  //             );
  //             localStorage.setItem("isActiveSubscription", true);
  //             // history.push("/auth/dashboard/success");
  //             if (window.trackdesk) {
  //               window.trackdesk("lennyai", "conversion", {
  //                 conversionType: "sale",
  //                 amount: {
  //                   value: price?.substring(1),
  //                 },
  //                 customParams: {
  //                   advS1: "Trial successful",
  //                 },
  //               });
  //             }
  //           }
  //         },
  //       });
  //       if (paddleInstance) {
  //         setPaddle(paddleInstance);
  //         setLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Error initializing Paddle:", error);
  //     }
  //   };

  //   initializeAndOpenCheckout();
  // }, []);

  // useEffect(() => {
  //   if (!loading && paddle) {
  //     openCheckout();
  //   }
  // }, [loading, paddle]);

  if (paddle) {
    openCheckout();
  }

  // if (loading) {
  //   return ;
  // }
  return (
    <div>
      <div className="checkout-container"></div>
    </div>
  );
}
