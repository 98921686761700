import useAxios from "axios-hooks";
import React, { useEffect, useState } from "react";
import { Button, Modal, Row, Col, Spin, message } from "antd";
import fetch_ from "../../../interceptors/FetchInterceptor";
import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";
import DOMPurify from "dompurify";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";
import mixpanel from "mixpanel-browser";
import { useAssitanceChatBotContext } from "context/ChatbotContext";

const UploadEssay = ({
  history,
  props,
  onSave,
  onGoBack,
  existingValue,
  drawerOpen,
  details,
}) => {
  //check previous data in localstorage
  const { filledData, setFilledData } = useAssitanceChatBotContext();
  const { quill, quillRef } = useQuill();
  const { field, title, description, optional } = details;
  const [modalVisible, setModalVisible] = useState(true);
  const [loading, setLoading] = useState(false);
  const mobileView = window.innerWidth < 1200;
  const [{ data = [] }] = useAxios(`/register-applicants/form-fields`);
  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const reqData = filledData;
  // const reqData = JSON.parse(localStorage.getItem("reqData"));
  // console.log("🚀 ~ reqData:", reqData);

  const fieldName = field?.name === "Essay File" ? field?.name : field?.key;

  const existData = reqData ? reqData[fieldName] : existingValue;
  // console.log("🚀 ~ existData:", existData, reqData[field?.key]);

  const gaEventTracker = useAnalyticsEventTracker("Upload Essay");
  const fieldOfStudyId = data?.filter((e) => e.key === "fieldOfStudy");
  const fieldOfStudy = userInfo?.applicantFields?.filter(
    (e) => e.fieldId === fieldOfStudyId[0]?.id
  )[0];
  const highSchoolNameId = data?.filter((e) => e.key === "highSchoolName");
  const highSchoolName = userInfo?.applicantFields?.filter(
    (e) => e.fieldId === highSchoolNameId[0]?.id
  )[0];
  const highSchoolNameValue =
    highSchoolName?.field?.options[highSchoolName?.fieldValue];
  const fieldOfStudyValue =
    fieldOfStudy?.field?.options[fieldOfStudy?.fieldValue];

  useEffect(() => {
    if (drawerOpen) {
      setModalVisible(true);
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (quill && existData) {
      // console.log(existData);

      const separateContent = (content) => {
        // Regular expressions to match the title and author
        const titleRegex = /^Title: (.+)$/m;
        const authorRegex = /^Essay by: (.+)$/m;

        // Extract the title and author
        const titleMatch = content.match(titleRegex);
        const authorMatch = content.match(authorRegex);

        const title = titleMatch ? titleMatch[1] : "";
        const author = authorMatch ? authorMatch[1] : "";

        // Extract the description by removing the title and author lines
        const description = content
          .replace(titleRegex, "")
          .replace(authorRegex, "")
          .trim();

        return `Title: ${title}\n\nEssay by: ${author}\n\n${description}`;
      };

      const formattedData = separateContent(existData);
      quill.clipboard.dangerouslyPasteHTML(DOMPurify.sanitize(existData));
    }
  }, [quill]);

  const handleCloseClick = () => {
    setModalVisible(false);
    // onGoBack();
  };
  const saveClick = () => {
    let label = {
      screenName: "Upload Essay",
    };
    gaEventTracker("Save clicked", JSON.stringify(label));
    mixpanel.track("Save clicked");

    if (quill.root.innerHTML === "<p><br></p>") {
      message.error(`Please add your ${field.name || "essay"}!!!`);
      return;
    }

    if (onSave) {
      onSave(quill.root.innerHTML);
    } else {
      history.goBack();
    }
  };

  const writeEssay = () => {
    setLoading(true);
    let label = {
      screenName: "Upload Essay",
    };

    // event
    gaEventTracker("Write Lenny Easy clicked", JSON.stringify(label));
    mixpanel.track("Write Lenny Easy clicked");
    let updateData = {
      prompt: (() => {
        // const userTypePrompt = `for user with details: ${userInfo.firstName} ${userInfo.lastName} is studied in ${highSchoolNameValue} and interested in ${fieldOfStudyValue}. Word count: 500`;
        const titlePrompt = title ? `on ${title}` : "";
        const descriptionPrompt = description
          ? `and description: ${description}`
          : "";

        return field.key === "essay"
          ? `Write an essay ${titlePrompt} ${descriptionPrompt}`
          : `Write an answer ${titlePrompt} ${descriptionPrompt}`;
      })(),
    };
    // let updateData = {
    //   prompt: history?.location?.params?.title
    //     ? `Write an essay on ${history?.location?.params?.title} Word count: 500 and Include user information ${userInfo.firstName} ${userInfo.lastName} is studied in ${highSchoolNameValue} and interested in ${fieldOfStudyValue}`
    //     : `Write an essay for user ${userInfo.firstName} ${userInfo.lastName} is studied in ${highSchoolNameValue} and interested in ${fieldOfStudyValue}. Word count: 500`,
    // };

    fetch_({
      url: `/register-applicants/get-essay`,
      method: "POST",
      data: updateData,
    })
      .then(({ data }) => {
        quill.clipboard.dangerouslyPasteHTML(data.content);
        setModalVisible(false);
        setLoading(false);
      })
      .catch((error) => {
        setModalVisible(false);
        setLoading(false);
        console.log(error);
      });
  };

  const helpOutline = () => {
    setLoading(true);
    let label = {
      screenName: "Upload Essay",
    };
    gaEventTracker("Write Lenny Easy clicked", JSON.stringify(label));
    mixpanel.track("Write Lenny Easy clicked");
    let updateData = {
      prompt: (() => {
        const userTypePrompt = `for user with details: ${userInfo.firstName} ${userInfo.lastName} is studied in ${highSchoolNameValue} and interested in ${fieldOfStudyValue}.`;
        const titlePrompt = title ? `on ${title}` : "";
        const descriptionPrompt = description
          ? `and description: ${description}`
          : "";

        return field.key === "essay"
          ? `Write an outline for essay ${titlePrompt} ${descriptionPrompt} and ${userTypePrompt}`
          : `Write an outline for answer ${titlePrompt} ${descriptionPrompt} and ${userTypePrompt}`;
      })(),
    };
    // let updateData = {
    //   prompt: history?.location?.params?.title
    //     ? `Write an essay on ${history?.location?.params?.title} Word count: 500 and Include user information ${userInfo.firstName} ${userInfo.lastName} is studied in ${highSchoolNameValue} and interested in ${fieldOfStudyValue}`
    //     : `Write an essay for user ${userInfo.firstName} ${userInfo.lastName} is studied in ${highSchoolNameValue} and interested in ${fieldOfStudyValue}. Word count: 500`,
    // };

    fetch_({
      url: `/register-applicants/get-essay`,
      method: "POST",
      data: updateData,
    })
      .then(({ data }) => {
        quill.clipboard.dangerouslyPasteHTML(data.content);
        setModalVisible(false);
        setLoading(false);
      })
      .catch((error) => {
        setModalVisible(false);
        setLoading(false);
        console.log(error);
      });
  };

  if (loading && false)
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "720px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );

  return (
    <div style={{ background: "#ffffff" }}>
      <Modal
        visible={modalVisible}
        destroyOnClose={true}
        footer={null}
        closable={false}
        bodyStyle={{ padding: "0px", borderRadius: "10px", minWidth: "25vw" }}
      >
        <div style={{ borderRadius: "10px" }}>
          <div>
            <Row
              style={{
                background: "#1D252D",
                padding: "0px",
                display: "flex",
                // justifyContent:"center",
                alignItems: "center",
              }}
            >
              <Col style={{ padding: "15px" }}>
                <img
                  src="/applicantImg/circleRobo.png"
                  style={{ height: "30px", marginRight: "4px" }}
                />
              </Col>
              <Col style={{ color: "#ffffff", alignSelf: "center" }}>
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  {" "}
                  Lenny
                </span>{" "}
                <br />
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {" "}
                  Your personalised assistant{" "}
                </span>
              </Col>
              <div style={{ position: "absolute", top: "10px", right: "10px" }}>
                <Button
                  type="text"
                  onClick={handleCloseClick}
                  style={{
                    color: "#ffffff",
                    fontSize: "18px", // Increased font size
                    fontWeight: "bold",
                    paddingTop: "8px",
                  }}
                >
                  ✖
                </Button>
              </div>
            </Row>
            {loading ? (
              <div
                style={{
                  // height: "200px",
                  // paddingTop: "90px",
                  // paddingLeft: "250px",
                  display: "grid",
                  placeItems: "center",
                  height: "25vh",
                }}
              >
                <Spin />
              </div>
            ) : (
              <Row
                style={{
                  paddingBottom: "20px",
                }}
              >
                <Col
                  style={{
                    width: "60%",
                    marginTop: "25px",
                    paddingLeft: "15px",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    Hi, I am Lenny AI, how can I help you ?
                  </h2>
                  <Button
                    onClick={writeEssay}
                    style={{
                      marginTop: "15px",
                      borderColor: "#1778F2",
                      borderRadius: "18px",
                    }}
                  >
                    <span style={{ color: "#1778F2" }}>
                      {field.key === "essay"
                        ? "Write an essay for me"
                        : "Write an answer for me"}
                    </span>
                  </Button>
                  <Button
                    onClick={helpOutline}
                    style={{
                      marginTop: "15px",
                      borderColor: "#1778F2",
                      borderRadius: "18px",
                    }}
                  >
                    <span style={{ color: "#1778F2" }}>
                      {field.key === "essay"
                        ? "Help in outline of an essay"
                        : "Help in outline of an answer"}
                    </span>
                  </Button>
                </Col>
                <Col>
                  <img
                    src="/applicantImg/robo.png"
                    style={{ height: "200px", marginTop: "35px" }}
                  />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </Modal>
      <div style={{ padding: "8px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <a
            onClick={() => {
              if (onGoBack) onGoBack();
              else history.goBack();
            }}
            style={{ color: "#1D252D", display: "flex", alignItems: "center" }}
          >
            <img
              src="/applicantImg/back.png"
              style={{ height: "22px", marginRight: "6px" }}
            />
            <p
              style={{
                fontWeight: "500",
                fontSize: "18px",
                margin: "0",
                color: "black",
              }}
            >
              {field.name} - <span style={{ color: "#8D96FF" }}>{title}</span>
            </p>
          </a>
          <Button
            type="primary"
            onClick={saveClick}
            style={{
              color: "#8D96FF",
              borderRadius: "8px",
              background: "transparent",
              border: "none",
              fontWeight: "500",
              fontSize: "18px",
              boxShadow: "none",
              width: "max-content",
            }}
          >
            <span>Submit</span>
          </Button>
        </div>
      </div>

      <div
        style={
          {
            // paddingLeft: "20px", paddingRight: "20px"
          }
        }
      >
        <div ref={quillRef} style={{ minHeight: "400px" }} />
      </div>
    </div>
  );
};

export default UploadEssay;
