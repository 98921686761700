import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "antd";
import questionData from "./questionData.js";
import answerData from "./answerData.js";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Footer from "../footer.js";

const Faq = () => {
  const history = useHistory();
  const [selected, setSelected] = useState(-1);
  const mobileView = window.innerWidth < 481;
  const tabView = window.innerWidth < 961;

  const renderQuestion = (e, i) => {
    return (
      <a
        style={{
          fontWeight: "600",
          fontSize: "18px",
          paddingRight: "20px",
          color: "black",
        }}
        onClick={(event) => {
          event.stopPropagation();
          // console.log("Clicked question:", e);
          setSelected((prevSelected) => (prevSelected === i ? -1 : i)); // Toggle open/close
        }}
      >
        <Row
          style={{
            background: "#F1F2FF",
            padding: "10px",
            borderRadius: "10px",
          }}
        >
          <Col style={{ width: "95%" }}>
            <span>
              {e}
              {selected === i && renderAnswer(i)}
            </span>
          </Col>
          <Col style={{ width: "5%" }}>
            <span
              style={{
                float: "right",
                fontFamily: "'Poppins'",
                fontWeight: "450",
                letterSpacing: "0.45px",
              }}
            >
              {selected === i ? <UpOutlined /> : <DownOutlined />}
            </span>
          </Col>
        </Row>
      </a>
    );
  };

  const renderAnswer = (i) => {
    return (
      <Row>
        <Col style={{ width: "95%" }}>
          <span
            style={{
              fontWeight: "600",
              fontSize: "18px",
              paddingRight: "20px",
              color: "rgb(130,130,130)",
            }}
          >
            <div
              style={{
                whiteSpace: "pre-wrap",
                whiteSpace: "pre-wrap",
                fontFamily: "'Poppins'",
                fontWeight: "100",
                letterSpacing: "0.35px",
                marginTop: "10px",
              }}
            >
              {answerData[i]}
            </div>
          </span>
        </Col>
      </Row>
    );
  };

  useEffect(() => {
    // Define the structured data for the FAQ page
    const faqStructuredData = {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      mainEntity: questionData.map((question, i) => ({
        "@type": "Question",
        name: question,
        acceptedAnswer: {
          "@type": "Answer",
          text: answerData[i],
        },
      })),
    };

    // Create a script element for structured data
    const script = document.createElement("script");
    script.type = "application/ld+json";
    script.innerHTML = JSON.stringify(faqStructuredData);

    // Append the script to the document head
    document.head.appendChild(script);

    // Clean up the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div
      style={{
        background: "linear-gradient(180deg, #E1E3FF 0%, #FFFFFF 6.69%)",
      }}
    >
      <Row
        style={{
          // paddingLeft: window.innerWidth < 915 ? "30px" : "100px",
          paddingLeft:
            window.innerWidth < 600
              ? "0px"
              : window.innerWidth < 915
              ? "30px"
              : "100px",

          // textAlign: "center",
          display: "flex",
          textAlign: window.innerWidth < 600 ? "center" : "start",
        }}
      >
        <Col
          style={{
            // width: tabView ? "100%" : "60%",
            // flex: "60% 1",
            flexGrow: "1",
            flexShrink: "1",
            flexBasis: "30%",
            placeSelf: "center",
            padding: tabView && "20px 0px",
          }}
        >
          <h2
            style={{
              fontFamily: "Poppins",
              fontSize: window.innerWidth < 600 ? "27px" : "32px",
              lineHeight: "45px",
              margin: window.innerWidth < 600 ? "5px" : "0",
            }}
          >
            Frequently asked questions
          </h2>
          <div
            style={{
              fontSize: window.innerWidth < 600 ? "16px" : "18px",

              marginTop: "0px",
              paddingTop: "0px",
              fontFamily: "Poppins",
              margin: "5px",
            }}
          >
            If you need additional assistance please
          </div>
          <Button
            type="primary"
            style={{
              marginTop: "20px",
              width: "300px",
              height: "40px",
              background: "#8D96FF",
              border: "none",
              borderRadius: "5px",
              fontSize: "20px",
              fontFamily: "'Poppins'",
            }}
            onClick={() => {
              history.push("/contact");
            }}
          >
            Contact us
          </Button>
        </Col>
        {
          <Col
            style={{
              // width: mobileView ? "100%" : "40%",
              marginTop: "20px",
              flexGrow: "1",
              flexShrink: "1",
              // flexBasis: "50%",
            }}
          >
            <img
              src="/new/faq1.png"
              style={{
                float: "right",
                width: window.innerWidth < 915 ? "350px" : "auto",
              }}
            />
            <img
              src="/new/faq.png"
              style={{
                position: "absolute",
                width: window.innerWidth < 915 ? "180px" : "232px",
                left: "100px",
                bottom: "0px",
              }}
            />
          </Col>
        }
      </Row>
      <div style={{ padding: tabView ? "20px" : "20px 100px" }}>
        {questionData.map((e, i) => renderQuestion(e, i))}
      </div>
      <Footer />
    </div>
  );
};

export default Faq;
