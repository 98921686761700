export default [
  "While Lenny may offer scholarships to its users, Lenny aggregates and applies users to third party scholarships.",
  "Lenny is the Brainchild of Kenny Sandorffy. Way before founding Lenny, he founded ScholarshipOwl a, back then, unique scholarship application engine.",
  "Lenny.ai is ScholarshipOwl on steroids :) with multiple AI powered algorithms and unbeatable state of the art features that makes the application process flawless and fun. Did we mention that Lenny is also FREE to use and does not charge students for its core service?",
  "Lenny.ai goes beyond being a mere database; it serves as your personalized student-centric AI search engine, continuously applying you to both existing and newly available scholarships. This comprehensive platform streamlines the entire scholarship application procedure, enabling you to submit a single application for numerous scholarships. Additionally, Lenny provides valuable assistance with tasks such as essay writing and various other aspects of the application process.",
  "Any individual looking for scholarship opportunities towards their college or professional education. While Lenny focuses on students studying in the USA, we are constantly adding more interenational scholarships to support students worldwide.",
  "Lenny.ai requests only the essential information required for students to complete scholarship applications. This data is used to assess your eligibility for various scholarships, and we subsequently submit applications to multiple scholarships on your behalf. Rest assured, we will only request the same information that each specific scholarship fund requires.",
  "Lenny.ai provides each user with an inbox, accessible through its website, where all incoming emails will be shown, ensuring that your private inbox remains free from unwanted messages. Your applications will also be submitted through this system.",
  "Absolutely. We do not want to send you emails you don't want to read. You can choose to unsubscribe from all messages or only from some of them - up to you.",
  "Your likelihood of winning scholarships is primarily influenced by how effectively you have presented yourself, including factors such as your essay, grades, and other qualifications. Nevertheless, Lenny.ai enhances your opportunities by enabling students to apply to a lot more scholarships within a shorter timeframe. We assist students in identifying numerous scholarships for which they qualify and that they wouldn't otherwise know about, and we then facilitate applications to a greater number of them (automatically).",
  "Why don't you ask our users? Or even better, how about simply giving it a try? It is free, so what do you have to lose..",
  "Although many scholarship applications require prior acceptance to a college or university, there are also opportunities that do not impose this level of prerequisite on applicants. If you've missed the deadlines for the scholarships you had in mind, it's time to start exploring new possibilities.Many provider also offer recurring scholarships, so if you have missed one cycle you can apply again during the next opening. Nevertheless, like with so many other things in life, time is of essence, and we recommend applying early in order to avoid disappointment.",
  "Your chances of winning a scholarship increase as you apply to more of them. The more details you provide us, the greater the number of potential scholarships we can discover and apply you to. Also, many providers re-open their scholarships so even if a scholarship expired, you can always try to re-apply dyring the next cycle.",
  "No worries, we got you covered. Simply contact us and we will sort it out.",
  "In your Profile section you can see what information is missing. Keep in mind that with more information Lenny can better match you to scholarship opportunities and also submit more complete applications.",
  "You can view our privacy policy here. In short - we do everything to keep your information safe. As part of our submission enginge, we need to submit some of the details you provide us with to the relevant scholarship providers.",
  "Let's call it Lenny's magic sauce :) Lenny.ai empowers you to select scholarships from our comprehensive database that align with your eligibility, determined by the information you provide during registration. The questions within the application process serve as the eligibility criteria, aligning students with scholarships they qualify for. Criteria such as GPA, current academic year, and chosen major are examples of factors we consider in this matching process.",
  "Absolutely. Lenny initially started in the USA but is open to students from everywhere.",
  "For sure! Although some scholarships require that students are of certain age or have certain qualifications, adult students can still apply to a lot of scholarships at Lenny.ai.",
  "While we do not think you will every want to do that, you can close your account by contacting contacting us.",
  "Well, compared to what a student would have had to do 5 years ago, not much. Still some parts are up to you. Lenny is here to assist you and automate the process from A to Z as much as possible, but your input is still required in order to complete and personalize certain part of the process.",
  "You definitely should, otherwise, how would you know if you won or get other important information? Also, if you supply incorrect information your application may get disqualified.",
  "Every scholarship comes with its unique eligibility prerequisites, and Lenny.ai excels at aligning students with scholarships that match their qualifications. Common criteria like age, GPA, location, areas of academic interest, and ethnicity are instrumental in identifying scholarships that suit your profile.",
  "That clearly depends on the number of scholarships you are eligilble to apply for at ayn given time. One of the many features makming Lenny so unique is that it will apply you to as many scholarships as possible in the least amount of time humanly (or droid-ly) possible. There may always be some other information you are requested to supply, but we got you covered wherever we can. And whlie essay writing might take more time, Lenny has you covered also there with unique features ...",
  "I wish I knew :). Joking aside, many providers will ask students to write essays on specific topics. Lenny will also cluster and show you related essay topics so you do not have to start similar essays from scratch. And of course, Lenny's AI will help you draft outline and/or write your essay versions before submitting them.",
  "Lenny's AI will help you draft or even write essays. Lenny can even include your personal information or experiences in the essay if you like ...",
  "That is like asking Nasa how to go to space :). In short we leverage AI, algorithms, and years of experience to create an unbeatable experience and flawless, time-saving application process.",
  "Yes, absolutely! And we will use our little AI helpers to find them and submit the application for you in no time.",
  `Scholarships, grants, and student loans are three different types of financial aid for students, and they differ primarily in how they provide funding and whether or not they need to be repaid: Scholarships:
  Funding Source: Scholarships are typically awarded by various organizations, institutions, and foundations, including universities, private companies, non-profit organizations, and government agencies.
  Payment: Scholarships are essentially "free money." Recipients do not need to repay them, and they are often awarded based on academic achievement, talent, specific skills, or meeting certain criteria such as financial need or demographic background.
  Application Process: Students usually need to apply for scholarships, which may involve submitting essays, demonstrating their qualifications, or meeting specific criteria set by the scholarship provider.
  Grants:
  Funding Source: Grants can be provided by government agencies, educational institutions, or private organizations.
  Payment: Like scholarships, grants do not require repayment. They are awarded based on financial need, academic merit, or specific purposes (e.g., research grants).
  Application Process: Students typically need to apply for grants, and the application process may involve demonstrating financial need or meeting specific eligibility criteria set by the grant provider.
  Student Loans:
  Funding Source: Student loans are usually offered by government agencies (federal or state) or private financial institutions (banks, credit unions).
  Payment: Unlike scholarships and grants, student loans must be repaid with interest over time. The borrower is responsible for repaying the loan, typically after finishing their education or after a grace period.
  Application Process: Students must apply for student loans, and eligibility may be influenced by factors like credit history, income, and the cost of attending school.
  In summary, scholarships and grants are forms of financial aid that do not require repayment and are often awarded based on merit or need, while student loans provide borrowed funds that must be repaid with interest. It's advisable for students to explore scholarships and grants first to minimize the need for loans and reduce future debt.`,
  "If it is in your list again, then you may be eligible to re-apply again. In many cases scholarships 'renew' and you may re-apply.",
  "Yes, and we do exactly that as well, if you want us to.",
  "For sure you can re-apply.  We recommend you do. A simple setting in Lenny allows you to automate the application process and forget about setting reminders or calendar notes.",
  "You can check the status of the applications in your personal area. There are also many other things you can do there, while you are at it.",
  "The most annoying answer is: it depends... Some providers will email you a confirmation while others will stay silent. We recommend that you keep an eye on your Lenny.ai inbox and make sure to see whether you won.",
  "And again: It depends. Providers announce winners at different times, but on average we see it taking around 3 weeks after the application deadline passed, and a lot longer for college-funded scholarships.  You can read up on specific scholarship details, simply go to your Personal area and click on the scholarships detail page. From there you can easily navigate to the individual scholarship providers' Terms and Conditions. ",
  "If you are chosen as a winner, then first of all: Congratulations! Well Done. The majority of providers will notify you by email - i.e. through your Lenny.ai inbox. In some cases they might reach out to you by phone or through other means.",
  "Certain scholarship providers disburse funds to your university's Financial Aid office, while others send the funds directly to you. You will be requested to furnish the necessary payout details as per their instructions.",
  "We definitely recommend you to do that. You can log in any time and update your details through the website.",
  "Probably you have not won. But we do rcommend checking all contact methods thouroughly. And also - never give up - there are billions of Dollars out there in scholarships each year and up for grabs, so don't get discouraged, and keep on applying!",
  "Of course, you can pick up where you left off earlier at any time. Just log in to Lenny and get right back to where you left off earlier.",
  `Preparing for college involves several steps to ensure a smooth transition from high school or other life stages to a higher education institution. Here are some essential steps to consider:
Academic Preparation:
Maintain Strong Grades: Continue to excel in your high school coursework. Colleges often consider your high school GPA as a key admission factor.
Take Challenging Courses: Enroll in advanced courses, honors classes, or Advanced Placement (AP) courses if available, as they can demonstrate your commitment to academics.
College Research:
Explore Colleges: Research colleges or universities that align with your academic, career, and personal goals. Consider factors like location, size, majors offered, and campus culture.
Visit Campuses: If possible, visit campuses to get a feel for the environment and talk to current students.
Standardized Tests:
Take Required Tests: Depending on the colleges you're interested in, you may need to take standardized tests like the SAT or ACT. Prepare for these exams through study resources or test prep courses.
Financial Planning:
Complete the FAFSA: Fill out the Free Application for Federal Student Aid (FAFSA) to determine your eligibility for federal financial aid.
Scholarships and Grants: Search and apply for scholarships and grants to reduce the cost of tuition. Begin early, as some deadlines are well in advance.
Consider Work-Study: Explore work-study programs that allow you to work part-time on campus to help cover expenses.
Extracurricular Activities:
Participate Actively: Engage in extracurricular activities that interest you. These can enhance your college application and provide valuable skills and experiences.
Leadership Roles: If possible, take on leadership roles in clubs or organizations.
College Applications:
Prepare Application Materials: Assemble necessary documents such as transcripts, recommendation letters, and your personal statement or essay.
Apply to Multiple Schools: Apply to a range of colleges to maximize your options.
Meet Deadlines: Pay attention to application deadlines, as they vary among institutions.
Financial Literacy:
Understand College Costs: Familiarize yourself with the costs of attending college, including tuition, fees, housing, and textbooks.
Budgeting: Learn basic financial management skills to manage your expenses while in college.
Health and Well-being:
Health Insurance: Ensure you have health insurance coverage, either through your college or a private plan.
Mental Health: Be aware of mental health resources available on campus and how to seek help if needed.
Housing and Logistics:
Housing: Arrange for on-campus or off-campus housing as per your college's policies.
Transportation: Plan for how you'll get to and from college, whether it's on-campus or off-campus.
Personal Growth:
Independence: Develop practical life skills, such as cooking, laundry, and time management.
Goal Setting: Set academic and personal goals for your college years.
Remember that preparing for college is not just about academics but also about personal growth and readiness for independence. Stay organized, seek guidance from counselors or mentors, and don't hesitate to reach out to colleges for information and support during the application process.`,
  `There is generally no fixed limit to the amount of money you can receive in scholarships. Scholarships vary widely in terms of their amounts, eligibility criteria, and the number of scholarships you can apply for and receive. Here are some important points to consider regarding scholarship limits:
Number of Scholarships: You can apply for and potentially receive multiple scholarships from different sources. There are scholarships available for various purposes, such as academic achievement, community involvement, specific majors, and more.
Total Amount: The total amount you can receive in scholarships depends on the scholarships you qualify for and successfully apply to. Some scholarships may cover full tuition and other expenses, while others may offer smaller amounts. Accumulating multiple scholarships can significantly reduce the cost of your education.
Stacking Scholarships: Some colleges and universities allow students to "stack" scholarships, meaning you can combine multiple scholarships to cover your expenses. However, this policy varies among institutions, so it's essential to check with the specific colleges you're interested in.
Renewable Scholarships: Some scholarships are renewable, meaning they provide funding for multiple years if you maintain certain academic or eligibility requirements.
Outside Scholarships: In addition to scholarships offered by colleges and universities, there are numerous external scholarships provided by organizations, foundations, and companies. These can often be stacked with institutional scholarships.
Need-Based Aid: Scholarships are typically merit-based, but you may also qualify for need-based financial aid, which considers your family's financial situation. This can include grants, work-study, and low-interest federal student loans.
Federal and State Aid Limits: While there's no fixed limit on scholarships, there are limits on federal and state financial aid programs. For instance, the Federal Pell Grant has an annual maximum award amount, and your eligibility is determined by your Expected Family Contribution (EFC) from the FAFSA.
It's important to actively search for scholarships, meet application deadlines, and present a strong application to maximize your chances of receiving financial assistance for your education. Keep in mind that each scholarship has its own eligibility criteria, so you should tailor your applications to meet the requirements of each scholarship opportunity you pursue.`,
  `The cost of studying can vary significantly depending on several factors, including the country where you choose to study, the specific institution, your course of study, your residency status, and your lifestyle choices. Here are some of the primary expenses to consider when calculating the cost of studying:
Tuition and Fees: This is the cost of your academic program. Tuition fees vary widely between countries, institutions, and programs. Public universities often have lower tuition fees for in-state residents, while private institutions and out-of-state or international students may face higher costs.
Housing: The cost of housing can vary based on whether you live on-campus in university housing, off-campus in an apartment or shared accommodation, or with family. Rental costs also depend on the location, with urban areas generally being more expensive.
Books and Supplies: Textbooks, course materials, and supplies for your studies can add up. Some universities have book rental programs or digital textbooks to help reduce this expense.
Food and Dining: Your food expenses will depend on your eating habits. Universities typically offer meal plans, but you can also choose to cook your meals or dine out, which can affect costs.
Transportation: Consider the cost of commuting to and from campus, whether by public transportation, a car, or other means.
Health Insurance: Many countries require international students to have health insurance, and even in countries with public healthcare, additional coverage may be necessary.
Personal Expenses: This includes clothing, entertainment, personal care items, and other miscellaneous expenses.
Visa and Immigration Fees: International students may need to pay for visa application fees and other immigration-related costs.
Travel and Visa Costs: If you're studying abroad, factor in the cost of traveling to and from your home country.
Scholarships and Financial Aid: Scholarships, grants, and financial aid can significantly offset the cost of studying. Apply for as many scholarships and grants as possible to reduce your expenses.
Income and Part-Time Work: Consider whether you'll be working part-time while studying to help cover expenses. Be aware of any restrictions on working for international students in your host country.
Exchange Rates and Currency Fluctuations: If you're studying in a different country, currency exchange rates can impact your costs.
It's crucial to create a budget based on your specific circumstances and research the cost of studying at the institutions you're interested in. Additionally, consider the availability of financial aid, scholarships, and part-time work opportunities to help fund your education. Remember that the cost of studying can vary widely, so careful planning and financial management are essential to ensure a successful and affordable education.`,
  "There are many schoalrships for students with low GPA or ones where GPA is not required. So even if you have a low GPA, don't be discouraged, there are a lot of options out there for you as well.",
  `Yes, there are many scholarships specifically designed to support minority students in pursuing their education. These scholarships aim to promote diversity and inclusion in higher education and help students from underrepresented backgrounds access educational opportuni
Ethnic or Racial Minority Scholarships: These scholarships are often provided by organizations, foundations, and colleges to students from specific ethnic or racial backgrounds. Examples include scholarships for African American, Hispanic, Native American, Asian American, and Pacific Islander students.
First-Generation College Student Scholarships: Scholarships may be available for students who are the first in their families to attend college.
LGBTQ+ Scholarships: Some scholarships are designed to support LGBTQ+ students in their pursuit of higher education.
Women in STEM Scholarships: While not exclusively for minorities, some scholarships focus on supporting women pursuing degrees in science, technology, engineering, and mathematics (STEM) fields, where women are underrepresented.
Disability Scholarships: Students with disabilities may find scholarships that provide financial assistance and support services.
Religious Affiliation Scholarships: Some scholarships are offered based on a student's religious affiliation or involvement in religious organizations.
Veterans and Military Scholarships: Scholarships are available for military service members, veterans, and their dependents.
Specific Field Scholarships: Some scholarships are tied to specific fields of study or professions, such as education, nursing, or business, with the goal of increasing diversity in those areas.
To find scholarships for minority students, you can:
Check with your school's financial aid office.
Research scholarship databases and websites that specialize in minority scholarships, such as the United Negro College Fund (UNCF), Hispanic Scholarship Fund, and others.
Explore scholarships offered by professional organizations related to your intended field of study or career.
Reach out to community organizations, local businesses, and foundations that may offer scholarships to minority students.
Utilize scholarship search engines and platforms that allow you to filter scholarships by criteria like ethnicity, background, or specific demographics.
When applying for minority scholarships, pay close attention to the eligibility criteria, application deadlines, and required documentation. Many of these scholarships are competitive, so make sure to present a strong application that highlights your achievements, goals, and contributions to your communities.`,
  "In most cases not, unless the scholarship specifically mentions otherwise",
  "Some scholarships will be specific to attending certain schools and/or courses while many others are not. Keep on the look out for all your options and be as specific as possible when running scholarship searches. you will find many options even if you have not decided on a school yet.",
  "The key to securing a scholarship is through application! The more scholarships you apply for, the better your chances of success. Lenny.ai's platform matches you with numerous scholarships, and by submitting applications for a few scholarships each week, your likelihood of winning increases significantly.",
];
