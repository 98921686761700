import React from "react";
import Footer from "./footer";
import "./style.css";

const PrivacyPolicy = () => {
  return (
    <div>
      <div
        className="term_container"
        style={{
          background:
            "linear-gradient(180deg, #DFE2FF 0%, #FFF 60.3%), url(/img/pricing/bg.png)",
          padding: "50px 50px 0 50px",
        }}
      >
        <h2
          style={{
            fontFamily: "Poppins",
            fontSize: "32px",
            letterSpacing: "-0.96px",
          }}
        >
          Privacy Policy
        </h2>
        <p>
          Last revised: <u>June 20, 2023</u>
        </p>{" "}
        <p>
          Lenny.ai (“we,” “us,” “our”) respects the privacy of its customers and
          visitors (“you”) and is committed to maintaining the privacy and
          confidentiality of your information including your personal
          information (collectively, “information”). This Privacy Policy
          explains what information we collect about you on our website(s)
          (“Site”) and through other internet-based mechanisms (e.g., email and
          social media), our online applications, and our services generally
          (collectively, “Services”), how we use and disclose such information,
          and the safeguards we have in place to reduce the risk of unauthorized
          access and use of such information. We offer various products and
          services including Lenny.ai for students and businesses. How we
          collect, use, and disclose your information will vary based on your
          relationship with us, the Site(s) you visit, and the Services you use.
        </p>{" "}
        <p>
          This Privacy Policy also provides information about information we
          process as a service provider to scholarship providers and other
          organizations (“Clients”).
        </p>{" "}
        <p>
          By visiting our Site and/or using our Services, you accept our privacy
          practices described in this Privacy Policy. This Privacy Policy is
          also a part of our Terms of Use.
        </p>
      </div>

      <div
        className="term_container"
        style={{
          padding: "0 50px",
        }}
      >
        <p>
          <strong>Table of Contents</strong>
        </p>
        <p className="table-of-content">
          <ol>
            <li>
              <a href="#privacy-policy-changes" className="anchor">
                PRIVACY POLICY CHANGES
              </a>
            </li>
            <br />
            <li>
              <a
                href="#information-we-collect-as-a-business"
                className="anchor"
              >
                INFORMATION WE COLLECT AS A BUSINESS
              </a>
              <ol type="a">
                <li>
                  <a href="#personal-information" className="anchor">
                    Personal Information
                  </a>
                </li>
                <li>
                  <a href="#sensitive-personal-information" className="anchor">
                    Sensitive Personal Information
                  </a>
                </li>
                <li>
                  <a href="#usage-data" className="anchor">
                    Usage Data
                  </a>
                </li>
                <li>
                  <a href="#communication-recording" className="anchor">
                    Communication Recording
                  </a>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <a
                href="#how-we-collect-information-as-a-business"
                className="anchor"
              >
                HOW WE COLLECT INFORMATION AS A BUSINESS
              </a>
              <ol type="a">
                <li>
                  <a href="#voluntary-disclosure" className="anchor">
                    Voluntary Disclosure
                  </a>
                </li>
                <li>
                  <a href="#cookies" className="anchor">
                    Cookies
                  </a>
                </li>
                <li>
                  <a href="#tracking-pixels-and-clear-gifs" className="anchor">
                    Tracking Pixels and Clear GIFs
                  </a>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <a
                href="#how-we-use-and-share-information-as-a-business"
                className="anchor"
              >
                HOW WE USE AND SHARE INFORMATION AS A BUSINESS
              </a>
              <ol type="a">
                <li>
                  <a href="#generally" className="anchor">
                    Generally
                  </a>
                </li>
                <li>
                  <a href="#testimonials-submissions" className="anchor">
                    Testimonials &amp; Submissions
                  </a>
                </li>
                <li>
                  <a href="#service-providers" className="anchor">
                    Service Providers
                  </a>
                </li>
                <li>
                  <a href="#third-party-selling-and-sharing" className="anchor">
                    Third-Party Selling and Sharing
                  </a>
                </li>
                <li>
                  <a href="#other-third-party-disclosures" className="anchor">
                    Other Third-Party Disclosures
                  </a>
                </li>
                <li>
                  <a href="#mail-email-marketing" className="anchor">
                    Mail &amp; Email Marketing
                  </a>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <a href="#security" className="anchor">
                SECURITY
              </a>
            </li>
            <br />
            <li>
              <a href="#blogs-forums-testimonials" className="anchor">
                BLOGS, FORUMS, TESTIMONIALS
              </a>
            </li>
            <br />
            <li>
              <a href="#other-websites" className="anchor">
                OTHER WEBSITES
              </a>
            </li>
            <br />
            <li>
              <a
                href="#compliance-with-laws-and-law-enforcement"
                className="anchor"
              >
                COMPLIANCE WITH LAWS AND LAW ENFORCEMENT
              </a>
            </li>
            <br />
            <li>
              <a href="#other-transfers" className="anchor">
                OTHER TRANSFERS
              </a>
            </li>
            <br />
            <li>
              <a href="#how-long-we-retain-your-data" className="anchor">
                HOW LONG WE RETAIN YOUR DATA
              </a>
            </li>
            <br />
            <li>
              <a href="#childrens-privacy" className="anchor">
                CHILDREN’S PRIVACY
              </a>
            </li>
            <br />
            <li>
              <a
                href="#personal-information-we-collect-as-a-service-provider-for-our-clients"
                className="anchor"
              >
                PERSONAL INFORMATION WE COLLECT AS A SERVICE PROVIDER FOR OUR
                CLIENTS
              </a>
            </li>
            <br />
            <li>
              <a href="#additional-consumer-rights" className="anchor">
                ADDITIONAL CONSUMER RIGHTS
              </a>
              <ol type="a">
                <li>
                  <a href="#right-to-know" className="anchor">
                    Right to Know
                  </a>
                </li>
                <li>
                  <a href="#right-to-deletion" className="anchor">
                    Right to Deletion
                  </a>
                </li>
                <li>
                  <a href="#right-to-correct" className="anchor">
                    Right to Correct
                  </a>
                </li>
                <li>
                  <a
                    href="#right-to-opt-out-from-the-sale-or-sharing-of-personal-information"
                    className="anchor"
                  >
                    Right to Opt-Out from the Sale or Sharing of Personal
                    Information
                  </a>
                </li>
                <li>
                  <a
                    href="#right-to-limit-use-of-sensitive-personal-information"
                    className="anchor"
                  >
                    Right to Limit Use of Sensitive Personal Information
                  </a>
                </li>
                <li>
                  <a href="#right-to-appeal" className="anchor">
                    Right to Appeal
                  </a>
                </li>
                <li>
                  <a href="#how-to-submit-a-request" className="anchor">
                    How to Submit a Request
                  </a>
                </li>
                <li>
                  <a href="#authorized-agent" className="anchor">
                    Authorized Agent
                  </a>
                </li>
                <li>
                  <a href="#non-discrimination-notice" className="anchor">
                    Non-Discrimination Notice
                  </a>
                </li>
                <li>
                  <a
                    href="#california-do-not-track-disclosure"
                    className="anchor"
                  >
                    California “Do Not Track” Disclosure
                  </a>
                </li>
                <li>
                  <a
                    href="#california-site-ownership-disclosure"
                    className="anchor"
                  >
                    California Site Ownership Disclosure
                  </a>
                </li>
              </ol>
            </li>
            <br />
            <li>
              <a href="#additional-nevada-consumer-rights" className="anchor">
                ADDITIONAL NEVADA CONSUMER RIGHTS
              </a>
            </li>
            <br />
            <li>
              <a href="#contact-us" className="anchor">
                CONTACT US
              </a>
            </li>
          </ol>
        </p>{" "}
        <h2>
          <a id="privacy-policy-changes" className="anchor"></a>1. PRIVACY
          POLICY CHANGES
        </h2>{" "}
        <p>
          This Privacy Policy is subject to change. We encourage you to review
          this Privacy Policy frequently for any revisions or amendments. Such
          changes to this Privacy Policy will be posted on the Site and will be
          effective immediately upon posting. You will be deemed to have been
          made aware of and have accepted the changes by your continued use of
          the Site or Services after the changes have been posted.
        </p>{" "}
        <h2>
          <a id="information-we-collect-as-a-business"></a>2. INFORMATION WE
          COLLECT AS A BUSINESS
        </h2>{" "}
        <h3>
          <a id="personal-information"></a>a. Personal Information
        </h3>{" "}
        <p>
          We collect information that personally identifies, relates to,
          describes, or is capable of being associated with you (“Personal
          Information”), including:
        </p>{" "}
        <ul className="identifier">
          <li>
            Identifiers (<em>e.g.,</em> name, date of birth, social security
            number, email address, mailing address, IP address, login username
            &amp; password, links to social media profiles)
          </li>{" "}
          <li>
            Personal Information listed in Cal. Civ Code 1798.80(e) (
            <em>e.g</em>., name, signature, address, telephone number, education
            level, credit/debit card number, brand, expiration date, and other
            information under this category to the extent such information is
            covered by another category)
          </li>{" "}
          <li>
            Protected classification characteristics under California or federal
            law (<em> e.g</em>., gender, ethnicity, citizenship, military
            affiliation)
          </li>{" "}
          <li>
            Commercial Information (<em>e.g</em>., payment method, subscription
            history, transaction history, scholarship history, donation history)
          </li>{" "}
          <li>
            Internet or Other Electronic Network Activity (<em>e.g</em>., device
            information such as operating system and browser type, email system
            data such as inbox and outbox content, affiliate marketing data such
            as transaction ID and offer ID, device type, other technical
            information regarding your use of our Site/Services that can be
            associated with you)
          </li>{" "}
          <li>Geolocation Information (city-level)</li>{" "}
          <li>
            Audio, electronic, visual, thermal, olfactory, or similar
            information (<em>e.g</em>., photos, videos)
          </li>{" "}
          <li>
            Professional or Employment-Related Information (<em>e.g</em>.,
            occupation, job title, employer)
          </li>{" "}
          <li>
            Education information meaning non-public education information (per
            the Family Educational Rights and Privacy Act (20 U.S.C. Section
            1232g, 34 C.F.R. Part 99)) (<em>e.g</em>., high school name, college
            name, grade point average, career goals, field of study, grade
            level, major, essays, transcripts, recommendation letters)
          </li>{" "}
          <li>
            Inferences drawn from the above categories of Personal Information
            that relate to your preferences, characteristics, psychological
            trends, predispositions, behavior, attitudes, intelligence,
            abilities, and aptitudes.
          </li>
        </ul>{" "}
        <h3>
          <a id="sensitive-personal-information"></a>b. Sensitive Personal
          Information
        </h3>{" "}
        <p>
          We also collect certain types of Personal Information that may be
          considered sensitive under relevant laws (“Sensitive Personal
          Information”) such as:
        </p>{" "}
        <ul className="identifier">
          <li>Ethnic origin</li> <li>Citizenship</li>{" "}
          <li>
            Communications not directed to us such as mail, email, or text
            message content where we are not the intended recipient. This is
            considered Sensitive Personal Information for California residents
            only.
          </li>{" "}
          <li>
            Account username and password. This is considered Sensitive Personal
            Information for California residents only.
          </li>
        </ul>{" "}
        <p>
          The types of Personal Information we collect about you may vary based
          on how you use our Site(s) and/or Services.
        </p>{" "}
        <h3>
          <a id="usage-data"></a>c. Usage Data
        </h3>{" "}
        <p>
          We automatically collect information in connection with the actions
          you take on a Site and in connection with using the Services (“Usage
          Data”). For example, each time you use a Site, we automatically
          collect your IP address, location, hardware settings, browser type and
          settings, time and duration of your connection, and other device
          information. We use this information to improve the performance and
          functionality of our Site(s) and Services, and to better understand
          how individuals interact with our Site(s) and Services. If this
          information is capable of being associated with you, directly or
          indirectly, we treat it as Personal Information. If this information
          is not capable of being individually associated with you, we treat it
          as Usage Data.
        </p>{" "}
        <h3>
          <a id="communication-recording"></a>d. Communication Recording
        </h3>{" "}
        <p>
          We and/or our Service Providers (as defined below), may record calls
          and retain the content of chat conversations or other
          written/electronic communications between you and us and/or our
          Service Provider. By communicating with us and/or our Service
          Provider, you consent to such recording and retention of
          communications.
        </p>{" "}
        <h2>
          <a id="how-we-collect-information-as-a-business"></a>3. HOW WE COLLECT
          INFORMATION AS A BUSINESS
        </h2>{" "}
        <h3>
          <a id="voluntary-disclosure"></a>a. Voluntary Disclosure
        </h3>{" "}
        <p>
          We may ask you to provide us with Personal Information when you
          register with our Site(s)/Services, purchase a service, complete a
          scholarship application, claim a scholarship, leave a testimonial,
          communicate with us (online or offline), and at other times. You are
          not required to provide us your Personal Information; however, if you
          choose not to provide the requested information, you may not be able
          to use some or all of the features of the Site or Services or we may
          not be able to fulfill your requested interaction.
        </p>{" "}
        <h3>
          <a id="cookies"></a>b. Cookies
        </h3>{" "}
        <p>
          We use cookies (a small text file placed on your device to identify
          your device and browser) to improve the experience of a Site and
          Services, such as keeping track of your activities on the Site,
          recognizing return visitors, and analyzing our promotions and Site
          traffic. Some third parties who advertise on our Site(s) may also use
          cookies similar technologies. Many web browsers are initially set up
          to accept cookies. You can reset your web browser to refuse all
          cookies or to indicate when a cookie is being sent. Instructions for
          how to manage cookies in popular browsers are available at:&nbsp;
          <a href="https://www.theguardian.com/info/cookies" target="_blank">
            <u>Internet Explorer</u>
          </a>
          ,&nbsp;
          <a
            href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies"
            target="_blank"
          >
            <u>Firefox</u>
          </a>
          ,&nbsp;
          <a
            href="https://support.google.com/chrome/answer/95647?hl=en&amp;ref_topic=14666"
            target="_blank"
          >
            <u>Chrome</u>
          </a>
          ,&nbsp;
          <a href="https://support.apple.com/en-us/HT201265" target="_blank">
            <u>Safari (iOS)</u>
          </a>
          ,&nbsp;
          <a
            href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
            target="_blank"
          >
            <u>Safari (Mac)</u>
          </a>
          , and&nbsp;
          <a
            href="https://blogs.opera.com/news/2015/08/how-to-manage-cookies-in-opera/"
            target="_blank"
          >
            <u>Opera</u>
          </a>
          . However, certain features of our Site(s) or Services may not work if
          you delete or disable cookies.
        </p>{" "}
        <p>We use the following types of cookies:</p>{" "}
        <ul className="identifier">
          <li>
            <u>Session Cookies:</u>&nbsp;Session cookies keep track of you or
            your information as you move from page to page within the
            Site/Services and are typically deleted once you close your browser.
          </li>{" "}
          <li>
            <u>Persistent Cookies:</u>&nbsp;Persistent cookies reside on your
            system and allow us to customize your experience if you leave and
            later return to the Site/Services. For example, persistent cookies
            may allow us to remember your preferences.
          </li>{" "}
          <li>
            <u>Strictly Necessary Cookies:</u>&nbsp;Strictly necessary cookies
            are, as their name suggests, necessary for the operation of the Site
            and/or Services. These are cookies that facilitate our compliance
            with laws, allow us to verify your identity before you access
            restricted areas of the Site/Services, manage our network, or keep
            track of user input.
          </li>{" "}
          <li>
            <u>Advertising Cookies:</u>&nbsp;Advertising cookies are used to
            learn more about you and advertise our products/services that might
            interest you; however, we do not use advertising cookies in a manner
            that would constitute cross-context behavioral advertising or
            targeted advertising as contemplated by applicable privacy laws.
          </li>{" "}
          <li>
            <u>Analytics Cookies:</u>&nbsp;Analytics cookies help us understand
            how our Site and Services are working and who is visiting our Site
            or using our Services. Google Analytics is one tool we use, and you
            can learn more by reviewing&nbsp;
            <a href="https://policies.google.com/privacy" target="_blank">
              <u>Google’s Privacy Policy</u>
            </a>
            .
          </li>
        </ul>{" "}
        <p>
          Depending on the circumstances, we may use cookies to collect Personal
          Information, Usage Data, or both.
        </p>{" "}
        <h3>
          <a id="tracking-pixels-and-clear-gifs"></a>c. Tracking Pixels and
          Clear GIFs
        </h3>{" "}
        <p>
          We employ software technology that enables us to track certain aspects
          of a user's visit to a Site. This technology helps us better manage
          content on the Site(s) by informing us what content is effective, how
          consumers engage with the Site(s), and how consumers arrive at and/or
          depart from the Site(s). The software typically uses two methods to
          track user activity: (1) "tracking pixels" and (2) "clear gifs."
          Tracking pixels are pieces of executable code that are embedded in a
          web page that track usage activity including which pages are viewed,
          when they are viewed, and how long the pages are viewed. We utilize
          tracking pixels provided by third-party social media platforms such as
          Pinterest, Facebook, and TikTok. Clear gifs are tiny graphics with
          unique identifiers which are embedded in web pages and email messages
          that track whether or not a user has viewed a particular web page or
          email message. User activity information may be associated with
          additional information about a user's session, such as IP addresses
          and the type of browser used, and Personal Information, if provided by
          the user.
        </p>{" "}
        <p>
          If you arrive at a Site by "clicking through" from another website,
          then certain information about you that you provided to that other
          website, such as the terms you were searching that led you to that
          Site, may be transmitted to us and we may use it. You should review
          the Privacy Policy of any website from which you reached a Site to
          determine what information was collected by that website and for what
          purpose(s) you agreed that website could use that information. We may
          retain information about you provided to us by other websites and will
          only use it in accordance with this Privacy Policy. Such information
          may be associated with other Usage Data or Personal Information.
        </p>{" "}
        <h2>
          <a id="how-we-use-and-share-information-as-a-business"></a>4. HOW WE
          USE AND SHARE INFORMATION AS A BUSINESS
        </h2>{" "}
        <h3>
          <a id="generally"></a>a. Generally
        </h3>{" "}
        <p>We use Personal Information for internal purposes, such as to</p>{" "}
        <ul className="identifier">
          <li>Provide you with our Site(s) and Services;</li>{" "}
          <li>Process or complete transactions you requested;</li>{" "}
          <li>
            Improve our Site(s) and Services, including customization and
            personalization;
          </li>{" "}
          <li>
            Market and remarket our products and services and the products and
            services of select third parties to you;
          </li>{" "}
          <li>Communicate with you about our Site(s) and Services</li>{" "}
          <li>
            Develop new products and services and enhance existing products and
            services; and
          </li>{" "}
          <li>
            Compile information and analyses to enhance the customer experience
            and improve our business.
          </li>
        </ul>{" "}
        <p>
          We use Sensitive Personal Information to assist in scholarship
          matching and to provide our Services to you as described above. We may
          also sell certain Sensitive Personal Information, like ethnicity and
          citizenship, to Third Parties (as explained more fully below). If you
          are a California resident, such use and/or disclosure is for
          additional purposes beyond those specified in 11 Cal. Code of Regs. §
          7027(m).
        </p>{" "}
        <h3>
          <a id="testimonials-submissions"></a>b. Testimonials &amp; Submissions
        </h3>{" "}
        <p>
          If you provide a testimonial or other submission of information, we
          may post it publicly on our Site(s), via our Services, or in other
          advertising material. By providing a testimonial or submission, you
          give us permission to use it in any manner and for any purpose,
          including in marketing and advertising communications, and to share it
          with traditional and social media providers.
        </p>{" "}
        <h3>
          <a id="service-providers"></a>c. Service Providers
        </h3>{" "}
        <p>
          From time to time, we may establish a relationship with other
          businesses who provide services to us and that we believe are
          trustworthy and have privacy practices consistent with ours that may
          include corporate affiliates (“Service Providers”). We contract with
          Service Providers to provide certain services to us, including:
        </p>{" "}
        <ul className="identifier">
          <li>Online hosting and maintenance;</li>{" "}
          <li>
            Mobile and online application development, operation, and
            maintenance;
          </li>{" "}
          <li>
            Marketing and advertising design, distribution, tracking, and
            analysis;
          </li>{" "}
          <li>Payment processing;</li>{" "}
          <li>Management of access to services;</li>{" "}
          <li>Customer service &amp; support;</li>{" "}
          <li>Information provision and notifications;</li>{" "}
          <li>
            Professional services such as legal, accounting, and management
            professionals;
          </li>{" "}
          <li>Data storage and management;</li>{" "}
          <li>
            Analysis of the Site and Services for performance, business,
            technical, and user experience optimization; and
          </li>{" "}
          <li>Identity and contact information validation.</li>
        </ul>{" "}
        <p>
          We only disclose the necessary Personal Information for our Service
          Providers to perform these services on our behalf. Each Service
          Provider is expected to use reasonable security measures appropriate
          to the nature of the information involved to protect your Personal
          Information from unauthorized access, use, or disclosure. Service
          Providers are prohibited from using Personal Information other than as
          specified by us. In the past twelve months, we have disclosed each
          category of Personal Information we collect (listed above) with one or
          more Service Providers.
        </p>{" "}
        <h3>
          <a id="third-party-selling-and-sharing"></a>d. Third-Party Selling and
          Sharing
        </h3>{" "}
        <p>
          We may sell your Personal Information to other parties who are not our
          service providers (“Third Parties”). We do not share Personal
          Information with Third Parties for cross-context behavioral
          advertising or targeted advertising purposes. Any time we use the word
          “share” or its derivatives in this Privacy Policy, we mean sharing for
          cross-context behavioral advertising or targeted advertising purposes.
          When you provide Personal Information to us, you agree to be contacted
          by such Third Parties including without limitation schools or
          institutions in which you expressed interest. In the past twelve
          months we have sold and/or shared Personal Information with Third
          Parties are outlined below:
        </p>{" "}
        <div className="table-wrp">
          <table className="privacyTable">
            <thead>
              <tr>
                <th>
                  <p>
                    <strong>Category of Third-Party Company</strong>
                  </p>
                </th>{" "}
                <th>
                  <p>
                    <strong>Categories of Personal Information </strong>
                  </p>
                </th>{" "}
                <th>
                  <p>
                    <strong>Sold or Shared</strong>
                  </p>
                </th>{" "}
                <th>
                  <p>
                    <strong>Business or Commercial Purpose</strong>
                  </p>
                </th>
              </tr>{" "}
              <br />
              <tr>
                <th>
                  <p>Scholarship providers</p>
                </th>{" "}
                <th>
                  <p>Identifiers</p>{" "}
                  <p>
                    Protected classification characteristics under California or
                    federal law Education information
                  </p>{" "}
                  <p>Professional/Employment-related information</p>{" "}
                  <p>
                    Audio, electronic, visual, thermal, olfactory, or similar
                    information
                  </p>{" "}
                  <p>
                    Sensitive Personal Information (ethnicity &amp; citizenship)
                  </p>
                </th>{" "}
                <th>
                  <p>Sold</p>
                </th>{" "}
                <th>
                  <p>
                    To enable the purchaser to market and provide their goods
                    and/or services to you.
                  </p>
                </th>
              </tr>{" "}
              <br />
              <tr>
                <th>
                  <p>Colleges/Universities</p>
                </th>{" "}
                <th>
                  <p>Identifiers</p>{" "}
                  <p>
                    Protected classification characteristics under California or
                    federal law Education information
                  </p>{" "}
                  <p>
                    Sensitive Personal Information (ethnicity &amp; citizenship)
                  </p>
                </th>{" "}
                <th>
                  <p>Sold</p>
                </th>{" "}
                <th>
                  <p>
                    To enable the purchaser to market and provide their goods
                    and/or services to you.
                  </p>
                </th>
              </tr>{" "}
              <br />
              <tr>
                <th>
                  <p>Education finance providers</p>
                </th>{" "}
                <th>
                  <p>Identifiers</p>{" "}
                  <p>
                    Protected classification characteristics under California or
                    federal law Education information
                  </p>{" "}
                  <p>
                    Sensitive Personal Information (ethnicity &amp; citizenship)
                  </p>
                </th>{" "}
                <th>
                  <p>Sold</p>
                </th>{" "}
                <th>
                  <p>
                    To enable the purchaser to market and provide their goods
                    and/or services to you.
                  </p>
                </th>
              </tr>{" "}
              <br />
              <tr>
                <th>
                  <p>Advertising and marketing companies</p>
                </th>{" "}
                <th>
                  <p>Identifiers</p>{" "}
                  <p>
                    Protected classification characteristics under California or
                    federal law Education information
                  </p>{" "}
                  <p>
                    Sensitive Personal Information (ethnicity &amp; citizenship)
                  </p>
                </th>{" "}
                <th>
                  <p>Sold</p>
                </th>{" "}
                <th>
                  <p>
                    To enable the purchaser to market and provide their goods
                    and/or services to you.
                  </p>
                </th>
              </tr>{" "}
              <br />
              <tr>
                <th>
                  <p>Student support organizations</p>
                </th>{" "}
                <th>
                  <p>Identifiers</p>{" "}
                  <p>
                    Protected classification characteristics under California or
                    federal law Education information
                  </p>{" "}
                  <p>
                    Sensitive Personal Information (ethnicity &amp; citizenship)
                  </p>
                </th>{" "}
                <th>
                  <p>Sold</p>
                </th>{" "}
                <th>
                  <p>
                    To enable the purchaser to market and provide their goods
                    and/or services to you.
                  </p>
                </th>
              </tr>{" "}
              <br />
              <tr>
                <th>
                  <p>Market research firms</p>
                </th>{" "}
                <th>
                  <p>Identifiers</p>{" "}
                  <p>
                    Protected classification characteristics under California or
                    federal law Education information
                  </p>{" "}
                  <p>
                    Sensitive Personal Information (ethnicity &amp; citizenship)
                  </p>
                </th>{" "}
                <th>
                  <p>Sold</p>
                </th>{" "}
                <th>
                  <p>
                    To enable the purchaser to market and provide their goods
                    and/or services to you.
                  </p>
                </th>
              </tr>{" "}
              <br />
              <tr>
                <th>
                  <p>Non-college/university education providers</p>
                </th>{" "}
                <th>
                  <p>Identifiers</p>{" "}
                  <p>
                    Protected classification characteristics under California or
                    federal law Education information
                  </p>{" "}
                  <p>
                    Sensitive Personal Information (ethnicity &amp; citizenship)
                  </p>
                </th>{" "}
                <th>
                  <p>Sold</p>
                </th>{" "}
                <th>
                  <p>
                    To enable the purchaser to market and provide their goods
                    and/or services to you.
                  </p>
                </th>
              </tr>
            </thead>
          </table>
        </div>{" "}
        <p>
          Please note that even if you opt out, your information may still be
          disclosed to certain Third Parties for transactional purposes ,such as
          to apply for scholarships, when you direct us to do so. Additionally,
          Third Parties who have already received your Personal Information may
          continue to communicate with you and use your Personal Information. We
          do not control and are not responsible for Third-Party communications
          or the use of your Personal Information by Third Parties. Use of your
          information by a Third Party will be governed by that Third Party’s
          terms of use and/or privacy policy.
        </p>{" "}
        <h3>
          <a id="other-third-party-disclosures"></a>e. Other Third-Party
          Disclosures
        </h3>{" "}
        <p>
          We may disclose your Personal Information to Third Parties for other
          purposes such as:
        </p>{" "}
        <ul className="identifier">
          <li>
            To comply with applicable law, legal obligations, or valid legal
            processes such as search warrants, subpoenas, or court orders.
          </li>{" "}
          <li>
            In connection with a potential or completed merger, sale of assets,
            or other business transaction.
          </li>{" "}
          <li>
            For additional purposes with your consent where such consent is
            required by law.
          </li>
        </ul>{" "}
        <p>
          In the past twelve months, we have disclosed the following categories
          of personal information to the following categories of Third Parties:
        </p>{" "}
        <div className="table-wrp">
          <table className="privacyTable">
            <thead>
              <tr>
                <th>
                  <p>
                    <strong>Third Party Categories</strong>
                  </p>
                </th>{" "}
                <th>
                  <p>
                    <strong>Personal Information Categories </strong>
                  </p>
                </th>
              </tr>{" "}
              <tr>
                <th>
                  <p>Government Agencies and Courts</p>
                </th>{" "}
                <th>
                  <p>None in past 12 months</p>
                </th>
              </tr>{" "}
              <tr>
                <th>
                  <p>Parties to Litigation</p>
                </th>{" "}
                <th>
                  <p>None in past 12 months</p>
                </th>
              </tr>{" "}
              <tr>
                <th>
                  <p>Potential acquirers of our business</p>
                </th>{" "}
                <th>
                  <p>None in past 12 months</p>
                </th>
              </tr>
            </thead>
          </table>
        </div>{" "}
        <h3>
          <a id="mail-email-marketing"></a>f. Mail &amp; Email Marketing
        </h3>{" "}
        <p>
          We may use your Personal Information to market products or services
          likely to be of interest to you, or to provide you with special offers
          and opportunities, via mail &amp; email. With respect to commercial
          email communications from us, you may opt out of these emails via a
          link in the footer of all commercial email messages. You may also opt
          out of mail and email marketing communications by emailing us at&nbsp;
          <a href="mailto:unsubscribe@lenny.ai">
            <u>unsubscribe@lenny.ai</u>
          </a>
          .
        </p>{" "}
        <h2>
          <a id="security"></a>5. SECURITY
        </h2>{" "}
        <p>
          We recognize the importance of safeguarding the confidentiality of
          your Personal Information. Accordingly, we employ commercially
          reasonable administrative, technical, and physical safeguards to
          protect your Personal Information from unauthorized access,
          disclosure, and use. For example, we work to protect the security of
          your information during transmission using Secure Sockets Layer
          (“SSL”) software and have deployed commercially reasonable encryption,
          firewalls, and access controls. Even with these safeguards, no data
          transmission over the Internet or other network can be guaranteed 100%
          secure. As a result, while we strive to protect information
          transmitted on or through our Site(s) and Services we cannot, and do
          not, guarantee the security of any information you or we transmit on
          our Site(s) or using our Services, or that you or we transmit over any
          other electronic network. You understand and agree that data and
          communications, including information collected and/or transferred via
          our Site(s) or Services, may be accessed by unauthorized third parties
          at rest or in transit. Your provision of information to us is at your
          own risk and is subject to this Privacy Policy and our Terms of Use.
        </p>{" "}
        <h2>
          <a id="blogs-forums-testimonials"></a>6. BLOGS, FORUMS, TESTIMONIALS
        </h2>{" "}
        <p>
          Our Site(s) and/or Services may contain features such as blogs,
          forums, message boards, testimonial opportunities, or other
          public-facing message systems. If you use one of these features, your
          comments, including any Personal Information you share, will be
          viewable to other users and the public. You use such features at your
          own risk. We are not responsible for the Personal Information you
          share using such features or any consequences that result therefrom.
          We reserve the right, but are under no obligation, to remove any
          comments from such features that we believe, in our sole discretion,
          constitute defamation, libel, slander, obscenity, pornography,
          profanity, hate speech, discrimination, or otherwise offend other
          users or our brand.
        </p>{" "}
        <h2>
          <a id="other-websites"></a>7. OTHER WEBSITES
        </h2>{" "}
        <p>
          Please be aware that third-party websites and social media platforms
          accessible through our Site(s) and/or Services have their own privacy
          and data collection policies and practices. We are not responsible for
          any actions, content of websites, or privacy policies of such third
          parties. You should check the applicable privacy policies of those
          third parties before providing information to them.
        </p>{" "}
        <h2>
          <a id="compliance-with-laws-and-law-enforcement"></a>8. COMPLIANCE
          WITH LAWS AND LAW ENFORCEMENT
        </h2>{" "}
        <p>
          We cooperate with government and law enforcement officials and private
          parties to enforce and comply with the law. We may disclose Personal
          Information, Usage Data, and any other information about you to
          government or law enforcement officials or private parties if, in our
          sole discretion, we believe it is necessary or appropriate to respond
          to legal requests (including court orders, investigative demands, and
          subpoenas), to protect the safety, property, or rights of ourselves,
          users, or any other third party, to prevent or stop any illegal,
          unethical, or legally actionable activity, to enforce this Privacy
          Policy, our Terms of Use, or any other agreement you have with us, or
          to comply with law.
        </p>{" "}
        <h2>
          <a id="other-transfers"></a>9. OTHER TRANSFERS
        </h2>{" "}
        <p>
          We may share Personal Information and Usage Data with businesses
          controlling, controlled by, or under common control with us. If we are
          merged, acquired, or sold, or in the event of a transfer of some or
          all of our assets, we may disclose or transfer Personal Information
          and Usage Data in connection with such transaction. You will have the
          opportunity to opt out of any such transfer if, in our reasonable
          judgment, the new entity plans to handle your information in a way
          that differs materially from this policy.
        </p>{" "}
        <h2>
          <a id="how-long-we-retain-your-data"></a>10. HOW LONG WE RETAIN YOUR
          DATA
        </h2>{" "}
        <p>
          We retain your Personal Information for as long as we have a
          relationship with you. We also retain your Personal Information for a
          period of time after our relationship with you has ended where there
          is an ongoing business need to retain it. This includes retention to
          comply with our legal, regulatory, tax, and/or accounting obligations.
          Generally, we retain Personal Information of individuals&nbsp;who
          engage with our Site or Services&nbsp;for approximately seven (7)
          years after their last login or expiration of
          their&nbsp;Lenny.ai&nbsp;subscription (whichever is later). If an
          individual creates an account with us as the parent or legal guardian
          of a student, we will retain their Personal Information for
          approximately the same period of time as we retain the Personal
          Information of the underlying student or three (3) years, whichever is
          longer. If you are an individual associated with a Client using our
          Lenny.ai for Business service, we will retain your Personal
          Information for approximately three (3) years after the last active
          scholarship posted by the Client under the account expires. The exact
          period for which we retain your Personal Information may be longer or
          shorter in accordance with our data retention policies and applicable
          law.
        </p>{" "}
        <h2>
          <a id="childrens-privacy"></a>11. CHILDREN’S PRIVACY
        </h2>{" "}
        <p>
          The Site is not intended for children under the age of 13 and we do
          not knowingly collect or sell Personal Information from children under
          the age of 13. If we become aware that we have inadvertently received
          Personal Information from a child under the age of 13, we will delete
          such information from our records. We do not sell Personal Information
          of any person we know is under the age of 16.
        </p>{" "}
        <h2>
          <a id="personal-information-we-collect-as-a-service-provider-for-our-clients"></a>
          12. PERSONAL INFORMATION WE COLLECT AS A SERVICE PROVIDER FOR OUR
          CLIENTS
        </h2>{" "}
        <p>
          When acting on behalf of our Clients as a service provider, we may
          collect, use, and disclose Personal Information for the purpose of
          servicing those Clients. The specific categories of Personal
          Information collected and how that Personal Information is used or
          disclosed is determined by our Client and will vary.
        </p>{" "}
        <p>
          The Personal Information collected, used, and disclosed on our
          Clients’ behalf is governed by their privacy policies. If you request
          information about the Personal Information we collect, use, and
          disclose on our Clients’ behalf, we will direct you to contact the
          applicable Client(s).
        </p>{" "}
        <h2>
          <a id="additional-consumer-rights"></a>13. ADDITIONAL CONSUMER RIGHTS
        </h2>{" "}
        <p>
          If you are a resident of California, Colorado, Connecticut, Utah, or
          Virginia you have additional rights in regards your Personal
          Information as defined below. If you request to exercise any of the
          following rights with regard to Personal Information we collect, use,
          and/or disclose as a service provider on behalf of our Client(s), we
          will direct you to contact the applicable Client(s).
        </p>{" "}
        <h3>
          <a id="right-to-know"></a>a. Right to Know
        </h3>{" "}
        <p>
          You have the right to request twice per 12-month period that we
          provide you (i) the categories or specific pieces of Personal
          Information we collected about you; (ii) the categories of sources
          from which your Personal Information was collected; (iii) the business
          or commercial purpose for which we collected your Personal
          Information; (iv) the categories of Third Parties with whom we shared
          your Personal Information; (v) the categories of your Personal
          Information we sold and the categories of Third Parties to whom we
          sold such category Personal Information; (vi) our business or
          commercial purposes for selling/sharing Personal Information; and
          (vii) the categories of your Personal Information we disclosed for a
          business purpose to Third Parties and the categories of Third Parties
          to whom we disclosed such categories of Personal Information.
        </p>{" "}
        <p>
          We are not permitted to provide access to specific pieces of Personal
          Information if disclosure of the Personal Information creates a high
          risk of potential harm such as financial information, social security
          numbers, and driver’s license numbers. We will not provide specific
          pieces of Personal Information in response to a request unless you
          expressly request them.
        </p>{" "}
        <h3>
          <a id="right-to-deletion"></a>b. Right to Deletion
        </h3>{" "}
        <p>
          You have the right to request that we delete any Personal Information
          we have collected about you. Please understand that we are not
          required to honor a deletion request if a legal exemption applies such
          as if we need the information to complete a requested or reasonably
          anticipated transaction, prevent security incidents or fraud, enable
          internal uses that are reasonably aligned with your expectations, or
          comply with legal obligations.
        </p>{" "}
        <h3>
          <a id="right-to-correct"></a>c. Right to Correct
        </h3>{" "}
        <p>
          Please contact us at
          <a href="mailto:SUPPORT@LENNY.AI">
            <u>SUPPORT@LENNY.AI</u>
          </a>
          to update your Personal Information.
        </p>{" "}
        <p>
          You have the right to request that we correct inaccurate Personal
          Information we maintain about you. After you request to correct
          inaccurate Personal Information, we will provide instructions for you
          to provide us with optional documentation to support your request and
          we will consider it.
        </p>{" "}
        <p>
          We may decline to correct your Personal Information if a legal
          exemption applies such as if we determine that your request is
          fraudulent or abusive or if we determine that the information we have
          on file is more likely than not correct. We may decide to delete your
          allegedly inaccurate Personal Information instead of correcting it.
        </p>{" "}
        <h3>
          <a id="right-to-opt-out-from-the-sale-or-sharing-of-personal-information"></a>
          d. Right to Opt-Out from the Sale or Sharing of Personal Information
        </h3>{" "}
        <p>
          You have the right to direct us not to sell or share your Personal
          Information to Third Parties. Please note that opting out of the sale
          or sharing of Personal Information will not impact any sale or sharing
          of your Personal Information that may have occurred prior to the date
          of your request.
        </p>{" "}
        <p>
          We will recognize browser-based opt-out signals as required by law,
          specifically Global Privacy Control. If we receive an opt-out signal
          and are able to identify the consumer to whom the signal relates, we
          will treat the signal as a request to opt out of the sale or sharing
          of all Personal Information we have on file for the consumer and
          disable cookies and other tracking tools placed by Third Parties. If
          we receive an opt-out signal and are not able to identify the consumer
          to whom the signal relates, we will treat the signal as a request to
          opt out limited to the Personal Information we collect from the
          consumer during the online session during which the signal is present
          and to disable cookies and other tracking tools placed by Third
          Parties.
        </p>{" "}
        <h3>
          <a id="right-to-limit-use-of-sensitive-personal-information"></a>e.
          Right to Limit Use of Sensitive Personal Information
        </h3>{" "}
        <p>
          If you are a resident of California, you have the right to limit our
          use of your Sensitive Personal Information to the purposes listed in
          11 Cal. Code of Regs. § 7027(m) such as to provide the goods and
          services reasonably expected by the average consumer who requests such
          goods and services, to prevent fraud, and to verify or maintain the
          quality or safety of goods or services we provide.
        </p>{" "}
        <p>
          If you are a Connecticut, Colorado, Utah, or Virginia resident, and
          you make a request to limit the use of your Sensitive Personal
          Information as defined under your applicable state’s law, we will
          treat that request as a revocation of your consent for us to collect
          and process your Sensitive Personal Information and will delete your
          Sensitive Personal Information subject to any exemptions available
          under applicable law.
        </p>{" "}
        <h3>
          <a id="right-to-appeal"></a>f. Right to Appeal
        </h3>{" "}
        <p>
          You have the right to appeal our decision to deny, in full or in part,
          your exercise of privacy rights. If we deny your appeal, we will
          provide you with a written explanation of the reasons for our
          decision. If you are not satisfied with our explanation for the denial
          of your appeal, you may contact your state Attorney General to submit
          a complaint about the appeal results and we will provide you with
          instructions for doing so.
        </p>{" "}
        <h3>
          <a id="how-to-submit-a-request"></a>g. How to Submit a Request
        </h3>{" "}
        <p>
          You may submit a request by calling us at 00972 99561694, emailing us
          at&nbsp;
          <a href="mailto:SUPPORT@LENNY.AI">
            <u>SUPPORT@LENNY.AI</u>
          </a>
          . If you submit your request via email, please include at least your
          name, mailing address, email address, and a description of your
          request.
        </p>{" "}
        <p>
          After you submit your request, we may contact you to obtain additional
          information necessary to verify your identity. For example, we may
          require you to verify certain information in our files or submit a
          signed declaration under penalty of perjury verifying your identity.
          We will not process your request without verifying your identity, so
          please respond promptly. If you do not timely respond to our requests
          for information, we may deny your request.
        </p>{" "}
        <p>
          We will process verified requests to Know, Delete, or Correct Personal
          Information within 45 days of receipt, subject to any applicable
          exemptions and extensions permitted by law up to 90 days. We will
          process Opt-Out and Limit Use of Sensitive Personal Information within
          15 business days of receipt. We will process Appeals within the time
          period required under applicable law, but generally within 45 days of
          receipt. If you have an online account with us, we will provide the
          response to your request via the online account, otherwise, we will
          give you the option to choose between mail and electronic delivery. We
          will retain a copy of your request for at least two years as required
          by law.
        </p>{" "}
        <h3>
          <a id="authorized-agent"></a>h. Authorized Agent
        </h3>{" "}
        <p>
          If you are an authorized agent submitting a request on behalf of a
          California, Colorado, Connecticut, Utah, or Virginia resident, you
          must provide (i) a copy of a lawful power of attorney, (ii) a written
          signed authorization from the consumer, or (iii) other documentation
          evidencing your role as a guardian, conservator, or similar legal
          relationship over the consumer along with proof of your identity. You
          may provide this documentation via email to&nbsp;
          <a href="mailto:SUPPORT@LENNY.AI">
            <u>SUPPORT@LENNY.AI</u>
          </a>
          &nbsp;after submitting the request. We may contact you and/or the
          consumer on whose behalf you claim to act to verify your
          authorization.
        </p>{" "}
        <h3>
          <a id="non-discrimination-notice"></a>i. Non-Discrimination Notice
        </h3>{" "}
        <p>
          We will not discriminate against any consumer for exercising their
          privacy rights under law or this Privacy Policy.
        </p>{" "}
        <h3>
          <a id="california-do-not-track-disclosure"></a>j. California “Do Not
          Track” Disclosure
        </h3>{" "}
        <p>
          Do Not Track is a web browser privacy preference that causes the web
          browser to broadcast a signal to websites requesting that a user’s
          activity not be tracked. At this time, our Site and Services do not
          respond to “do not track” signals.
        </p>{" "}
        <h3>
          <a id="california-site-ownership-disclosure"></a>k. California Site
          Ownership Disclosure
        </h3>{" "}
        <p>
          Under California Civil Code Section 1789.3, California residents are
          entitled to the following information: The provider of the Site is
          Lenny.ai, 831 N Tatnall Street Suite M #224 Wilmington, Delaware
          19801. To file a complaint regarding the Site or to receive further
          information about the Site, please contact us. You may also contact
          the Complaint Assistance Unit of the Division of Consumer Services of
          the Department of Consumer Affairs in writing at 1625 North Market
          Blvd., Suite N 112, Sacramento, California 95834 or by telephone at
          (800) 952-5210.
        </p>{" "}
        <h2>
          <a id="additional-nevada-consumer-rights"></a>14. ADDITIONAL NEVADA
          CONSUMER RIGHTS
        </h2>{" "}
        <p>
          If you are a resident of Nevada, you have the right to direct us not
          to sell your Personal Information to Third Parties. You may exercise
          these rights by sending an email to&nbsp;
          <a href="mailto:SUPPORT@LENNY.AI">
            <u>SUPPORT@LENNY.AI</u>
          </a>
          with the subject line “Nevada Opt Out.” Although Nevada law gives us
          60 days to respond to your request, plus an additional 30 days in
          certain instances, we will strive to respond to your request as soon
          as reasonably possible. We may request additional information in order
          to verify your identity or locate your Personal Information.
        </p>{" "}
        <h2>
          <a id="contact-us"></a>15. CONTACT US
        </h2>{" "}
        <p>
          If you have any questions about our Site, Services, or this Privacy
          Policy, please email us at&nbsp;
          <a href="mailto:SUPPORT@LENNY.AI">
            <u>SUPPORT@LENNY.AI</u>
          </a>
          . You may also send us a letter to the following address:
          <p
            className="textDetail"
            style={{
              marginTop: "20px",
            }}
          >
            email address :
            <a href="https://business.lenny.ai/" className="anchor">
              <u>SUPPORT@LENNY.AI</u>
            </a>
          </p>
          <p className="textDetail">company : Better Online Ltd </p>
          <p className="textDetail">
            {" "}
            business :{" "}
            <a href="https://business.lenny.ai/">
              <u>Lenny.ai</u>
            </a>
          </p>
          <p className="textDetail">
            Address : Shalva Street 75, herzliya Israel
          </p>
          <p className="textDetail">Phone no : 00972 99561694</p>
          <p className="textDetail">
            website names :{" "}
            <a href="https://lenny.ai/">
              <u>Lenny.ai</u>
            </a>{" "}
            ,{" "}
            <a href="https://business.lenny.ai/">
              <u>business.lenny.ai</u>
            </a>
          </p>
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
