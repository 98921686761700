import React, { useRef, useEffect, useState } from "react";
import Navbar from "./Navbar";
import { Row, Col, Form, Input, Button, message, Spin } from "antd";
import Text from "antd/lib/typography/Text";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { LoadingOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { API_BASE_URL } from "configs/AppConfig";
import useAnalyticsEventTracker from "useAnalyticsEventTracker";
import mixpanel from "mixpanel-browser";

const ForgotPassword = (props) => {
  const gaEventTracker = useAnalyticsEventTracker("Forgot Password");
  const history = useHistory();
  const mobileView = window.innerWidth < 915;
  const [userEmail, setUserEmail] = useState("");
  const [askOtp, setAskOtp] = useState("");
  const [newPass, setNewPass] = useState("");
  const [enteredOtp, setEnteredOtp] = useState(""); // State to hold entered OTP
  const [confirmPassword, setConfirmPassword] = useState("");
  const [invalidOTP, setInvalidOTP] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);

  const requestOtp = async (email) => {
    try {
      setAskOtp(true);
      const emailBody = {
        email: email,
      };
      const data = await fetch(
        `${API_BASE_URL}api/v1/register-applicants/forgot-password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailBody),
        }
      );
      const response = await data.json();
      if (data.status === 201) {
        message.success("OTP sent successfully");
      }
      if (data.status === 400) {
        message.error(response?.error?.message);
        setAskOtp(false);
        setNewPass(false);
      }
      console.log(response);
    } catch (error) {
      console.log("Error occured while requesting OTP", error);
    }
  };

  const submitPassword = async (password) => {
    try {
      setBtnLoader(true);
      const body = {
        email: userEmail,
        otp: enteredOtp,
        newPassword: password,
      };
      const response = await fetch(
        `${API_BASE_URL}api/v1/register-applicants/reset-password`,
        // "https://api-scholarship.lenlen.ai/api/v1/register-applicants/reset-password",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        }
      );
      const data = await response.json();

      if (response.status === 201) {
        message.success("Password reset successfully");
        localStorage.setItem(
          "userInfo",
          typeof data?.data?.user === "string"
            ? data?.data?.user
            : JSON.stringify(data?.user)
        );
        localStorage.setItem(
          "refresh_token",
          typeof data?.data?.refreshToken === "string"
            ? data?.data?.refreshToken
            : JSON.stringify(data?.data?.refreshToken)
        );
        localStorage.setItem(
          "token",
          typeof data?.data?.accessToken === "string"
            ? data?.data?.accessToken
            : JSON.stringify(data?.data?.accessToken)
        );
        history.push("/dashboard");
        setBtnLoader(false);
        let label = {
          userEmail: body.email,
        };
        gaEventTracker(
          "forgot password button successful ",
          JSON.stringify(label)
        );
        mixpanel.track(
          "forgot password button successful ",
          JSON.stringify(label)
        );
      } else if (response.status === 400) {
        setInvalidOTP(true);
        message.error(data?.error?.message);
        console.log("Error while saving new password", data);
        setBtnLoader(false);
      }
    } catch (error) {
      console.log("Error while saving new password", error);
      setBtnLoader(false);
    }
  };

  const OtpInput = () => {
    const [otp, setOtp] = useState(["", "", "", "", "", ""]); // Array to hold OTP digits
    const [isCompleted, setIsCompleted] = useState(false); // Flag to track completion status
    const refs = [useRef(), useRef(), useRef(), useRef(), useRef(), useRef()]; // Refs for each input box

    useEffect(() => {
      setIsCompleted(otp.every((digit) => digit !== ""));
    }, [otp]);

    const handleInputChange = (index, e) => {
      const value = e.target.value;
      if (!isNaN(value) && value !== "") {
        // Check if input is a number
        const newOtp = [...otp];
        newOtp[index] = value.slice(-1); // Update OTP array with new value
        setOtp(newOtp);

        if (index < refs.length - 1 && value !== "") {
          // Move focus to the next input box if available
          refs[index + 1].current.focus();
        }
      }
    };

    const handleKeyDown = (index, e) => {
      if (e.key === "Backspace") {
        if (otp[index] === "") {
          // Move focus to the previous input box on backspace if the current input box is empty
          if (index > 0) {
            refs[index - 1].current.focus();
          }
        } else {
          // Clear the current digit if the input box is not empty
          const newOtp = [...otp];
          newOtp[index] = "";
          setOtp(newOtp);
        }
      }
    };

    const handleSubmit = () => {
      // Handle OTP submission
      const enteredOtp = otp.join("");
      setEnteredOtp(enteredOtp);
      setNewPass(true);
      // Structure()
      message.success("OTP submitted successfully!");
    };

    return (
      <div>
        <Row gutter={[16, 16]}>
          {otp.map((digit, index) => (
            <Col key={index} xs={4} sm={4} md={4} lg={4}>
              <Input
                ref={refs[index]} // Assign ref to the input box
                value={digit}
                onChange={(e) => handleInputChange(index, e)}
                onKeyDown={(e) => handleKeyDown(index, e)}
                maxLength={1} // Limit input to single digit
                style={{ width: "100%", marginTop: "15px" }} // Set width to 100%
              />
            </Col>
          ))}
        </Row>
        <br />
        <Text
          style={{ textAlign: "center", fontSize: "14px", fontWeight: "400" }}
        >
          I didn't receive any code.{" "}
          <Text
            style={{
              cursor: "pointer",
              color: "#8d96ff",
            }}
            onClick={() => {
              requestOtp(userEmail);
            }}
          >
            Resend OTP
          </Text>
        </Text>
        <Button
          type="primary"
          onClick={handleSubmit}
          disabled={!isCompleted}
          style={{
            backgroundColor: "#8d96ff",
            color: "white",
            fontWeight: "400",
            letterSpacing: "0.5px",
            fontSize: "18px",
            border: "none",
            cursor: "pointer",
            padding: "20px",
            lineHeight: "0",
            borderRadius: "7px",
            marginTop: "15px",
            cursor: !isCompleted ? "not-allowed" : "pointer",
          }}
        >
          Submit
        </Button>
        {/* {enteredOtp && <p>Entered OTP: {enteredOtp}</p>} Display entered OTP */}
      </div>
    );
  };

  const Forgot = () => {
    return (
      <div>
        <h1>Forgot Password</h1>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: "400",
          }}
        >
          To reset the password, please provide your email ID. An OTP will be
          sent to your email ID to verify your Lenny account.
        </Text>
        <Form
          onFinish={(values) => {
            setUserEmail(values.email);
            setAskOtp(true);
            requestOtp(values.email);
          }}
        >
          <br />
          <Text
            style={{
              fontSize: "18px",
              fontWeight: "600",
            }}
          >
            Email:
          </Text>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: "Please input your email address!",
                type: "email",
              },
            ]}
          >
            <Input
              placeholder="e.g. johnsmith@gmail.com"
              style={{
                padding: "15px",
                borderRadius: "7px",
                border: "1px solid black",
                margin: "10px 0",
              }}
              // value={userEmail}
              // onChange={(e) => setUserEmail(e.target.value)}
            />
          </Form.Item>
          <Form.Item>
            <Button
              // type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#8d96ff",
                color: "white",
                fontWeight: "400",
                letterSpacing: "0.5px",
                fontSize: "18px",
                border: "none",
                cursor: "pointer",
                padding: "20px",
                lineHeight: "0",
                borderRadius: "7px",
                marginTop: "5px",
              }}
            >
              Get OTP
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const OtpDesign = ({ email }) => {
    return (
      <div>
        <h1>
          <span
            onClick={() => {
              setAskOtp(false);
              setNewPass(false);
            }}
            style={{
              cursor: "pointer",
              fontSize: "19px",
            }}
          >
            <ArrowRightOutlined />
          </span>{" "}
          OTP Verification
        </h1>
        <Text
          style={{
            fontSize: "18px",
            fontWeight: "400",
          }}
        >
          Enter the code from the email we sent to your email id {email}
        </Text>
        <Form
          onFinish={() => {
            message.error("This feature is not available yet");
          }}
        >
          <OtpInput />
        </Form>
      </div>
    );
  };

  const NewPassword = () => {
    setAskOtp(false);
    const [newPassword, setNewPassword] = useState("");

    const handleNewPasswordChange = (e) => {
      setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
      setConfirmPassword(e.target.value);
    };

    const handleSubmit = async (values) => {
      if (values?.newPassword !== values?.confirmPassword) {
        message.error("Passwords do not match");
        return;
      }
      setConfirmPassword(values.confirmPassword);
      await submitPassword(values.confirmPassword);
      setNewPassword("");
      // message.success('Password submitted successfully!');
    };
    return (
      <div>
        <h1>Reset Password</h1>
        <Form onFinish={handleSubmit}>
          <Text
            style={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Enter new password
          </Text>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password
              // value={newPassword}
              // onChange={handleNewPasswordChange}
              placeholder="Enter password"
              style={{
                padding: "10px",
                borderRadius: "7px",
                border: "1px solid black",
                margin: "10px 0",
              }}
            />
          </Form.Item>
          <Text
            style={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            Confirm password
          </Text>
          <Form.Item
            name="confirmPassword"
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
            ]}
          >
            <Input.Password
              // value={confirmPassword}
              // onChange={handleConfirmPasswordChange}
              placeholder="Confirm password"
              style={{
                padding: "10px",
                borderRadius: "7px",
                border: "1px solid black",
                margin: "10px 0",
              }}
            />
          </Form.Item>
          {invalidOTP ? (
            <Text
              style={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#8d96ff",
                cursor: "pointer",
              }}
              onClick={() => {
                requestOtp(userEmail);
                setNewPass(false);
                setAskOtp(true);
              }}
            >
              Resend OTP
            </Text>
          ) : null}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                backgroundColor: "#8d96ff",
                color: "white",
                fontWeight: "400",
                letterSpacing: "0.5px",
                fontSize: "18px",
                border: "none",
                cursor: "pointer",
                // padding: "20px",
                lineHeight: "0",
                borderRadius: "7px",
              }}
            >
              Submit{" "}
              {btnLoader && (
                <LoadingOutlined style={{ marginRight: 10, lineHeight: "0" }} />
              )}
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const Structure = () => {
    console.log(newPass);
    if (newPass) {
      return <NewPassword />;
    }
    if (askOtp) {
      return <OtpDesign email={userEmail} />;
    }
    if (!askOtp && !newPass) {
      return <Forgot />;
    }
  };
  // console.log(askOtp);
  useEffect(() => {
    let label = {
      screenName: "forgot password",
    };
    gaEventTracker("forgot password screen ", JSON.stringify(label));
    mixpanel.track("forgot password screen ", JSON.stringify(label));
  }, []);

  return (
    <div>
      <Navbar />
      <Row
        style={{
          background: "#ffffff",
          // height: "80vh"
        }}
      >
        <Col xs={24} sm={24} md={24} lg={24}>
          <div className="d-flex flex-column justify-content-center h-100">
            <Row
              className="dFlex"
              style={{
                height: "90vh",
              }}
            >
              <Col
                style={{
                  width: mobileView ? "100%" : "45%",
                  padding: `${mobileView ? "30px" : "80px"}`,
                }}
                className="order1"
              >
                <Structure />
              </Col>
              <Col
                className="order2"
                style={{
                  width: "55%",
                  backgroundImage: 'url("/new/rectangle.png")',
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right center",
                  backgroundSize: "90%",
                  display: mobileView ? "none" : "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{
                    backgroundImage: 'url("/new/chatNavbg.png")',
                    backgroundSize: "cover",
                    width: "100%",
                    // display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    display: mobileView ? "none" : "flex",
                  }}
                >
                  <img
                    src="/new/chatNavRobo.png"
                    alt=""
                    style={{
                      width: "50%",
                      // height: "485px",
                      // margin: '130px 0 0 0'
                    }}
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ForgotPassword;
