import { useHistory } from "react-router-dom";
import React, { useState, useCallback } from "react";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";
import "./style.css";
import Footer from "./footer";
const TermsConditions = () => {
  const history = useHistory();
  const navToPrivacy = () => {
    history.push("/privacy");
    // console.log('navigate')
  };
  return (
    <div>
      <div
        className="term_container"
        style={{
          background:
            "linear-gradient(180deg, #DFE2FF 0%, #FFF 60.3%), url(/img/pricing/bg.png)",
          padding: "50px 50px 0 50px",
        }}
      >
        <h2
          style={{
            fontFamily: "Poppins",
            fontSize: "32px",
            letterSpacing: "-0.96px",
          }}
        >
          Terms of Use
        </h2>

        <p>
          These Terms of Use apply to all websites that are owned, operated, and
          maintained by or for Better Online Ltd. (“we,” “us,” or “our”)
          including
          <a href="https://lenny.ai/"> Lenny.AI </a>,
          <a href="https://business.lenny.ai/">Business.lenny.ai</a> ,
          <a href="https://apply.me/">Apply.me</a> , and other websites on which
          these Terms of Use are linked (“Site(s)”) as well as your use of the
          services we provide and other transactions and engagements you might
          have with us (collectively, “Services”). These Terms of Use take
          effect when you click or check the applicable button or box associated
          with these Terms of Use or, if earlier, when you visit a Site or use
          any of the Services (“Effective Date”).
        </p>

        <p
          style={{
            color: "black",
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "26px",
          }}
        >
          THESE TERMS OF USE TOGETHER WITH THE{" "}
          <a onClick={navToPrivacy}>PRIVACY POLICY </a> AND ANY ADDITIONAL
          AGREEMENTS (DEFINED BELOW) SET FORTH THE LEGALLY BINDING TERMS
          GOVERNING YOUR USE OF THE SITE(S) AND SERVICES.
        </p>

        <p
          style={{
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "26px",
          }}
        >
          We may update these Terms of Use from time to time and if we
          determine, in our sole discretion, that such update is material, we
          will notify you of such update either via email or by posting notice
          of such changes on one or more of the Sites, or by other reasonable
          means. Your continued access to the Site(s) or use of the Services
          after our provision of a notice of an updated version of these Terms
          of Use shall constitute your consent to such updated Terms of Use.
        </p>
      </div>

      <div
        className="term_container"
        style={{
          padding: "0 50px",
        }}
      >
        <p
          style={{
            fontSize: "14px",
            fontWeight: "500",
            textDecorationLine: "underline",
          }}
        >
          Table of Contents
        </p>
        <h3>
          <a id="access-and-use-of-services"></a>1.1 Access and Use of Services
        </h3>{" "}
        <p>
          You may access and use the Site(s) and/or Services offered by us in
          accordance with these Terms of Use. You may not access or use the
          Site(s) and/or Services for: (i) developing a product or service that
          could reasonably be regarded as being competitive with the
          Site(s)/Services, or (ii) monitoring the availability, performance or
          functionality of the Site(s)/Services, or for any other benchmarking
          or competitive purpose.
        </p>{" "}
        <p>
          <strong>
            You shall not, and shall not permit third parties to, block, mask or
            obscure advertisements that appear on the Website.
          </strong>
        </p>{" "}
        <h3>
          <a id="customer-account"></a>1.2 Customer Account
        </h3>{" "}
        <p>
          To utilize the Services, you may be required to create an account
          associated with a valid email address (“Account”). Upon Account
          creation with our Lenny.AI, we may also provide you with a unique
          service-specific email address as part of your Account (“Lenny Email
          Account”). You are solely responsible for your Account, including for:
          (i) controlling the access to, and use and security of, the Account
          and your Content (as defined below), (ii) maintaining the security of
          the passwords and other measures used to protect access to the
          Account, and (iii) all instructions provided to us through the
          Account, whether or not authorized by you. We are not responsible for
          unauthorized access to the Account. You will contact us immediately if
          you believe an unauthorized third party may be using the Account.
          Nothing in the foregoing requires us to monitor the Account for
          unauthorized access or any other security breach.
        </p>{" "}
        <p>
          “Content” means any information you provide and/or, if you are a
          Client (defined below), that we collect on your behalf in connection
          with establishing your Account or your use of the Site(s)/Services.
          For example, and not limitation, your Content includes information you
          submit in connection with applying for a scholarship, registering for
          access to a Site or use of a Service, or providing a testimonial or
          story. A “Client” is a business or non-profit organization on whose
          behalf we collect and process information as a service provider.
        </p>{" "}
        <h3>
          <a id="separate-services-disclaimer"></a>1.3 Separate Services;
          Disclaimer
        </h3>{" "}
        <p>
          We may from time to time advertise to you, either through the
          Sites(s), Services, or separate correspondence, third-party services
          or offers that are not included as part of the Services, such as
          services offered by third-party scholarship providers (“Separate
          Services”). You provide your affirmative consent to receive offers for
          Separate Services through email (such consent may be withdrawn at any
          time by sending an email to{" "}
          <a href="mailto:unsubscribe@lenny.ai">
            <u>unsubscribe@lenny.ai</u>
          </a>
          ). You agree to review all terms of service and other agreements
          related to all Separate Services. You are solely responsible for
          strict compliance with any such terms of service or other agreements
          and for any liability arising from your use of the Separate Services.
          NOTWITHSTANDING ANYTHING TO THE CONTRARY IN THESE TERMS OF USE, WE
          MAKE NO REPRESENTATIONS OR WARRANTIES REGARDING THE SEPARATE SERVICES
          AND ARE NOT BE RESPONSIBLE FOR ANY DAMAGES CAUSED BY THE SEPARATE
          SERVICES, INCLUDING WITHOUT LIMITATION ANY DAMAGES ARISING OUT OF ANY
          MISREPRESENTATIONS OR VIOLATIONS OF LAW. OUR ADVERTISEMENT OF ANY
          SEPARATE SERVICE DOES NOT CONSTITUTE AN ENDORSEMENT OF THE SEPARATE
          SERVICE.
        </p>{" "}
        <h3>
          <a id="services-changes"></a>1.4 Services Changes
        </h3>{" "}
        <p>
          We may change or discontinue any of the Site(s)/Services or change or
          remove features or functionality of the Site(s)/Services from time to
          time, without any liability to you. We will use commercially
          reasonable efforts to notify you of any material change, as determined
          by us, to or discontinuation of the Site(s)/Services.
        </p>{" "}
        <h3>
          <a id="suspension-of-access"></a>1.5 Suspension of Access
        </h3>{" "}
        <p>
          At any time, we may suspend the provision of the Site(s)/Services and
          remove any of your Content transmitted via the Site(s)/Services
          without liability: (i) if we believe that any Site/Service is being
          used in violation of these Terms of Use or applicable law, (ii) if we
          believe that the use of the Site/Services may pose a security risk to
          us or any third party, (iii) if ordered by a law enforcement or
          government agency or otherwise in order to comply with applicable law,
          (iv) if necessary for operation, maintenance or expansion of our
          Site(s)/Services, or for other technological purposes, (v) if required
          or requested by a Service Provider (as defined in the Privacy Policy)
          or if a Service Provider ceases to provide services to us, (vi) if
          Customer fails to fulfill payment obligations, or (vii) we determine
          in our sole discretion that suspending or terminating the provision of
          the Site(s)/Services to you is in our best interest. To the extent
          practicable, we will use commercially reasonable efforts to notify you
          prior to suspending the Site(s)/Services to you, but notice is not
          required.
        </p>{" "}
        <h2>
          <a id="fees-billing"></a>2. Fees &amp; Billing
        </h2>{" "}
        <h3>
          <a id="fees"></a>2.1 Fees
        </h3>{" "}
        <p>
          In consideration for your use of some of the Services, you agree to
          pay us fees at then-current pricing for the Services as published from
          time to time on the applicable Site(s)/Services. All payments must be
          made in United States Dollars.
        </p>{" "}
        <p>
          We may in our sole discretion modify pricing upon the provision of at
          least 30 days’ notice to already-paying customers, by posting a notice
          on the applicable Site(s)/Services and sending an email to the address
          maintained for the relevant Account. Unless you notify us in writing
          of your termination of use of the Services within 14 days after
          delivery of such notice, you will be deemed to have consented to the
          updated pricing.
        </p>{" "}
        <h3>
          <a id="billing"></a>2.2 Billing
        </h3>{" "}
        <p>
          We will bill your payment method for the Services, if applicable,
          either as a one-time payment or in automatically reoccurring payments
          if you have an ongoing subscription. We will send a sales receipt to
          you upon request.
        </p>{" "}
        <p>
          Customers with ongoing subscriptions are responsible for maintaining
          current and complete payment method information on file with our
          payment processor at all times. Your payment method information,
          including credit/debit card number or PayPal account information, are
          stored by our payment processor and may be used for future or
          reoccurring payments. Changes to on-file payment method information,
          or requests to delete payment method information on file, must be
          received by us at least three (3) business days before the next
          scheduled payment in order to be processed in time for the next
          scheduled payment. We may change our payment processor at any time
          with or without notice to you.
        </p>{" "}
        <p>
          All charges are automatically made on your specific billing cycle date
          as disclosed during the enrollment process. If your Services begin
          with a free trial period, you must cancel the Services prior to the
          end of the free trial period in order to avoid being charged.
        </p>{" "}
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, YOU WAIVE ALL CLAIMS RELATING
          TO CHARGES ASSOCIATED WITH THE SERVICES UNLESS CLAIMED WITHIN SIXTY
          (60) DAYS AFTER THE APPLICABLE CHARGE.
        </p>{" "}
        <h3>
          <a id="refunds"></a>2.3 Refunds
        </h3>{" "}
        <p>
          <strong>All payments to us are non-refundable.</strong>
          Notwithstanding the foregoing, if you purchased a Service that began
          with a free trial period and do not cancel prior to the end of the
          free trial period, (i) the first fee paid following the free trial
          period is non-refundable and (ii) subsequent fees are non-refundable
          except that we will refund upon request the fee paid for the most
          recent billing cycle so long as you have not logged into or otherwise
          accessed or used the Service during the applicable billing cycle. If
          you purchased a Service that did not begin with a free trial period,
          fees are non-refundable except that we will refund upon request the
          fee paid for the most recent billing cycle so long as you have not
          logged into or otherwise accessed or used the Service during the
          applicable billing cycle. To request a refund related to the Lenny.AI
          Service, please contact us at{" "}
          <a>
            <u>SUPPORT@LENNY.AI</u>
          </a>{" "}
          or by calling customer service at 00972 99561694. At this time, no
          other Service may incur fees for which refunds may be available.
        </p>{" "}
        <h3>
          <a id="late-fees"></a>2.4 Late Fees
        </h3>{" "}
        <p>
          We may charge interest on overdueounts at the lesser of 1.5% per month
          or the maximum rate permitted by law, plus all reasonable expenses and
          costs of collection (including collection agency fees, attorney fees
          and court costs). The assessment of interest shall not limit our other
          rights and remedies for late payment, including our right to suspend
          or terminate access to the Services.
        </p>{" "}
        <h3>
          <a id="taxes"></a>2.5 Taxes
        </h3>{" "}
        <p>
          You are responsible for all taxes, feesd duties, including penalties
          and interest, imposed in connection with these Terms of Use and the
          Services (“Taxes”). You will pay our fees without reduction for Taxes.
          If we are obligated to collect or pay Taxes, the Taxes will be
          invoiced to you unless you provide us on a timely basis with a valid
          tax exemption certificate authorized by the appropriate taxing
          authority (along with any supporting documentation that we may require
          in connection with such tax exemption certificate). If any deduction
          or withholding is required by law, you will notify us and pay us any
          additional amounts necessary to ensure that the net amount that we
          receive, after any deduction and withholding, equals the amount we
          would have received if no deduction or withholding has been required.
          You shall provide to us original or certified copies of all Tax
          payments or other evidence of payment of Taxes by you with respect to
          transactions or payments under these Terms of Use.
        </p>{" "}
        <h3>
          <a id="third-party-fees"></a>2.6 Third Party Fees
        </h3>{" "}
        <p>
          Any third party fees (includingthout limitation credit card, debit
          card, PayPal and bank fees) (“Third Party Fees”) are your sole
          responsibility, and if charged to us, you will pay us an additional
          amount equaling the Third Party Fees in order to ensure that the net
          amount that we receive equals the amount we would have received if no
          Third Party Fees had been charged.
        </p>{" "}
        <h2>
          <a id="acceptable-use"></a>3. Acceptable Use
        </h2>{" "}
        <h3>3.1</h3>{" "}
        <p>
          You will use the Services in compliance with all applicable laws,
          ordinances, rules and regulations, will not violate or attempt to
          violate our system or network security, and will not misuse the
          Services in any way. You will not (i) engage in, solicit, or promote
          any activity that is illegal, invasive, violates the rights of others,
          or could subject us to liability to third parties, (ii) submit Content
          that is defamatory, misleading, fraudulent, obscene, distasteful,
          harassing, discriminatory, racially or ethnically offensive, contains
          sexually suggestive or explicit content, facilitates or promotes
          illegal activity, or contains illegal content, (iii) distribute
          viruses, worms, Trojan horses, corrupted files, hoaxes, or other items
          of a disabling, destructive, damaging, or deceptive nature, or (iii)
          disable, interfere with, or circumvent any aspect of the Services.
        </p>{" "}
        <h3>3.2</h3>{" "}
        <p>
          We may, but are not obligated to, monitor and remove Content, and it
          is your sole responsibility to monitor the content uploaded by you or
          on your behalf.
        </p>{" "}
        <h2>
          <a id="publicity"></a>4. Publicity
        </h2>{" "}
        <p>
          You grant to us and those acting under our authority, and any third
          party sponsor or co-sponsor of a scholarship (“
          <strong>Scholarship Partner</strong>”), the unrestricted, absolute,
          perpetual, irrevocable, royalty-free, worldwide right and license to
          use your name, address, photograph, likeness, voice, biographical and
          personal information, statements, application, essays, and any
          photograph, film, video, audio recording or other recording taken of
          you or such materials (collectively, “
          <strong>Promotional Content</strong>”), and to reproduce, copy,
          modify, create derivative works of, display, perform, exhibit,
          distribute, transmit or broadcast, publicly or otherwise, or otherwise
          use and permit to be used, the Promotional Content or any part
          thereof, whether alone or in combination with other materials
          (including but not limited to text, data, images, photographs,
          illustrations, and graphics, video or audio segments of any nature),
          in any media whatsoever, in connection with our marketing purposes or
          otherwise; and all the foregoing without any compensation, royalties,
          remuneration or consideration to you or to any third party, and you
          hereby waive all claims to compensation, royalties, remuneration,
          consideration, notice or permission in connection therewith.
        </p>{" "}
        <h2>
          <a id="customers-warranties"></a>5. Customer’s Warranties
        </h2>{" "}
        <p>
          You represent and warrant to us that (i) your Content is complete and
          accurate and does not infringe upon the copyrights, trademarks, rights
          of privacy, publicity, or other intellectual property rights of any
          person or entity; (ii) you have obtained permission from any person
          whose name, likeness, voice, or documents is included in your Content;
          (iii) our re-publication of all or part of your Content will not
          violate any third party rights; (iv) you have the requisite power and
          authority to enter into these Terms of Use and to perform all of your
          obligations hereunder, (v) you are a natural person over the age of 16
          or a legally-existing entity, and (iv) you are not named on any U.S.
          Government denied party list. You will update your Account information
          to maintain the accuracy of your Content during the term of these
          Terms of Use.
        </p>{" "}
        <h2>
          <a id="indemnification"></a>6. Indemnification
        </h2>{" "}
        <p>
          You will defend, indemnify, and hold harmless us, our affiliates and
          licensors, and each of their respective employees, officers,
          directors, agents and representatives, from and against any claims,
          damages, losses, liabilities, costs, fines and expenses (including
          reasonable attorneys’ fees) arising out of or relating to: (i) your
          actual or alleged use of the Site(s)/Services (including any
          activities under your Account), (ii) your breach of these Terms of Use
          or any violation of applicable law, or (iii) your Content, including
          any claim involving alleged infringement or misappropriation of
          third-party rights by or in connection with your Content.
        </p>{" "}
        <p>
          We will notify you of any claim subject to indemnification, provided
          that our failure to do so shall not affect your obligations hereunder
          except to the extent that our failure to notify you materially delays
          or prejudices your ability to defend the claim. At our option, you
          will have the right to defend against any such claim with counsel of
          your own choosing (subject to our written consent) and to settle such
          claim as you deems appropriate, provided that you will not enter into
          any settlement without our prior written consent and provided that we
          may, at any time, elect to take over control of the defense and
          settlement of the claim at your expense.
        </p>{" "}
        <h2>
          <a id="disclaimers-of-warranties-and-limitation-of-liability"></a>7.
          Disclaimers of Warranties and Limitation of Liability
        </h2>{" "}
        <h3>
          <a id="disclaimer-of-warranties"></a>7.1 Disclaimer of Warranties
        </h3>{" "}
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, THE SITE(S)/SERVICES ARE
          PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. WE AND OUR AFFILIATES
          MAKE NO REPRESENTATIONS OR WARRANTIES OF ANY KIND (EXPRESS, IMPLIED,
          STATUTORY OR OTHERWISE) REGARDING THE SITE(S), SERVICES, ADDITIONAL
          SERVICES, THIRD PARTY SERVICES, OR SEPARATE SERVICES (COLLECTIVELY, “
          <strong>ACCUMULATED SERVICES</strong>”), INCLUDING ANY WARRANTY THAT
          THE ACCUMULATED SERVICES WILL BE UNINTERRUPTED, ERROR FREE OR FREE OF
          HARMFUL COMPONENTS, THAT ANY CONTENT, INCLUDING YOUR CONTENT OR THIRD
          PARTY CONTENT, WILL BE SECURE OR NOT OTHERWISE LOST OR DAMAGED, OR
          THAT THERE WILL NOT BE ANY TYPOGRAPHICAL OR OTHER ERRORS IN
          APPLICATIONS. WE DO NOT GUARANTEE THE RECEIPT OF ANY SCHOLARSHIP, AND
          YOU WAIVE ANY CLAIM AGAINST US IN CONNECTION THEREWITH, EVEN IF YOU
          WERE OTHERWISE ENTITLED TO SUCH SCHOLARSHIP. TO THE FULLEST EXTENT
          PERMITTED BY LAW, WE AND OUR AFFILIATES HEREBY DISCLAIM ALL
          WARRANTIES, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY,
          SATISFACTORY QUALITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT, AND WARRANTIES ARISING OUT OF THE COURSE OF DEALING
          OR USAGE OF TRADE. YOU ACKNOWLEDGE THAT THERE ARE RISKS INHERENT IN
          INTERNET CONNECTIVITY AND THE SERVICES’ TECHNOLOGY THAT COULD RESULT
          IN THE LOSS OF YOUR CONTENT, PRIVACY, DATA, CONFIDENTIAL INFORMATION,
          AND/OR PROPERTY.
        </p>{" "}
        <h3>
          <a id="limitation-of-liability"></a>7.2 Limitation of Liability
        </h3>{" "}
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL WE OR ANY OF
          OUR AFFILIATES, OR ANY OF THEIR EMPLOYEES, OFFICERS, DIRECTORS,
          AGENTS, OR SUPPLIERS, BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL,
          SPECIAL, EXEMPLARY OR CONSEQUENTIAL DAMAGES (COLLECTIVELY “DAMAGES”),
          HOWEVER CAUSED AND UNDER ANY THEORY OF LAW (INCLUDING BREACH OF
          CONTRACT, TORT, STRICT LIABILITY, AND INFRINGEMENT), EVEN IF ADVISED
          OF THE POSSIBILITY OF DAMAGES AND EVEN IF DAMAGES ARE FORESEEABLE.
          THIS LIMITATION OF LIABILITY INCLUDES BUT IS NOT LIMITED TO, THE
          FOLLOWING DAMAGES: (A) YOUR INABILITY TO USE ANY OF THE ACCUMULATED
          SERVICES, INCLUDING AS A RESULT OF ANY (I) TERMINATION OR SUSPENSION
          OF THESE TERMS OF USE OR YOUR USE OF OR ACCESS TO THE ACCUMULATED
          SERVICES, (II) OUR DISCONTINUATION OF ANY OF THE SERVICES, OR (III)
          DOWNTIME, INCLUDING ANY UNANTICIPATED OR UNSCHEDULED DOWNTIME OF ALL
          OR A PORTION OF THE SERVICES FOR ANY REASON, (B) THE COST OF OR TIME
          INVOLVED IN THE PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, (C) ANY
          INVESTMENTS, EXPENDITURES, LOST OPPORTUNITIES, OR COMMITMENTS BY YOU
          IN CONNECTION WITH THESE TERMS OR USE OF THE ACCUMULATED SERVICES, AND
          (D) ANY UNAUTHORIZED ACCESS TO, ALTERATION OF, OR DELETION,
          DESTRUCTION, DAMAGE, LOSS OR FAILURE TO STORE ANY OF YOUR CONTENT.
        </p>{" "}
        <p>
          TO THE FULLEST EXTENT PERMITTED BY LAW, THE MAXIMUM AGGREGATE
          LIABILITY OF US OR ANY OF OUR AFFILIATES, OR ANY OF THEIR EMPLOYEES,
          OFFICERS, DIRECTORS, AGENTS OR SUPPLIERS, UNDER ANY THEORY OF LAW
          (INCLUDING FOR BREACH OF CONTRACT, TORT, STRICT LIABILITY, OR
          INFRINGEMENT), SHALL NOT EXCEED THE AMOUNT OF FEES ACTUALLY RECEIVED
          BY US FROM YOU DURING THE SIX MONTHS IMMEDIATELY PRECEDING THE FIRST
          CLAIM UNDER THESE TERMS OF USE. THIS IS AN AGGREGATE LIMIT. THE
          EXISTENCE OF MORE THAN ONE CLAIM SHALL NOT ENLARGE THIS LIMIT.
        </p>{" "}
        <p>
          YOU ACKNOWLEDGE THAT THE FOREGOING DISCLAIMERS OF WARRANTIES AND
          LIMITATIONS OF LIABILITY ARE ESSENTIAL PARTS OF THESE TERMS OF USE AND
          ABSENT SUCH DISCLAIMERS AND LIMITATIONS WE WOULD NOT AGREE TO PROVIDE
          ANY SERVICES TO YOU OR ENTER INTO THESE TERMS OF USE.
        </p>{" "}
        <p>
          THE LAWS OF CERTAIN JURISDICTIONS DO NOT ALLOW LIMITATIONS ON IMPLIED
          WARRANTIES OR THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF SUCH
          LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS
          MAY NOT APPLY, AND YOU MAY HAVE ADDITIONAL RIGHTS.
        </p>{" "}
        <h2>
          <a id="proprietary-rights-and-confidentiality"></a>8. Proprietary
          Rights and Confidentiality
        </h2>{" "}
        <h3>
          <a id="your-content"></a>8.1 Your Content
        </h3>{" "}
        <p>
          As between you and us, you own all right, title and interest in and to
          your Content. You grant to us and our successors and assigns, and to
          such other persons as we may designate from time to time
          (“sub-licensees”), a non-exclusive, worldwide, unrestricted,
          royalty-free, irrevocable license to take, use, publish, reproduce,
          distribute, and/or copyright any and all portions of your Content,
          including, but not limited to, photographs, text, images, and
          recordings of you, your name, voice, and likeness, either still,
          motion picture, or sound, for marketing, advertising, promotion, and
          trade purposes in any and all publications and media, whether now
          known or hereinafter invented, in perpetuity. Without limiting any of
          the foregoing, you irrevocably consent to and authorize the
          reproduction, alteration, and publication of such Content, in whole or
          in part, or in any altered or derivative forms, without any future
          compensation to you and you waive any right to inspect or approve any
          use of your Content by us. You specifically release us and our
          sub-licensees of liability of any and every nature, including, but not
          limited to, claims of defamation, privacy, or publicity arising out of
          or in connection with any use of your Content, including your name,
          voice, likeness, image, or recording.
        </p>{" "}
        <h3>
          <a id="services"></a>8.2 Services
        </h3>{" "}
        <p>
          As between you and us, we or our licensors own and reserve all rights,
          title and interest in and to the Site(s)/Services, our trademarks,
          logos and service marks and all modifications to any of the forgoing
          and all related intellectual property rights, including, without
          limitation, all content accessible from the Site(s)/Services (other
          than your Content), whether registered or not and whether registrable
          or not. You are required to retain all copyright, trademark and other
          notices on our materials and content.
        </p>{" "}
        <h3>
          <a id="suggestions"></a>8.3 Suggestions
        </h3>{" "}
        <p>
          Should you provide us with any suggestions or ideas pertaining to the
          Site(s)/Services (“Suggestions”), we will own all right, title and
          interest in and to the Suggestions and will be free to use the
          Suggestions for any purpose without any liability or payment to you.
          You hereby irrevocably assign to us all right, title and interest in
          and to Suggestions and agree to provide us with reasonable assistance,
          at our cost, required or desirable in order to document, perfect and
          maintain our rights in the Suggestions.
        </p>{" "}
        <h3>
          <a id="confidentiality"></a>8.4 Confidentiality
        </h3>{" "}
        <p>
          You agree not to use any of our Confidential Information except in
          connection with your authorized use of the Site(s)/Services. “
          <strong>Confidential Information</strong>” means all non-public
          information disclosed by us to you in connection with the
          Site(s)/Services that you should reasonably understand to be
          confidential, including all non-public aspects of the
          Site(s)/Services.
        </p>{" "}
        <h3>
          <a id="links-to-website"></a>8.5 Links to Website
        </h3>{" "}
        <p>
          You may link to the Site(s) by using a text link and linking to the
          Site(s)’ homepage or any other webpage or content contained on the
          Site(s), provided that the link you create and the pages that are
          activated by the link do not: (i) duplicate our content; (ii) frame or
          create a border around our content or any pages on the Site(s), or use
          other techniques that alter in any way the visual presentation or
          appearance of any of our content; (iii) misrepresent your relationship
          with us or otherwise create a false affiliation, connection or
          association with us; (iv) imply that we approve or endorse you, your
          website or the content contained thereon, or Customer’s goods or
          services; (v) present false or misleading impressions about us or
          otherwise damage any goodwill associated with our name, Site(s),
          scholarships, Services, or trademarks; (vi) use our trademarks in page
          text, metatags or hidden text in order to gain higher rankings from
          search engines; or (vii) use our name, trademarks, or your
          relationship with us, in a promotional manner without our prior
          written permission.
        </p>{" "}
        <h2>
          <a id="governing-law-jurisdiction"></a>9. Governing Law &amp;
          Jurisdiction
        </h2>{" "}
        <p>
          These Terms of Use will be governed by and construed in accordance
          with law of the State of Delaware without giving effect to any
          conflicts of laws provisions that would require the application of the
          laws of any other jurisdiction. The parties hereby expressly reject
          any application to these Terms of Use of: (i) the United Nations
          Convention on Contracts for the International Sale of Goods, and (ii)
          the 1974 Convention on the Limitation Period in the International Sale
          of Goods, as amended. All disputes arising out of these Terms of Use
          not subject to binding arbitration (below) will be subject to the
          exclusive jurisdiction of the state and federal courts located in New
          Castle County, Delaware, and the parties agree and submit to the
          personal and exclusive jurisdiction and venue of these courts, except
          that nothing will prohibit us from instituting an action in any court
          of competent jurisdiction to obtain injunctive relief or protect or
          enforce our intellectual property rights or to otherwise protect our
          interests.
        </p>{" "}
        <h2>
          <a id="binding-arbitration"></a>10. Binding Arbitration
        </h2>{" "}
        <p>
          Any controversy, claim or dispute arising out of or related to these
          Terms of Use, the Site(s), and/or the Services, including, but not
          limited to, alleged violations of state or federal statutory or common
          law rights or duties (a “Dispute”) will be solely and exclusively
          resolved according to the procedures set forth in this paragraph. If
          the parties are unable to resolve any Dispute through informal means,
          either party may initiate binding arbitration of such Dispute by
          sending notice demanding arbitration to the other party. The demand
          for arbitration will be made within a reasonable time after the
          Dispute has arisen, but in no event will it be made more than one year
          from when the aggrieved party knew or should have known of the
          controversy, claim, or facts forming the basis of the Dispute. The
          arbitration will be initiated and conducted according to American
          Arbitration Association rules and procedures for commercial
          arbitration, including, as applicable, provisions for the resolution
          of consumer disputes (the “Arbitration Rules”). The arbitration will
          be conducted in New Castle County, Delaware before a single neutral
          arbitrator appointed in accordance with the Arbitration Rules, or such
          other location as the parties may agree in writing. The decision of
          the arbitrator will be final without option to appeal. Disputes with
          an amount in controversy of $10,000 or less will be determined on the
          basis of written submissions only. To the fullest extent permitted by
          law, the arbitrator will not have the power to award punitive,
          special, consequential, or indirect damages against any party.
          Arbitration costs and fees will be divided in accordance with the
          Arbitration Rules. Each party will be responsible for paying its own
          attorneys’ fees, costs, and expenses, regardless of which party
          prevails, but a party may recover any or all expenses from another
          party if the arbitrator, applying applicable law, so determines. No
          disputes may be arbitrated on a class or representative basis and the
          arbitrator may not consolidate or join the claims of other persons or
          parties who may be similarly situated. BY AGREEING TO THESE TERMS OF
          USE, EACH PARTY IRREVOCABLY WAIVES ANY RIGHT IT MAY HAVE TO JOIN
          CLAIMS OR DISPUTES WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION,
          CLASS ARBITRATION, OR SIMILAR PROCEDURAL DEVICE; AND WAIVES ANY RIGHT
          IT MAY HAVE TO PRESENT ITS CLAIM OR DISPUTE IN A COURT OF LAW
          EXCEPTING THE RIGHT TO BRING A CLAIM OR DISPUTE IN SMALL CLAIMS COURT
          IN ACCORDANCE WITH THE ARBITRATION RULES. Judgment on the award
          rendered by the arbitrator(s), if any, may be entered for enforcement
          purposes in any court having jurisdiction thereof.
        </p>{" "}
        <h2>
          <a id="supplemental-terms-for-Lenny-users"></a>11. Supplemental Terms
          for Lenny.AI Users
        </h2>{" "}
        <p>
          The following supplemental terms apply to your use of Lenny, our
          scholarship application search and completion system.
        </p>{" "}
        <h3>
          <a id="no-double-applications"></a>11.1 No Double Applications
        </h3>{" "}
        <p>
          We will provide you with the opportunity to reject individual
          scholarship providers. You agree to reject any scholarship provider to
          which you or anyone on your behalf has applied in the past. You
          acknowledge that any failure to reject duplicative applications may
          disqualify your other applications, and you waive all claims against
          us relating to such disqualification.
        </p>{" "}
        <h3>
          <a id="additional-services"></a>11.2 Additional Services
        </h3>{" "}
        <p>
          We may from time to time offer additional services such as
          scholarships and/or sweepstakes (“Additional Services”). If you choose
          to accept or purchase Additional Services, you may be required to
          enter into a specific agreement for such Additional Services
          (“Additional Agreement”). Each Additional Agreement to which you agree
          is supplementary to these Terms of Use and incorporated herein by
          reference. Unless otherwise provided, the term “Services” shall
          include the Additional Services.
        </p>{" "}
        <h3>
          <a id="scholarship-sweepstakes"></a>11.3 Scholarship Sweepstakes
        </h3>{" "}
        <p>
          The Official Rules appended hereto as Exhibit A constitute an
          Additional Agreement for participating in our scholarship sweepstakes.
          If you participate in our scholarship sweepstakes, you agree to the
          Official Rules.
        </p>{" "}
        <h3>
          <a id="cancellation"></a>11.4 Cancellation
        </h3>{" "}
        <p>
          You may cancel your Account with the Lenny Service by (i) submitting a
          cancellation request through your Account on the Lenny Site; (ii)
          sending an email to{" "}
          <a>
            <u>SUPPORT@LENNY.AI</u>
          </a>{" "}
          specifying the email address used for the Account and clearly
          indicating that you want to cancel, or (iii) calling customer service
          at 00972 99561694 Monday - Friday, 11a.m. to 6p.m. EST.
        </p>{" "}
        <h3>
          <a id="termination"></a>11.5 Termination
        </h3>{" "}
        <p>
          In addition to any other rights to suspend or terminate an Account in
          the Terms of Use, we may terminate your right to access and use the
          Lenny Service immediately, with or without notice, and without
          liability (i) if we suspect a breach of the Terms of Use or violation
          of applicable law, (ii) if we are threatened with a legal claim,
          including for intellectual property infringement, related to the Lenny
          Service, or (iii) if we determine that the use of the Lenny Service by
          you or our provision of the Lenny Service has become impractical or
          unfeasible for any reason. If you or we cancel or terminate your use
          of the Lenny Service, then: (i) we shall immediately terminate your
          access to the Service, including removing your entry from any
          scholarship sweepstakes in which you are enrolled as of the date of
          termination, (ii) all fees and Taxes that you are obliged to pay us,
          up to the effective date of termination, will be charged at the end of
          the then-current billing period, (iii) there will be no refund of any
          amount already paid by you as of the effective date of termination,
          including any amount in respect of the period following the effective
          date of termination, and (iv) we will maintain your Content for a
          period of time in our discretion in accordance with our Privacy
          Policy.
        </p>{" "}
        <h3>
          <a id="supplemental-terms-for-Lenny-for-business-users"></a>11.6
          Promoted Scholarships
        </h3>{" "}
        <p>
          We may from time to time receive compensation from a scholarship
          provide to visually highlight a scholarship presented to you and/or to
          move a scholarship to the top or near the top of the list of
          scholarships presented to you. Paid promotion of a scholarship does
          not constitute our endorsement or recommendation of that scholarship
          or the scholarship provider.
        </p>{" "}
        <h2>
          <a id="supplemental-terms-for-Lenny-for-business-users"></a>12.
          Supplemental Terms for Lenny.AI for Business Users
        </h2>{" "}
        <p>
          The following supplemental terms apply to your use of Lenny.AI for
          Business, our scholarship application management system for businesses
          and non-profit organizations.
        </p>{" "}
        <h3>
          <a id="personal-information-processing-as-service-provider"></a>12.1
          Personal Information Processing as Service Provider
        </h3>{" "}
        <p>
          We may collect and process certain information that identifies,
          relates to, describes, or is reasonably capable of being associated
          with scholarship applicants (“Applicant PI”) on your behalf. Subject
          to the limited license below in section 12.2, we will not sell
          Applicant PI; share Applicant PI for cross-context behavioral
          advertising purposes; retain, use, or disclose Applicant PI for any
          purpose other than the specific purpose of providing the Lenny.AI for
          Business Service to you or as otherwise permitted by applicable data
          privacy laws and regulations, including retaining, using, or
          disclosing Applicant PI for a commercial purpose other than providing
          the Lenny.AI for Business Service to you; retain, use, or disclose
          Applicant PI outside of the direct business relationship between you
          and us; or combine Applicant PI we process on your behalf with
          personal information we process on behalf of ourselves or third
          parties. The processing we undertake in connection with the Lenny.AI
          for Business Service includes collecting Applicant PI as directed by
          you and processing Applicant PI for the purposes of administering
          scholarships on your behalf. We will provide reasonable assistance to
          you in responding to legally valid data subject requests related to
          Applicant PI we collect and process on your behalf by providing you
          copies of and/or deleting Applicant PI we hold on your behalf.
          Notwithstanding the foregoing, you acknowledge that you are solely
          responsible for (i) informing us of such request and providing all
          information we need in order to assist you in response to such request
          and (ii) responding to data subject requests, and if we receive a data
          subject request relating to your Applicant PI, we may forward such
          request to you for response. We will use reasonable technical,
          organizational, and physical safeguards to protect Applicant PI we
          process on your behalf from unauthorized or unlawful access,
          disclosure, use, or deletion. We will treat Applicant PI we process on
          your behalf as your confidential information under an obligation of
          confidentiality. We will return or delete all Applicant PI we process
          on your behalf as requested by you at the end of your use of the
          Lenny.AI for Business Services unless we need to retain the Applicant
          PI for compliance with applicable laws. Notwithstanding the foregoing,
          you acknowledge that when not acting on your behalf we may also
          collect and/or process the personal information of an applicant on
          behalf of ourselves or another client and that all personal
          information we collect and/or process in that capacity is not
          considered Applicant PI.
        </p>{" "}
        <h3>
          <a id="limited-license-to-use-applicant-pi-for-marketing-purposes"></a>
          12.2 Limited License to Use Applicant PI for Marketing Purposes
        </h3>{" "}
        <p>
          You grant us a limited, perpetual, global, non-revocable license to
          use Applicant PI for our own marketing purposes such as advertising
          other of our products or services to consumers. We agree that if a
          consumer whose Applicant PI we received from you exercises a right
          under applicable laws to prevent the sale of their personal
          information to third parties, and you provide us with notice of that
          request either through a tool we make available to you or through a
          written notice, we will not use such consumer’s Applicant PI for our
          marketing purposes and will only process it in accordance with our
          obligations in providing the Lenny.AI for Business Service to you.
        </p>{" "}
        <h3>
          <a id="compliance-with-privacy-laws"></a>12.3 Compliance with Privacy
          Laws
        </h3>{" "}
        <p>
          {" "}
          You and we will at all times comply with all applicable data privacy
          laws and regulations, including without limitation the California
          Consumer Privacy Act, California Privacy Rights Act, Connecticut’s Act
          Concerning Personal Data Privacy and Online Monitoring, Colorado
          Privacy Act, Utah Consumer Privacy Act, and Virginia Consumer Data
          Protection Act. You represent and warrant that you have obtained all
          necessary consents from and have provided all legally required notices
          to the individuals whose Applicant PI we collect and/or process on
          your behalf. If we determine that we are no longer able to comply with
          applicable data privacy laws and regulations with regard to our
          activities processing Applicant PI on your behalf or in our use of
          Applicant PI that you provide to us pursuant to section 12.2, we will
          provide notice of such determination to you within five (5) business
          days of making that determination. You may take reasonable and
          appropriate steps, at your own cost, to stop and remediate our
          unauthorized or noncompliant use of the Applicant PI.
        </p>{" "}
        <h3>
          <a id="subprocessors"></a>12.4 Subprocessors
        </h3>{" "}
        <p>
          {" "}
          We will not disclose Applicant PI we process on your behalf to any
          third party (including subprocessors) without your prior consent. You
          consent to our disclosure of Applicant PI we process on your behalf to
          the subprocessors identified on our subprocessor webpage. We will
          notify you of any proposed changes to the subprocessors used by
          posting an update on our subprocessor webpage. You should regularly
          check the subprocessor webpage for changes. You will have ten (10)
          business days to object to each change beginning on the date we post
          the change to the subprocessor webpage (the “Objection Period”). If
          you do not object to the changed subprocessors by sending us written
          notice within the Objection Period, you will be deemed to have
          approved and consented to the changed subprocessor. We will not
          disclose Applicant PI we process on your behalf to a new subprocessor
          until the expiration of the Objection Period. If you object to a
          subprocessor, you and we will negotiate in good faith to resolve your
          reasonable concerns related to the subprocessor or arrive at a
          workaround plan. If you and we are unable to arrive at a mutually
          agreeable accommodation, you or we may immediately terminate your
          Account and use of the Lenny.AI for Business Service. We will have no
          liability to you for a termination as a result of inability of you and
          we to reach a mutually agreeable accommodation. In connection with any
          authorized onward transfer of Applicant PI we process on your behalf,
          we will (i) require by contract that the subprocessor not disclose the
          Applicant PI to any other third party and only process such Applicant
          PI for the purpose of providing services to us; and (ii) contractually
          impose upon the subprocessor data protection obligations that are at
          least as restrictive as those under these supplemental terms,
          including, but not limited to, the obligation to provide at least the
          same level of privacy and data security protection for such Applicant
          PI as is required under applicable data privacy laws and regulations.
        </p>{" "}
        <h3>
          <a id="compliance-assessment"></a>12.5 Compliance Assessment
        </h3>{" "}
        <p>
          {" "}
          You may take reasonable and appropriate steps, at your cost, to ensure
          we process Applicant PI on your behalf in compliance with these
          supplemental terms and with applicable data privacy laws and
          regulations, including, no more than once per twelve-month period upon
          at least thirty (30) days advance written notice to us, conducting an
          assessment of our processing practices. You may use a third-party
          independent assessor to conduct the assessment so long as such
          third-party assessor agrees to confidentiality restrictions at least
          as restrictive as those in the Terms of Use. We will reasonably
          cooperate with such an assessment so long as you take reasonable
          efforts to minimize the burden of such assessment on our everyday
          business operations. We will also, upon your reasonable request and at
          your cost, provide you with information in our possession necessary to
          demonstrate compliance with these supplemental terms and applicable
          data privacy laws and regulations in connection with our processing of
          Applicant PI on your behalf.
        </p>{" "}
        <h3>
          <a id="prematurely-canceling-scholarships"></a>12.6 Prematurely
          Canceling Scholarships
        </h3>{" "}
        <p>
          {" "}
          If you provide a scholarship using the Lenny.AI for Business Service,
          you are required to complete the scholarship process and award the
          scholarship in accordance with the terms you present to the
          applicants. If you cancel a scholarship offer prior to the originally
          published end date, you must award the scholarship to an applicant who
          applied prior to the cancellation of the scholarship. Failure to award
          scholarships may result in immediate termination and a prohibition
          against your future use of the Lenny.AI for Business Service.
        </p>{" "}
        <h3>
          <a id="escrow"></a>12.7 Escrow
        </h3>{" "}
        <p>
          {" "}
          We may give you the option to escrow funds for disbursement to
          scholarship winners with the Lenny.ai (“Fund”), a non-profit
          organization. You will not receive interest on the escrowed funds.
          After you determine the winner of your scholarship, and inform us of
          the winner, we will instruct the Fund to award the scholarship on your
          behalf. If (i) you placed funds to award as part of a scholarship
          offer into escrow with the Fund, (ii) you cancel such scholarship
          offer prior to the originally published end date after receiving at
          least one qualified application, and (iii) you do not select a
          winner(s) of the scholarship and inform us of the winner(s) within
          five (5) days after the date you canceled the scholarship offer, you
          authorize the Fund to disburse the escrowed funds to one or more
          applicants selected by the Fund in its sole discretion. If your
          scholarship does not receive at least one qualified application prior
          to its published end date or the date of cancellation (whichever is
          earlier), we will instruct the Fund to return the escrowed funds to
          you within thirty (30) days after the published end date or the date
          of cancellation (whichever is earlier). The Fund may also return the
          escrowed funds to you at any time in its sole discretion and without
          notice, and is under no obligation to escrow funds for you.
        </p>{" "}
        <h3>
          <a id="cancellation"></a>12.8 Cancellation
        </h3>{" "}
        <p>
          {" "}
          You may cancel your Account with the Lenny.AI for Business Service by
          (i) submitting a cancellation request through your Account on the
          Lenny for Business Site; (ii) sending an email to{" "}
          <a>
            <u>SUPPORT@LENNY.AI</u>
          </a>{" "}
          specifying the email address used for the Account and clearly
          indicating that you want to cancel, or (iii) calling customer service.
        </p>{" "}
        <h3>
          <a id="termination"></a>12.9 Termination
        </h3>{" "}
        <p>
          {" "}
          In addition to any other rights to suspend or terminate an Account in
          the Terms of Use, we may terminate your right to access and use the
          Lenny.AI for Business Service immediately, with or without notice, and
          without liability (i) if we suspect a breach of the Terms of Use or
          violation of applicable law, (ii) if we are threatened with a legal
          claim, including for intellectual property infringement, related to
          the Lenny.AI for Business Service, or (iii) if we determine that the
          use of the Lenny.AI for Business Service by you or our provision of
          the Lenny for Business Service has become impractical or unfeasible
          for any reason. If you or we cancel or terminate your use of the Lenny
          for Business Service, then: (i) we shall immediately terminate your
          access to the Lenny for Business Service, and (ii) we will maintain
          your Content for a period of time in our discretion in accordance with
          our Privacy Policy.
        </p>{" "}
        <h2>
          <a id="copyright-notice"></a>14. Copyright Notice
        </h2>{" "}
        <p>
          If you believe that any material on the Sites infringes your copyright
          rights, please contact our designated agent for Digital Millennium
          Copyright Act notices at: Better Online Ltd.Copyright Manager Shalva
          Street 75, herzliya Israel Email address:{" "}
          <a>
            <u>SUPPORT@LENNY.AI</u>
          </a>
        </p>{" "}
        <p>In your notice, please include:</p>{" "}
        <p>• Your physical or electronic signature;</p>{" "}
        <p>
          • Identification of the copyrighted work you claim to have been
          infringed, or, if there are multiple copyrighted works, a list of such
          works;
        </p>{" "}
        <p>
          • Identification of the material that you claim to be infringing, and
          where the material is located on the Sites or Services;
        </p>{" "}
        <p>• Your address, telephone number, and email address;</p>{" "}
        <p>
          • A statement that you have a good faith belief that use of the
          material in the manner complained of is not authorized by you or the
          law; and
        </p>{" "}
        <p>
          • A statement, under penalty of perjury, that the information in your
          notice is accurate.
        </p>{" "}
        <p>
          If the notice is submitted by someone else on your behalf, the notice
          must also contain a statement that, under penalty of perjury, the
          person submitting the notice is authorized to act on your behalf. Upon
          receipt of your notice, we will remove the offending Content as
          required by law. If we receive a counter notice, we will provide it to
          the disputing party and allow the parties to resolve the matter
          between themselves through legal processes. We also have a policy of
          terminating account holders who repeatedly violate the copyright and
          other intellectual property rights of others.
        </p>{" "}
        <h2>
          <a id="miscellaneous"></a>15. Miscellaneous
        </h2>{" "}
        <h3>
          <a id="non-waiver"></a>15.1 Non-Waiver
        </h3>{" "}
        <p>
          A party’s failure or delay in enforcing any provision of these Terms
          of Use will not be deemed a waiver of that party’s rights with respect
          to that provision or any other provision of these Terms of Use. A
          party’s waiver of any of its rights under these Terms of Use is not a
          waiver of any of its other rights with respect to a prior,
          contemporaneous or future occurrence, whether similar in nature or
          not.
        </p>{" "}
        <h3>
          <a id="construction"></a>15.2 Construction
        </h3>
        The headings in these Terms of Use are not part of these Terms of Use
        but are solely for the convenience of the parties. As used herein, the
        words “include” and “including” and variations thereof, will not be
        deemed to be terms of limitation, but rather will be deemed to be
        followed by the words “without limitation.” All references in these
        Terms of Use to “Sections” refer to sections herein.
        <p></p> <p></p>
        <h3>
          <a id="survival"></a>15.3 Survival
        </h3>{" "}
        <p>
          All provisions of these Terms of Use which by their nature would
          survive the termination of these Terms will be deemed to survive.
        </p>{" "}
        <h3>
          <a id="force-majeure"></a>15.4 Force Majeure
        </h3>{" "}
        <p>
          Neither party shall be in default of any obligation under these Terms
          of Use if the failure to perform the obligation is due to any event
          beyond that party’s reasonable control, including electrical or
          internet failure, natural disaster, war, riot, insurrection, epidemic,
          strikes or other organized labor action, terrorist activity, or events
          of a magnitude or type for which precautions are not generally taken
          in the industry.
        </p>{" "}
        <h3>
          <a id="severability"></a>15.5 Severability
        </h3>{" "}
        <p>
          If any provision of these Terms of Use is held to be unenforceable for
          any reason, in whole or in part, such decision shall not affect the
          validity or enforceability of any or all of the remaining portions
          thereof. Any such unenforceable provision or portion thereof shall be
          changed and interpreted so as to best accomplish the objectives of
          such provision or portion thereof within the limits of applicable law.
        </p>{" "}
        <h3>
          <a id="assignment"></a>15.6 Assignment
        </h3>{" "}
        <p>
          Customer may not assign these Terms of Use without our prior written
          consent.
        </p>{" "}
        <h3>
          <a id="notices"></a>15.7 Notices
        </h3>{" "}
        <p>
          Customer may not assign these Terms of Use without our prior written
          consent. We may provide notice of changes to these Terms of Use to you
          either via e-mail or by posting a notice of such changes on our
          website. You may provide notice to us by courier, mail or e-mail,
          provided that receipt of such notice is verified in writing by us. We
          can be reached at{" "}
          <a>
            <u>SUPPORT@LENNY.AI</u>
          </a>
          , or at Better Online Ltd, Shalva Street 75, herzliya Israel.
        </p>{" "}
        <h3>
          <a id="entire-agreement"></a>15.8 Entire Agreement
        </h3>{" "}
        <p>
          These Terms of Use, along with our other policies (including our{" "}
          <a href="http://lenny.ai/privacy" target="_blank">
            <u>Privacy Policy</u>
          </a>
          ) and terms referenced herein, are the complete and exclusive
          agreement between the parties regarding its subject matter hereof and
          supersede and replace any other written or oral agreement,
          understanding or communication regarding such subject matter.
        </p>{" "}
        <p>
          <strong>
            <u>EXHIBIT A</u>
          </strong>
        </p>{" "}
        <p>
          <u>Sweepstakes Official Rules</u>
        </p>{" "}
        <p>
          We are pleased to offer the Lenny.AI Scholarship Sweepstakes
          (“Scholarship Sweepstakes”) in which you can apply for a chance to win
          a scholarship to be used for tuition or books at your qualified high
          school, college or university within the United States (collectively,
          “School”).
        </p>{" "}
        <p>
          <strong>
            NO PURCHASE OR PAYMENT OF ANY KIND IS NECESSARY TO ENTER OR WIN THE
            SCHOLARSHIP SWEEPSTAKES. ANY PURCHASE, PAYMENT OR UPGRADE WILL NOT
            INCREASE YOUR CHANCES OF WINNING. THE SCHOLARSHIP SWEEPSTAKES IS
            VOID WHERE PROHIBITED OR RESTRICTED BY LAW. PLEASE SEE
            “RESTRICTIONS” BELOW.
          </strong>
        </p>{" "}
        <p>
          <strong>
            By applying to enter the Scholarship Sweepstakes, whether
            automatically by us upon your registration to the Website, or else
            by you actively applying to enter the Sweepstakes, you agree to be
            bound by these Official Rules, and you understand that any violation
            of these Official Rules shall result in your disqualification from
            the Scholarship Sweepstakes. All of our decisions regarding the
            Scholarship Sweepstakes, including without limitation the selection
            of the winner, shall be final and binding in all respects. We
            reserve the right to amend or discontinue the Scholarship
            Sweepstakes and these Official Rules at any time in its sole
            discretion.
          </strong>
        </p>{" "}
        <p>
          <strong>
            The provisions set forth in the relevant sweepstakes page “
          </strong>
          <a href="" target="_blank">
            <strong>
              <u>Sweepstakes Page</u>
            </strong>
          </a>
          <strong>
            ” shall be deemed an integral part of these Official Rules. All
            capitalized terms not otherwise defined herein shall have the
            meaning ascribed to them on the Sweepstakes Page.
          </strong>
        </p>{" "}
        <p>
          <u>Eligibility</u>
        </p>{" "}
        <p>
          You may apply to enter the Sweepstakes for a chance to win a
          scholarship for your School only if you meet the Eligibility
          Requirements set forth on the Sweepstakes Page.
        </p>{" "}
        <p>
          We reserve the right to disqualify any applicant from the Scholarship
          Sweepstakes at any time, and in our sole discretion.
        </p>{" "}
        <p>
          Without limiting the foregoing, you may not be an officer, director,
          member or employee of us or any other party associated with the
          development or administration of the Scholarship Sweepstakes, or an
          immediate family member (i.e., parents, children, siblings or spouse)
          of any of the foregoing, or a person living in the household of any of
          these individuals, whether or not related.
        </p>{" "}
        <p>
          <u>How to Enter</u>
        </p>{" "}
        <p>
          The Scholarship Sweepstakes will run for the Sweepstakes Period, as
          set forth on the Sweepstakes Page.
        </p>{" "}
        <p>
          Instructions for registration for the Sweepstakes Scholarship are set
          forth on the Sweepstakes Page.
        </p>{" "}
        <p>
          You may apply to enter the Scholarship Sweepstakes under a single name
          and a single email address. After the end of the Sweepstakes Period no
          further applications will be accepted.
        </p>{" "}
        <p>
          The estimated odds of winning are set forth on the Sweepstakes Page.
          Actual odds of winning depend upon the total number of eligible
          applications received. Each eligible application will have an equal
          chance of winning.
        </p>{" "}
        <p>
          We will disqualify any applicant tampering with the application
          process or the operation of the Website, including without limitation
          through the use of bots or other automated processes to enter.
        </p>{" "}
        <p>All information you provide must be correct and complete.</p>{" "}
        <p>
          The Scholarship Sweepstakes is subject to applicable law and is void
          where prohibited or restricted by law.
        </p>{" "}
        <p>
          <u>Winner Selection</u>
        </p>{" "}
        <p>
          On or around the Draw Date, as set forth on the Sweepstakes Page, we
          will draw a winner of the Prize, as set forth on the Sweepstakes Page,
          who will be selected by a random drawing from amongst all eligible
          entries (“Winner”).
        </p>{" "}
        <p>
          In the event of a dispute regarding the identity of an applicant,
          including without limitation Winner, identity shall be deemed to be
          that of the natural person over 16 years of age owning the email
          address associated with the Winner’s Account, as of the date that
          Lenny receives such applicant’s application for the Scholarship
          Sweepstakes.
        </p>{" "}
        <p>
          <u>Prize</u>
        </p>{" "}
        <p>
          The amount of the Prize is set forth on the Sweepstakes Page. Winner
          must use the Prize solely and exclusively for the full or partial
          payment of Winner’s tuition and/or books at Winner’s School.
        </p>{" "}
        <p>
          We shall either pay the amount of the Prize to Winner, or else
          directly to Winner’s School, and all in our sole discretion.
        </p>{" "}
        <p>
          Upon our request, and prior to Winner being awarded the Prize, Winner
          must provide us with proof of Winner’s enrollment at the School and
          Winner’s tuition bill, and any other information requested by us.
        </p>{" "}
        <p>
          <u>Winner Notification</u>
        </p>{" "}
        <p>
          Winner will be notified by e-mail or by telephone within approximately
          five (5) business days of the Draw Date, to be followed by written
          notification to Winner’s Lenny Email Address.
        </p>{" "}
        <p>
          Winner will be deemed to have forfeited the Prize, and an alternate
          will be selected, in our sole discretion, as the new single Winner by
          a random drawing in Winner’s place, in the event of any of the
          following: (i) Winner cannot be reached after reasonable efforts
          within three (3) business days after the first attempt to contact
          Winner, (ii) Winner chooses not to accept the Prize, or (ii) Winner
          does not comply with these Official Rules (including on the
          Sweepstakes Page).
        </p>{" "}
        <p>
          <u>Affidavit</u>
        </p>{" "}
        <p>
          If requested by us, as a condition to winning the Prize, Winner shall
          execute an “
          <a href="http://lenny.ai/affidavit.pdf" target="_blank">
            <u>Affidavit of Eligibility and Liability/Publicity Release</u>
          </a>
          ” (“Affidavit”). Agreement to the publicity release portion of the
          Affidavit is not required for Tennessee residents or any other state
          where prohibited by law. If Winner is a minor, Winner’s parent or
          legal guardian shall also execute the Affidavit.
        </p>{" "}
        <p>
          <u>Verification of Information</u>
        </p>{" "}
        <p>
          Although information that you provide may be verified, we are not
          under any obligation to verify any of the information that you
          provide, and you hereby warrant that all information that you provide
          in connection with the Scholarship Sweepstakes is complete and
          accurate.
        </p>{" "}
        <p>
          <u>Ownership Of Applications</u>
        </p>{" "}
        <p>
          All applications shall become our property and will not be returned.
        </p>{" "}
        <p>
          <u>Taxes</u>
        </p>{" "}
        <p>
          Without limiting the Terms of Use, all taxes, costs and expenses
          associated with the receipt and/or use of the Prize shall be the sole
          and exclusive responsibility of the Winner and Winner shall report the
          value of any Prize for tax purposes as required by law. Without
          limiting the foregoing, Winner shall provide us with a completed Form
          W-9 with valid identification and a valid taxpayer identification
          number or social security number before the Prize will be awarded. If
          Winner wins over $600.00 in Prizes which are paid directly to Winner
          rather than being sent directly to the School or paid into a 529 plan,
          then Winner will file a Form 1099 at the end of the relevant calendar
          year with the IRS.
        </p>{" "}
        <p>
          <u>Disclaimer of Warranties, Limitation of Liability, and Privacy</u>
        </p>{" "}
        <p>
          WITHOUT LIMITING ANY OTHER PROVISION IN THESE TERMS OF USE, ANY
          ADDITIONAL AGREEMENT OR OUR PRIVACY POLICY, ALL CAUSES OF ACTION
          ARISING OUT OF OR CONNECTED WITH THE SCHOLARSHIP SWEEPSTAKES,
          INCLUDING WITHOUT LIMITATION IN CONNECTION WITH ANY APPLICATION OR
          PRIZE, AND, TO THE FULLEST EXTENT PERMITTED BY LAW, IN ANY CAUSE OF
          ACTION THE SWEEPSTAKES ENTITIES’ MAXIMUM AGGREGATE LIABILITY SHALL BE
          LIMITED TO APPLICANT’S DIRECT COSTS OF ENTERING AND PARTICIPATING IN
          THE SCHOLARSHIP SWEEPSTAKES.
        </p>{" "}
        <p>
          <u>
            Restrictions: Non-Transferability, Right to Cancel, Suspend, or
            Modify
          </u>
        </p>{" "}
        <p>
          Prizes are non-transferable and must be accepted as awarded. We
          reserve the right to cancel, suspend, or modify the Scholarship
          Sweepstakes and award the Prize from among all eligible entrants
          received prior to such cancelation, suspension or modification, or to
          elect not to award the Prize at all, for any legal, commercial,
          technical or other event, in our sole discretion.
        </p>{" "}
        <p>
          <u>Winner List</u>
        </p>{" "}
        <p>
          Names of Winners will be posted on Lenny’s Winners Page, and may be
          posted on the websites of Scholarship Partners or elsewhere, in our
          sole discretion. You may request a Winners’ list by sending a stamped,
          self-addressed envelope no later than ninety (90) days after the
          relevant Draw Date to: Better Online Ltd.,Shalva Street 75, herzliya
          Israel
        </p>{" "}
        <p>
          <u>Sponsor, No Affiliation Or Endorsement</u>
        </p>{" "}
        <p>
          This promotion is sponsored solely by Lenny.ai We can be reached at{" "}
          <a href="mailto:SUPPORT@LENNY.AI">
            <u>SUPPORT@LENNY.AI</u>
          </a>
          , or at Better Online Ltd., Shalva Street 75, herzliya Israel.
        </p>{" "}
        <p>
          We are not affiliated with, sponsored by, or endorsed by any School.
          Signing up, registering, or enrolling in a School does not increase
          your chances of winning.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default TermsConditions;
