import { notification } from "antd";
import axios from "axios";
import { configure } from "axios-hooks";
import { API_BASE_URL } from "configs/AppConfig";
import { RemoveLocalStorage } from "utils/common";
import {
  AUTH_TOKEN_KEY,
  ENTRY_ROUTE,
  PUBLIC_REQUEST_KEY,
  TOKEN_PAYLOAD_KEY,
} from "../constants/common.constant";
import history from "../history";
import { GetLocalStorage } from "../utils/common";

const fetch_ = axios.create({
  baseURL: `${API_BASE_URL}api/v1`,
  timeout: 120000,
});

configure({ axios: fetch_ });

// API Request interceptor
fetch_.interceptors.request.use(
  async (config) => {
    const jwtToken = GetLocalStorage(AUTH_TOKEN_KEY);
    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = `Bearer ${jwtToken}`;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      if (
        ![
          "/",
          "/auth/register1",
          "/signup",
          "/auth/userdetails",
          "/contact",
          "/pricing",
          "/about-us",
          "/chatbot",
        ].includes(history.location.pathname)
      ) {
        history.push(ENTRY_ROUTE);
        if (config.url !== "/register-applicants/form-fields") {
          window.location.reload();
        }
      }
    } else if (
      jwtToken &&
      ["/auth/register1", "/signup"].includes(history.location.pathname)
    ) {
      history.push("/auth/dashboard");
      window.location.reload();
    }
    return config;
  },
  (error) => {
    // Do something with request error here
    // notification.error({
    //   message: "Error",
    // });
    Promise.reject(error);
  }
);

// API respone interceptor
fetch_.interceptors.response.use(
  (response) => {
    const data = response.data;
    if (data.message) {
      notification.success({ description: data.message });
    }
    return data;
  },
  (error) => {
    const { data = {}, status, statusText } = error?.response || {};
    const notificationData = {
      description: data?.error?.message || statusText,
      message: data?.error?.error || statusText,
      statusCode: data.statusCode || status,
    };

    if (
      [401, 403].includes(notificationData.statusCode) &&
      ![API_BASE_URL.LOGIN, API_BASE_URL.SEND_OTP].includes(
        error.response?.config?.url
      )
    ) {
      RemoveLocalStorage(AUTH_TOKEN_KEY);
      if (
        !["/", "/auth/register1", "/signup", "/auth/userdetails"].includes(
          history.location.pathname
        )
      ) {
        history.push(ENTRY_ROUTE);
        window.location.reload();
      }
    }

    // notification.error(notificationData);

    return Promise.reject(notificationData);
  }
);

export default fetch_;
