import React, { useState, useRef, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { DeleteOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Form, message } from "antd";
import BouncingDotsLoader from "components/layout-components/BouncingDotsLoader";
import ChatStructure from "components/layout-components/ChatStructure";
import fetch_ from "interceptors/FetchInterceptor";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import DOMPurify from "dompurify";

const RequirementAss = ({
  dashbordHide = false,
  essayReq,
  isMobileView = false,
  setIsModalVisible,
}) => {
  // console.log("🚀 ~ isMobileView:", isMobileView);
  const {
    wordLengthData,
    setCurrentRequirementIndex,
    askNextQus,
    reqAssMaxView,
    setReqMaxView,
    mobReqAssMaxView,
    setMobReqMaxView,
    chatBtnType,
    reqAssQusArr,
    setReqQusArr,
    assMessages,
    setAssMessages,
    qusCounter,
    setQusCounter,
    localData,
    setLocalData,
    forceStopRender,
    setForceStopREnder,
    chatBtn,
    setChatBtn,
    filledData,
    setFilledData,
    setShowChat,
    chatThreeDotLoading,
    setSaveAndNext,
  } = useAssitanceChatBotContext();
  // console.log("🚀 ~ RequirementAss ~ asMessages:", assMessages, setAssMessages);

  const [mobileView, setMobileView] = useState(window.innerWidth < 915);
  const history = useHistory();
  const inputRef = useRef(null);
  const messagesEndRef = useRef(null);

  // const [assMessages, setAssMessages] = useState([]);
  const [enableChat, setEnableChat] = useState(true);
  // const [qusCounter, setQusCounter] = useState(0);
  // const [reqAssQusArr, setReqQusArr] = useState(
  //   essayReq.length > 0
  //     ? essayReq.map((item) => {
  //       return {
  //         name: item.field.name,
  //         field: item.field,
  //       };
  //     })
  //     : []
  // );

  const boxStyle = {
    border: "2px solid rgb(141, 150, 255)",
    borderRadius: "10px",
    width: "100%",
    alignItems: "center",
    flexShrink: "0",
    flexBasis: "25%",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    padding: "5px",
    textAlign: "center",
    background: "transparent",
  };

  const writeEssay = async (params) => {
    try {
      let updateData = {
        prompt: (() => {
          const titlePrompt = false
            ? `about the word '${reqAssQusArr[qusCounter]?.field.name}'`
            : "";
          const descriptionPrompt = reqAssQusArr[qusCounter]?.field.description
            ? `The document should include: - A title enclosed in a heading tag (e.g., <h1>) - A description enclosed in a paragraph tag (e.g., <p>). Do not include a <!DOCTYPE> declaration or <head> tag; only include the <body> tag and its contents.`
            : "";
          let basePrompt =
            reqAssQusArr[qusCounter]?.field.dataType === "text" ||
            reqAssQusArr[qusCounter]?.field.dataType === "editor"
              ? `Write an HTML document containing an essay ${titlePrompt}. ${descriptionPrompt}`
              : `Write an answer ${titlePrompt}. ${descriptionPrompt}`;

          let baseCondition = `The document should include: - A title enclosed in a heading tag (e.g., <h3>) - A description enclosed in a paragraph tag (e.g., <p>). Do not include a <!DOCTYPE> declaration or <head> tag; only include the <body> tag and its contents.`;

          switch (params) {
            case "make shorter":
              return `${basePrompt} and make it shorter. (within ${
                wordLengthData || 200
              } words). ${baseCondition}`;
            case "make longer":
              return `${basePrompt} and make it longer. (at least ${
                wordLengthData || 200
              } words)${baseCondition}`;
            case "rewrite the essay":
              return `${basePrompt} and rewrite it. ${baseCondition}`;
            default:
              return ` ${basePrompt} .${baseCondition}`;
          }
        })(),
      };

      const response = await fetch_({
        url: `/register-applicants/get-essay`,
        method: "POST",
        data: updateData,
      });

      if (response?.data?.usage_limit_message) {
        message.error(
          `${
            response?.data?.usage_limit_message ||
            "You have reached the limit of essay submission"
          }`
        );
        return "Some error occurred!!!";
      }

      const essayContent = response?.data?.content;
      if (essayContent) {
        const text = [
          {
            chat_type: "essayChatType",
            data: essayContent,
          },
        ];
        return text;
      }

      return response?.data?.content;
    } catch (error) {
      console.log(error);
    }
  };

  const Message = ({ sender, text, extra }) => (
    <div
      className={`message ${
        sender === "receiver" ? "message-left" : "message-right"
      }`}
      // ref={messagesEndRef}
      style={{
        width: "100%",
        justifyContent: sender === "receiver" ? "normal" : "flex-end",
      }}
      ref={messagesEndRef}
    >
      {sender === "receiver" && (
        <span
          style={{
            display: "inline-block",
            width: "30px",
            height: "37px",
            marginRight: "5px",
            backgroundImage: `url('/new/chatrob.png')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></span>
      )}
      {false ? (
        <BouncingDotsLoader />
      ) : (
        <>{chatStructure(text, sender, extra)}</>
      )}
      {sender === "user" && (
        <span
          style={{
            display: "inline-block",
            width: "25px",
            height: "37px",
            marginLeft: "5px",
            backgroundImage: `url('/new/peroson.png')`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
          }}
        ></span>
      )}
    </div>
  );
  // const chatThreeDotLoading = async (
  //   skipCall,
  //   apiCall,
  //   msg,
  //   chatBtnState = true
  // ) => {
  //   // if (skipCall) {
  //   // Display the loading message
  //   const loadingMessage = {
  //     sender: "receiver",
  //     text: "loading",
  //   };
  //   setAssMessages((prevMessages) => [...prevMessages, loadingMessage]);
  //   // }
  //   try {
  //     // Wait for the API call to complete
  //     let responseText;
  //     if (skipCall) {
  //       responseText = msg;
  //     } else {
  //       responseText = await apiCall();
  //     }

  //     const updateMessages = () => {
  //       setAssMessages((prevMessages) => {
  //         // Remove the loading message if it exists
  //         const newMessages = prevMessages.filter((e) => e.text !== "loading");
  //         // Add the new message with the API response
  //         return [...newMessages, { sender: "receiver", text: responseText }];
  //       });
  //     };

  //     if (skipCall) {
  //       setTimeout(updateMessages, 500);
  //     } else {
  //       updateMessages();
  //     }

  //     setChatBtn(chatBtnState);
  //   } catch (error) {
  //     // Handle any errors
  //     console.error("API call failed:", error);

  //     setAssMessages((prevMessages) => {
  //       // Remove the loading message if it exists
  //       const newMessages = prevMessages.filter((e) => e.text !== "loading");
  //       // Add an error message
  //       return [
  //         ...newMessages,
  //         { sender: "receiver", text: "Error loading response" },
  //       ];
  //     });

  //     setChatBtn(true);
  //   }
  // };

  const initiateChat = (newQusARr) => {
    chatThreeDotLoading(true, null, [
      {
        chat_type: "select_yes_no",
        data: `Oh! I see, you need to write ${newQusARr[qusCounter]?.name} to apply on this scholarship. Do you need any help?`,
      },
    ]);
    // setAssMessages(
    //   [{
    //     sender: "receiver",
    //     text: [
    //       {
    //         chat_type: "select_yes_no",
    //         data: `Oh! I see, you need to write ${reqAssQusArr[qusCounter].name} to apply on this scholarship. Do you need any help?`,
    //       },
    //     ],
    //   },]
    // )
  };

  // const askNextQus = () => {
  //   if (qusCounter < reqAssQusArr.length - 1) {
  //     setQusCounter((prevCounter) => {
  //       const counter = prevCounter + 1;

  //       chatThreeDotLoading(true, null, [
  //         {
  //           chat_type: "select_yes_no",
  //           data: `Oh! I see, you need to write ${reqAssQusArr[counter].name} to apply on this scholarship. Do you need any help?`,
  //         },
  //       ]);
  //       return counter;
  //     });
  //     setCurrentRequirementIndex((prevCounter) => prevCounter + 1)

  //   } else {
  //     setQusCounter(0);
  //     setEnableChat(false);

  //     setTimeout(() => {
  //       setShowChat(false);
  //     }, 1000);
  //     message.success(
  //       "Details have been successfully captured. Please add any additional details if required!!!"
  //     );
  //   }
  // };

  const moveToStart = (array, keyToFind) => {
    const index = array.findIndex((element) => element.key === keyToFind);
    if (index > -1) {
      const [element] = array.splice(index, 1);
      array.unshift(element);
    }
    return array;
  };

  useEffect(() => {
    if (forceStopRender) {
      const newQusArr =
        essayReq.length > 0
          ? essayReq.map((item) => ({
              name: item.field.name,
              field: item.field,
              key: item.field.key,
            }))
          : [];

      if (chatBtnType) {
        let newArray = moveToStart([...newQusArr], chatBtnType);
        const reqData = filledData;

        if (reqData) {
          newArray = newArray.filter(
            (question) => !reqData.hasOwnProperty(question.key)
          );
        }

        setFilledData(newArray);
        setReqQusArr(() => {
          initiateChat(newArray);
          return newArray;
        });
      } else {
        setReqQusArr(() => {
          initiateChat(newQusArr);
          return newQusArr;
        });
      }

      // console.log("🚀 ~ useEffect ~ newQusArr", newQusArr);

      setForceStopREnder(false);
    }
  }, []);
  // useEffect(() => {

  // }, [assMessages])

  const submitEssay = async () => {
    // const key = reqAssQusArr[qusCounter]?.field?.key;
    const key = chatBtnType || reqAssQusArr[qusCounter]?.field?.key;

    // console.log(chatBtnType, key, reqAssQusArr[qusCounter]?.field?.key);

    if (key) {
      let reqData = filledData || {};

      // Update the specific field key with the new value
      reqData = {
        ...reqData, // Preserve existing keys
        [key]: assMessages[assMessages.length - 1].text[0].data, // Add or update the specific key with the new value
      };

      const localStorData = JSON.parse(localStorage.getItem("reqData"));
      if (localStorData) {
        localStorData[key] = assMessages[assMessages.length - 1].text[0].data;
        localStorage.setItem("reqData", JSON.stringify(localStorData));
      } else {
        localStorage.setItem("reqData", JSON.stringify(reqData));
      }

      // Store the updated object as a string in localStorage
      // localStorage.setItem("reqData", JSON.stringify(reqData));
      setFilledData(reqData);

      // Update local data state and ask the next question
      setLocalData(() => {
        return !localData;
      });
      message.success(
        `${reqAssQusArr[qusCounter]?.field?.name} Submitted Successfully!!!`
      );
      setShowChat(false);
      setAssMessages([]);
      // setSaveAndNext(() => false);
      // askNextQus();
    } else {
      console.error("Field key is undefined");
      message.error("Some error occurred!!!");
    }
  };

  function processHtmlString(htmlString) {
    // Remove the ```html from the start and end
    const cleanHtmlString = htmlString
      .replace(/^```html\n/, "")
      .replace(/```$/, "");

    // Create a temporary DOM element to parse the HTML
    const parser = new DOMParser();
    const doc = parser.parseFromString(cleanHtmlString, "text/html");

    // Apply the style to all <p> elements
    const paragraphs = doc.querySelectorAll("p");
    paragraphs.forEach((p) => {
      p.style.color = "black";
    });

    // Convert the modified DOM back to an HTML string
    return doc.body.innerHTML;
  }
  const chatStructure = (text, sender, extra) => {
    // { console.log(text) }
    if (text == "loading") {
      return <BouncingDotsLoader />;
    } else if (text?.[0]?.chat_type === "essayChatType") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              fontSize: true ? "12px" : "16px",
              fontWeight: "500",
              maxWidth: "80%",
            }}
          >
            {/* {text[0]?.data} */}
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(processHtmlString(text[0]?.data)),
              }}
              // style={{
              //   backgroundColor:
              //     sender === "receiver"
              //       ? text === "loading"
              //         ? "transparent"
              //         : "white"
              //       : "#D3D6FF",
              //   padding: "10px",
              //   fontSize: mobileView ? "12px" : "12px",
              //   fontWeight: "500",
              //   maxWidth: sender === "receiver" ? "90%" : "90%",
              //   whiteSpace: "normal",
              //   overflowWrap: "break-word",
              //   wordWrap: "break-word",
              //   borderRadius: "8px",
              // }}
            />
          </div>
          <button
            style={{
              width: "80%",
              background: "#8D96FF",
              border: "none",
              padding: "8px 20px",
              fontSize: "13px",
              fontWeight: "600",
              cursor: "pointer",
              color: "white",
              marginTop: "16px",
            }}
            onClick={submitEssay}
          >
            Submit Essay
          </button>
          <div
            style={{
              display: "flex",
            }}
          >
            <button
              style={{
                marginRight: "8px",
                background: "transparent",
                border: "2px solid #8D96FF",
                marginTop: "8px",
                padding: "8px 20px",
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => {
                // writeEssay('rewrite')
                chatThreeDotLoading(
                  false,
                  () => writeEssay("rewrite the essay"),
                  null
                );
              }}
            >
              Rewrite Essay
            </button>
            <button
              style={{
                marginRight: "8px",
                background: "transparent",
                border: "2px solid #8D96FF",
                marginTop: "8px",
                padding: "8px 20px",
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => {
                // writeEssay('make shorter')
                chatThreeDotLoading(
                  false,
                  () => writeEssay("make shorter"),
                  null
                );
              }}
            >
              Make Shorted
            </button>
            <button
              style={{
                marginRight: "8px",
                background: "transparent",
                border: "2px solid #8D96FF",
                marginTop: "8px",
                padding: "8px 20px",
                cursor: "pointer",
                color: "black",
              }}
              onClick={() => {
                // writeEssay('make longer')/
                chatThreeDotLoading(
                  false,
                  () => writeEssay("make longer"),
                  null
                );
              }}
            >
              Make Longer
            </button>
          </div>
        </div>
      );
    } else if (text?.[0]?.chat_type === "select_yes_no") {
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            maxWidth: "80%",
          }}
        >
          <div
            style={{
              backgroundColor: "white",
              borderRadius: "10px",
              padding: "10px",
              fontSize: true ? "12px" : "16px",
              fontWeight: "500",
            }}
          >
            {text[0]?.data}
          </div>
          <div>
            <div
              style={{
                display: true ? "flex" : "none",
                justifyContent: "space-evenly",
                marginTop: "20px",
              }}
            >
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={async () => {
                  // noLogin();
                  setShowChat(false);
                }}
              >
                No
              </button>
              <button
                style={{
                  width: "auto",
                  background: "transparent",
                  border: "2px solid #8d96ff",
                  padding: "8px 20px",
                  fontSize: "13px",
                  fontWeight: "600",
                  cursor: "pointer",
                  marginRight: "20px",
                }}
                onClick={() => {
                  // setVisible(false);
                  // yesLogin();
                  setAssMessages(() => {
                    return [
                      ...assMessages,
                      {
                        sender: "user",
                        text: "Yes",
                      },
                    ];
                  });

                  // askNextQus();
                  chatThreeDotLoading(
                    true,
                    null,
                    `Please provide the keywords for the ${reqAssQusArr[qusCounter].name}.`,
                    false
                  );
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div
          style={{
            backgroundColor:
              sender === "receiver"
                ? text === "loading"
                  ? "transparent"
                  : "white"
                : "#D3D6FF",
            padding: "10px",
            fontSize: true ? "12px" : "16px",
            fontWeight: "500",
            maxWidth: sender === "receiver" ? "100%" : "100%",
            whiteSpace: "normal",
            overflowWrap: "break-word",
            wordWrap: "break-word",
          }}
        >
          {text}
        </div>
      );
    }
  };

  const ChatComponent = () => {
    const [inputValue, setInputValue] = useState("");
    const onFormSubmitted = (values) => {
      // console.log("🚀 ~ onFormSubmitted ~ values:", values, inputValue)
      if (inputValue) {
        setAssMessages((prevMessages) => [
          ...prevMessages,
          {
            sender: "user",
            text: inputValue,
          },
        ]);

        chatThreeDotLoading(false, () => writeEssay(inputValue), null);
        // writeEssay()

        setChatBtn(true);
        setInputValue("");
      }
    };
    const handleInputChange = (e) => {
      setInputValue(e.target.value);
    };
    useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [assMessages]);
    return (
      <>
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {enableChat && (
            <Scrollbars
              style={{
                width: "100%",
                // height: maximizeView
                //   ? "540px"
                //   : dashbordHide
                //     ? "350px"
                //     : mobileView
                //       ? "600px"
                //       : "550px",
                marginBottom: "10px",
                display: "flex",
                height: mobReqAssMaxView
                  ? "530px"
                  : reqAssMaxView
                  ? "505px"
                  : "260px",
              }}
            >
              {assMessages.map((message, index) => {
                return (
                  <Message
                    key={index}
                    sender={message.sender}
                    text={message.text}
                    extra={message.extra}
                  />
                );
              })}
            </Scrollbars>
          )}

          <Form onFinish={onFormSubmitted}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "10px",
                width: "100%",
                alignItems: "center",
                marginBottom: "5px ",
              }}
            >
              <input
                ref={inputRef}
                type="text"
                style={{
                  flex: "1",
                  margin: mobileView ? "10px" : "0 10px",
                  padding: "8px",
                  fontSize: "14px",
                  flexBasis: "90%",
                }}
                className="chatInp"
                placeholder={"Type here..."}
                value={inputValue}
                onChange={handleInputChange}
                required
              />

              <button
                style={{
                  flex: "1 1 4%",
                  cursor: "pointer",
                  border: "none",
                  padding: "0",
                  margin: "0",
                }}
                disabled={chatBtn}
                type="submit"
              >
                <img
                  src="/new/forward.png"
                  alt="Button Image"
                  style={{
                    width: "35px",
                    opacity: chatBtn ? "0.5" : "1",
                    cursor: chatBtn ? "not-allowed" : "pointer",
                  }}
                />
              </button>
            </div>
          </Form>
        </div>
      </>
    );
  };

  const MobileReqAss = ({ modalContent }) => {
    const showModal = () => {
      // setIsModalVisible(true);
    };

    const handleClose = () => {
      // setIsModalVisible(false);
      // setShowChat(false)
    };
    return (
      <>
        <div
          style={{
            position: mobReqAssMaxView ? "static" : "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            zIndex: 1000,
          }}
          onClick={handleClose}
        ></div>
        <div
          style={{
            position: "fixed",
            bottom: "0px",
            margin: "0px",
            width: "98%",
            border: "2px solid rgb(141, 150, 255)",
            backgroundColor: "rgb(240, 241, 255)",
            padding: "10px",
            height: mobReqAssMaxView ? "calc(100vh - 80px)" : "52%",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
            zIndex: 1001,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <p
                style={{
                  fontWeight: "500",
                  fontSize: "18px",
                  color: "black",
                  margin: "0",
                }}
              >
                Lenny's Assistant
              </p>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{
                  fontSize: "21px",
                  fontWeight: "bold",
                  marginRight: "8px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setShowChat(false);
                }}
              >
                <CloseCircleOutlined />
              </div>
              <div>
                <img
                  src="/new/arr.png"
                  style={{
                    width: "20px",
                    marginLeft: "20px",
                    cursor: "pointer",
                    display: history.location.pathname.includes(
                      "assistanceChat"
                    )
                      ? "none"
                      : "inline",
                  }}
                  alt="icon_image"
                  title="Maximize"
                  onClick={() => {
                    // toggleMaximize();
                    setMobReqMaxView(() => {
                      return !mobReqAssMaxView;
                    });
                  }}
                />
              </div>
            </div>
          </div>
          {modalContent}
        </div>
      </>
    );
  };

  return (
    <>
      {isMobileView ? (
        <MobileReqAss
          setIsModalVisible={setIsModalVisible}
          modalContent={<ChatComponent />}
        />
      ) : (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgb(240, 241, 255)",
              backgroundColor: "rgb(240, 241, 255)",
              borderRadius: "15px",
              border: "2px solid rgb(141, 150, 255)",
              width: "-webkit-fill-available",
              alignItems: "stretch",
              height: reqAssMaxView ? " calc(100vh - 110px)" : "auto",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                padding: "10px 20px",
                borderBottom: "2px solid rgb(141, 150, 255)",
              }}
            >
              <h3
                style={{
                  margin: "0",
                  fontFamily: "Poppins",
                }}
              >
                Lenny's AI
              </h3>

              <div>
                <DeleteOutlined
                  style={{
                    cursor: "pointer",
                    fontSize: "20px",
                    // display: history.location.pathname.includes("assistanceChat")
                    //   ? "inline"
                    //   : "none",
                  }}
                  onClick={() => {
                    setShowChat(false);
                    // setReqQusArr([])
                    setAssMessages([]);
                    setForceStopREnder(true);
                  }}
                />
                <img
                  src="/new/arr.png"
                  style={{
                    width: "20px",
                    marginLeft: "20px",
                    cursor: "pointer",
                    display: history.location.pathname.includes(
                      "assistanceChat"
                    )
                      ? "none"
                      : "inline",
                  }}
                  alt="icon_image"
                  title="Maximize"
                  onClick={() => {
                    // toggleMaximize();
                    setReqMaxView(() => {
                      return !reqAssMaxView;
                    });
                  }}
                />
              </div>
            </div>
            {<ChatComponent />}
          </div>
        </>
      )}
    </>
  );
};

export default RequirementAss;
