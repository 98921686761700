import React from "react";

const index = () => {
  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  return (
    <div
      style={{
        margin: "30px",
      }}
    >
      <h1
        style={{
          lineHeight: "'normal'",
          color: "#000",
          fontFamily: "Poppins",
          fontSize: "24px",
        }}
      >
        Subscription Support
      </h1>
      <p
        style={{
          color: "#000",
          fontFamily: "Poppins",
          fontSize: "16px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: "normal",
        }}
      >
        Hi {userInfo.firstName}, if you are facing some issue in subscription
        then try to logout and login again. If the issue still persist please
        email us on
        <a href="mailto:support@lenny.ai" style={{ margin: "5px" }}>
          support@lenny.ai
        </a>
        with the issue. We will assist you.
      </p>
    </div>
  );
};

export default index;
