import {
  Alert,
  Button,
  Col,
  Pagination,
  Row,
  Spin,
  Modal,
  Upload,
  Checkbox,
} from "antd";
import { API_UPLOAD_URL } from "configs/AppConfig";
import useAxios from "axios-hooks";
import { motion } from "framer-motion";
import queryString from "query-string";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import fetch_ from "../../../interceptors/FetchInterceptor";
import useAnalyticsEventTracker from "../../../useAnalyticsEventTracker";
import { FilterBox } from "./components/FilterBox.js";
import ThankYouModal from "./ScholarshipDetail/ThankYouModal";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import Subscription from "components/layout-components/Subscription";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign";
import mixpanel from "mixpanel-browser";
import Scrollbars from "react-custom-scrollbars-2";

const FilterApply = ({ type, props }) => {
  const pageSize = 6;
  const [alertMessage, setAlertMessage] = useState();
  const [alertType, setAlertType] = useState(true);
  const [alertShow, setAlertShow] = useState();
  /*Get api for scholarship list based on url search params*/
  const { search } = useLocation();
  const [modalVisible, setModalVisible] = useState(false);
  const [easyApplyClicked, setEasyApplyClicked] = useState("");
  const urlValues = queryString.parse(search);
  const [thankYouModalOpen, setThankYouModalOpen] = useState(false);
  const offset = urlValues?.page ? (urlValues?.page - 1) * pageSize : 0;
  const limit = urlValues?.page ? urlValues?.page * pageSize : pageSize;
  const history = useHistory();
  const pathName = history.location.pathname;

  const mobileView = window.innerWidth < 1200;
  const tabView = window.innerWidth < 961;
  const [isAllSelected, setIsAllSelected] = useState(true);
  const [hideCheckbox, setHideCheckbox] = useState(false);

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const storedValue = localStorage.getItem("isActiveSubscription");

  // Now, storedValue is a string representation of the boolean
  // You can directly use it as a boolean without parsing
  const isActiveSubscription = storedValue === "true";

  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    if (isActiveSubscription) {
      history.push("/auth/dashboard/plan");
    } else {
      // setIsOpen(!isOpen);
      if (tabView) {
        setIsOpen(!isOpen);
      } else {
        history.push("/auth/dashboard/subscription-plan");
      }
    }
  };

  const onPageChange = (el) => {
    const appUrl = `?page\=${el}`;

    history.push({
      pathname: history.location.pathname,
      search: appUrl,
    });
  };

  let url = "";
  switch (type) {
    case "easyApplyWithCv":
      url = `scholarships/mine-eligible-scholarships?offset\=${offset}\&limit\=${limit}\&order\=DESC\&orderBy\=createdAt\&withoutRequirement\=${false}\&requirementFields\=9991e67e-418d-448a-ae41-143e4a6c33a7`;
      break;
    case "saved":
      url = "scholarships/get-wishlist";
      break;
    case "recommendation":
      url = `scholarships/mine-eligible-scholarships?offset\=${offset}\&limit\=${limit}\&order\=DESC\&orderBy\=createdAt\&withoutRequirement\=${false}`;
      break;
    case "myscholarship/applied":
      url = "scholarships/me/scholarships";
      break;
    case "myscholarship/draft":
      url = "scholarships/me/scholarships";
      break;
    default:
      url = `scholarships/mine-eligible-scholarships?offset\=${offset}\&limit\=${limit}\&order\=DESC\&orderBy\=createdAt\&withoutRequirement\=${true}`;
  }

  const [{ data = [], loading }] = useAxios(
    { url: url },
    { useCache: false, manual: false }
  );

  /*Getting Scholarship count for pagination*/
  let countUrl =
    type === "saved"
      ? "scholarships/get-wishlist"
      : type === "recommendation"
      ? `scholarships/mine-eligible-scholarships/count?withoutRequirement\=${false}`
      : type === "easyApplyWithCv"
      ? `scholarships/mine-eligible-scholarships/count?withoutRequirement\=${false}\&requirementFields\=9991e67e-418d-448a-ae41-143e4a6c33a7`
      : `scholarships/mine-eligible-scholarships/count?withoutRequirement\=${true}`;

  const [{ data: count }] = useAxios(
    { url: countUrl },
    { useCache: false, manual: false }
  );

  const [tempData, setTempData] = useState([]);

  useEffect(() => {
    props?.setSideBars(false);
    if (!loading) {
      setTempData(
        data.scholarshiplists
          ? data.scholarshiplists.map((e) => {
              return { ...e, isSelected: true };
            })
          : data.map((e) => {
              return { ...e, isSelected: true };
            })
      );
    }
  }, [data, loading]);

  let title = "";
  switch (type) {
    case "recommendation":
      title = "Your Recommendation ";
      break;
    case "saved":
      title = "Wishlist / Saved ";
      break;
    case "easyApplyWithCv":
      title = "Apply With CV Data Only ";
      break;
    case "myscholarship/applied":
      title = "";
      break;
    case "myscholarship/draft":
      title = "";
      break;
    default:
      title = "Easy Apply Scholarships ";
  }

  /* const title = type
    ? type === "recommendation"
      ? "Your Recommendation "
      : type === "saved"
      ? "Wishlist / Saved "
      : "Apply With CV Data Only"
    : "Easy Apply Scholarships ";
  */

  const gaEventTracker = useAnalyticsEventTracker(title);

  const onSavedLike = (id) => {
    if (type === "saved") {
      setTempData(
        tempData.map((e) => {
          return id !== e.scholarshipId ? { ...e } : { ...e, isLiked: false };
        })
      );
    } else if (type === "recommendation") {
      setTempData(
        tempData.map((e) => {
          return id !== e.id ? { ...e } : { ...e, isLiked: false };
        })
      );
    }
  };

  const onCheckboxChange = (id, value) => {
    let tmp = 0;
    setTempData(
      tempData.map((e) => {
        if (e.isSelected === false) {
          tmp++;
        }
        return id !== e.id ? { ...e } : { ...e, isSelected: value };
      })
    );
  };

  const submitEasyApply = () => {
    let label = {
      screenName: "Apply with cv data",
    };
    gaEventTracker("cv uploaded", JSON.stringify(label));
    mixpanel.track("cv uploaded");

    let selectedId = [];
    if (easyApplyClicked === "") {
      tempData.forEach((e) => {
        if (e.isSelected) {
          selectedId = [...selectedId, e.id];
        }
      });
    } else {
      selectedId = easyApplyClicked;
    }
    setModalVisible(false);
    fetch_({
      url: `scholarships/submit-scholarships`,
      method: "POST",
      data: {
        scholarshipId: selectedId,
      },
    })
      .then(({ data }) => {
        setAlertShow(true);
        setThankYouModalOpen(true);
        setAlertType(true);
        setAlertMessage("Scholarship applied successfully!");
      })
      .catch((error) => {
        setAlertShow(true);
        setAlertMessage(error.description);
        setAlertType(false);
      });
  };

  const onFileUploadClick = (e) => {
    if (e?.file?.status !== "done") {
      return;
    }

    let label = {
      screenName: "Apply with cv data",
    };
    gaEventTracker("cv uploaded", JSON.stringify(label));
    mixpanel.track("cv uploaded");

    let selectedId = [];
    if (easyApplyClicked === "") {
      tempData.forEach((e) => {
        if (e.isSelected) {
          selectedId = [...selectedId, e.id];
        }
      });
    } else {
      selectedId = easyApplyClicked;
    }
    setModalVisible(false);
    fetch_({
      url: `scholarships/submit-scholarships`,
      method: "POST",
      data: {
        scholarshipId: selectedId,
        requirements: {
          // "9991e67e-418d-448a-ae41-143e4a6c33a7": JSON.stringify(
          //   e?.file?.response?.data
          // ),
          "9991e67e-418d-448a-ae41-143e4a6c33a7":
            e?.file?.response?.data?.publicUrl,
        },
      },
    })
      .then(({ data }) => {
        setAlertShow(true);
        setThankYouModalOpen(true);
        setAlertType(true);
        setAlertMessage("Scholarship applied successfully!");
      })
      .catch((error) => {
        setAlertShow(true);
        setAlertMessage(error.description);
        setAlertType(false);
      });
  };

  const submitData = () => {
    let label = {
      screenName: type,
    };
    gaEventTracker("Easy Apply clicked", JSON.stringify(label));
    mixpanel.track("Easy Apply clicked");
    setEasyApplyClicked("");
    setModalVisible(true);
  };

  const onCloseModal = () => {
    setThankYouModalOpen(false);
    history.push({
      pathname: history.location.pathname,
    });
  };

  useEffect(() => {
    // console.log(pathName.includes("myScholarship"))
    if (
      pathName.includes("myScholarship") ||
      pathName.includes("saved") ||
      pathName.includes("recommendation")
    ) {
      setHideCheckbox(true);
    }
  }, [pathName]);

  if (loading)
    return (
      <div
        style={{
          position: "absolute",
          left: mobileView ? window.innerWidth / 2 : "520px",
          top: "241px",
        }}
      >
        <Spin size={"large"} />
      </div>
    );
  return (
    <div
      className="auth-container"
      style={{
        background: "#ffff",
        minHeight: "100%", // Set a minimum height to allow growth
        height: window.innerWidth < 600 ? "auto" : "calc( 100vh - 110px )", // Subtract the height of the header
        overflow: "hidden ",
      }}
    >
      <ThankYouModal
        open={thankYouModalOpen}
        handleOnCloseModal={onCloseModal}
      />
      <div
        style={{
          paddingRight: "20px",
          paddingLeft: "20px",
        }}
      >
        <Row style={{ alignItems: "center" }}>
          <Col style={{ width: "50%" }}>
            {title && (
              <h2
                style={{
                  fontFamily: "Poppins",
                  fontSize: mobileView ? "16px" : "24px",
                  fontWeight: "500",
                  textAlign: "left",
                  fontStyle: "normal",
                }}
              >
                {title}({type === "saved" ? count?.length : count})
              </h2>
            )}

            {tempData?.length > 0 && (
              <div
                style={{
                  display: hideCheckbox ? "none" : "flex",
                }}
              >
                <Checkbox
                  checked={isAllSelected}
                  onChange={() => setIsAllSelected(!isAllSelected)}
                />
                <Button
                  style={{
                    background: "transparent",
                    border: "none",
                    width: "max-content",
                  }}
                  onClick={() => {
                    const newIsSelectedState = !isAllSelected; // Toggle the selection state

                    setIsAllSelected(newIsSelectedState);

                    setTempData(
                      data.scholarshiplists
                        ? data.scholarshiplists.map((e) => ({
                            ...e,
                            isSelected: newIsSelectedState,
                          }))
                        : data.map((e) => ({
                            ...e,
                            isSelected: newIsSelectedState,
                          }))
                    );
                  }}
                >
                  {isAllSelected ? "Unselect all" : "Select all"}
                </Button>
              </div>
            )}
          </Col>
          {/*<Col style={{alignItems:"right", marginBottom: "10px"}}>
            <Button
              type="primary"
              style={{
                background: "#F1F6FE",
                borderColor: "#F1F6FE",
                height: "40px",
                paddingTop: "5px",
                marginRight: "8px"
              }}
            >
              <span style={{ color: "#1778F2" }}>Apply Selected</span>
          </Button>
          <Select
              style={{ height: "40px", width: "150px", marginRight: "8px"}}
              mode="single"
              placeholder={"Filter"}
            >
            <Select.Option>
              Low to high
            </Select.Option>
        
          </Select>
          <Select
              style={{height: "40px", width: "200px" }}
              mode="single"
              placeholder={"Sort by"}
            >
            <Select.Option>
              Low to high
            </Select.Option>
        
          </Select>
        </Col>
      */}
        </Row>

        <div
          style={{
            // height: "calc(100vh - 200px)",
            // border: "2px solid red"
            width: "100%",
          }}
        >
          <Scrollbars
            style={{
              height: "calc(100vh - 250px)",
              // border: "2px solid green",
              // overflow: "scroll"
            }}
            // autoHide
            // autoHideTimeout={1000}
            // autoHideDuration={200}
          >
            <FilterBox
              data={tempData}
              loading={loading}
              history={history}
              type={type}
              onCheckboxChange={onCheckboxChange}
              onSavedLike={onSavedLike}
              setModalVisible={setModalVisible}
              setEasyApplyClicked={setEasyApplyClicked}
              hideCheckbox={hideCheckbox}
              {...props}
            />
          </Scrollbars>
          {tempData?.length === 0 && (
            <div
              style={{
                textAlignLast: "center",
                height: "inherit",
                marginTop: "150px",
                fontSize: "18px",
              }}
            >
              No Data
            </div>
          )}

          {/* {
            <motion.div
              initial={{ opacity: 0, marginBottom: 0 }}
              animate={{
                opacity: alertShow ? 1 : 0,
                marginBottom: alertShow ? 20 : 0,
              }}
            >
              <Alert
                type={alertType ? "success" : "error"}
                showIcon
                message={alertMessage}
              ></Alert>
            </motion.div>
          } */}

          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-evenly",
              alignItems: "center",
              flexDirection: window.innerWidth < 600 ? "column" : "row",
            }}
          >
            {(type === "easyApplyWithCv" || type === undefined) &&
              tempData?.length > 0 && (
                <div
                  style={{
                    // textAlignLast: "center",
                    paddingBottom: window.innerWidth < 600 ? "0" : "20px",
                    paddingTop: window.innerWidth < 600 ? "20px" : "0",
                    marginRight: window.innerWidth < 855 ? "0" : "300px",
                    // textAlign: "center",
                  }}
                >
                  <Button
                    type="primary"
                    style={{
                      borderColor: "#8D96FF",
                      backgroundColor: "#8D96FF",
                      borderRadius: "8px",
                      height: "40px",
                      width: window.innerWidth < 540 ? "max-content" : "300px",
                    }}
                    onClick={() => {
                      if (isActiveSubscription) {
                        if (type == undefined) {
                          submitEasyApply();
                          // console.log('from undefined')
                        } else {
                          submitData();
                          // console.log('another')
                        }
                      } else {
                        togglePopup();
                      }
                    }}
                  >
                    <span style={{}}>
                      {type === undefined
                        ? "Easy Apply to All"
                        : "Apply with CV"}
                    </span>
                  </Button>
                </div>
              )}
            {tempData?.length > 0 && (
              <div
                style={{
                  textAlignLast: "Center",
                  margin: "20px",
                  paddingBottom: "20px",
                }}
              >
                {![
                  "saved",
                  "myscholarship/draft",
                  "myscholarship/applied",
                ].includes(type) && (
                  <Pagination
                    total={count}
                    defaultCurrent={urlValues?.page || 1}
                    pageSize={pageSize}
                    showSizeChanger={false}
                    onChange={(el) => onPageChange(el)}
                    style={
                      {
                        // width: window.innerWidth < 350 ? "200px" : "auto",
                      }
                    }
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        visible={modalVisible}
        destroyOnClose={true}
        footer={null}
        closable={false}
        bodyStyle={{ padding: "0px 0px 20px 0px", borderRadius: "20px" }}
      >
        <Row
          style={{
            background: "#1D252D",
            borderTopRightRadius: "10px",
            borderTopLeftRadius: "10px",
            padding: "0px",
          }}
        >
          <Col style={{ padding: "15px" }}>
            <img
              src="/applicantImg/circleRobo.png"
              style={{ height: "30px", marginRight: "4px" }}
            />
          </Col>
          <Col style={{ color: "#ffffff", alignSelf: "center" }}>
            <span
              style={{
                fontSize: "14px",
                fontWeight: 600,
              }}
            >
              {" "}
              Lenny
            </span>{" "}
            <br />
            <span
              style={{
                fontSize: "12px",
                fontWeight: 400,
              }}
            >
              {" "}
              Your personalised assistant{" "}
            </span>
          </Col>
        </Row>
        <h5 style={{ margin: "20px" }}>Upload your CV</h5>
        <Row style={{ margin: "20px" }}>
          <Upload
            action={API_UPLOAD_URL}
            accept=".doc,.docx,.pdf"
            maxCount={1}
            onChange={onFileUploadClick}
            //onRemove={() => onChange(null)}
            showUploadList={{
              showRemoveIcon: false,
            }}
          >
            <Button
              style={{
                borderColor: "#699DFF",
              }}
            >
              <img
                src="/applicantImg/upload.png"
                style={{ height: "18px", marginRight: "4px" }}
              />
              <span style={{ color: "#699DFF" }}>{"Upload file"}</span>
            </Button>
          </Upload>
          <Button
            style={{
              borderColor: "#699DFF",
              width: "auto",
              marginLeft: "20px",
            }}
            onClick={() => {
              setModalVisible(false);
            }}
          >
            <span style={{ color: "#699DFF" }}>{"Cancel"}</span>
          </Button>
        </Row>
      </Modal>
      {isOpen &&
        (tabView ? (
          <div
            className="popup-overlay"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                color: "#FFF",
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "24px",
                padding: "0px 20px",
              }}
            >
              {`${userInfo?.firstName}`} get a membership for free 7 days to
              activate automatic application to your 234 scholarship matches.
              Lets us do the hard work for you !
            </p>

            <SubscriptionMobileDesign
              toggleMobilePopup={togglePopup}
              redirect={true}
            />
          </div>
        ) : (
          <div className="popup-overlay">
            <div className="popup-content">
              <div
                onClick={togglePopup}
                style={{
                  color: "rgb(113, 122, 245)",
                  fontSize: "24px",
                  cursor: "pointer",
                  float: "right",
                  lineHeight: "normal",
                }}
              >
                {" "}
                <CloseCircleFilled />
              </div>
              <Subscription
                modalStyle={{
                  border: "none",
                  boxShadow: "none",
                  margin: "0px",
                }}
                redirect={true}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default FilterApply;
