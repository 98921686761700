import Subscription from "components/layout-components/Subscription";
import SubscriptionMobileDesign from "components/layout-components/SubscriptionMobileDesign";
import React, { useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useAssitanceChatBotContext } from "context/ChatbotContext";
import mixpanel from "mixpanel-browser";

const Index = ({ props }) => {
  const history = useHistory();

  const { checkCanShare, setInvitationPopUp } = useAssitanceChatBotContext();

  const phoneView = window.innerWidth < 962;
  useEffect(() => {
    props.setSideBars(true);
  });

  const userInfoString = localStorage.getItem("userInfo");
  let userInfo = {};
  if (userInfoString) {
    try {
      userInfo = JSON.parse(userInfoString);
    } catch (err) {
      console.error("Error parsing userInfo:", err);
    }
  }

  const handleBackClick = async () => {
    try {
      const result = await checkCanShare();
      setInvitationPopUp(result ? true : false);
      mixpanel.track("Invitation Popup Opened");

      history.push("/auth/dashboard");
      // }
    } catch (error) {
      console.error("Error during back navigation:", error);
    }
  };

  return (
    <div>
      <span
        style={{
          fontSize: "30px",
          position: "absolute",
          top: "5px",
          left: "15px",
          cursor: "pointer",
        }}
        onClick={handleBackClick}
      >
        <ArrowLeftOutlined />
      </span>
      {phoneView ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            marginTop: "50px",
          }}
        >
          <p
            style={{
              color: "black",
              textAlign: "center",
              fontFamily: "Poppins",
              fontSize: "16px",
              fontStyle: "normal",
              fontWeight: "500",
              lineHeight: "24px",
              padding: "0px 20px",
            }}
          >
            {`${userInfo?.firstName}`} get a membership for free 7 days to
            activate automatic application to your 234 scholarship matches. Lets
            us do the hard work for you !
          </p>

          <SubscriptionMobileDesign redirect={true} noThanksDisplay={true} />
        </div>
      ) : (
        <Subscription
          redirect={true}
          modalStyle={{
            border: "none",
            boxShadow: "none",
            margin: "0px",
          }}
        />
      )}
    </div>
  );
};

export default Index;
